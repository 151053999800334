import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';
import { FaSearch, FaCircleNotch } from 'react-icons/fa';
import styled from 'styled-components';

import {
  fetchConsults,
  fetchConsultsOnPagination,
  selectConsult,
  showFilterPane,
  setSearchText,
  resetConsults,
  ipruAddCase,
} from '../actions/index';
import StyledTappable from './common/StyledTappable';
import ConsultationItem from './ConsultationItem';
import LoadingComponent from '../components/common/LoadingComponent';
import filterIcon from '../images/filter-icon.svg';
import { RegularText, Text, BoldText } from './common/Text';
import noResultsIcon from '../images/consultations/nosearch.svg';
import moment from 'moment';
import { PrimaryButton } from '../components/common/Buttons';

const OuterContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: stretch;
`;

const SearchContainer = styled.form`
  display: flex;
  flex-shrink: 0;
  align-items: stretch;
`;

const FilterIcon = styled.img`
  margin: 1.4rem;
`;

const StyledInputBox = styled(Input)`
  flex: 1 0;
  font-size: 1.6rem;
  padding: 1.4rem 0;
`;

const SearchIcon = styled(FaSearch)`
  color: #8852cc;
  font-size: 2.2rem;
  margin: 1.4rem;
`;

const ConsultationsContainer = styled.div`
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
`;

const TappableConsult = styled(StyledTappable)`
  flex-direction: column;
  flex: 1 0;
  align-items: stretch;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;
  flex: 1;
  flex-shrink: 0;
`;

const EmptyIcon = styled.img`
  width: 10rem;
  margin-bottom: 1.25rem;
`;

const EmptyText = styled(RegularText)`
  font-size: 1.25rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  flex-shrink: 0;
  padding: 0.625rem;
  align-items: center;
`;

const LoadingText = styled(Text)`
  font-size: 1.4rem;
`;

const LoadingIcon = styled(FaCircleNotch)`
  margin-right: 0.5rem;
  transform: translate(2);
`;

class ConsultationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      loading: true
    };
    this.listRef = React.createRef();
  }

  componentDidMount = () => {
    this.fetchConsults();
    if (this.props.consults) {
      this.setState({ loading: false })
    }

  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.consults && this.props.consults !== prevProps.consults) {
      if (this.props.consults) {
        this.setState({ loading: false })
      }
      else if (!this.state.loading) {
        this.setState({ loading: true })
      }
    }
    const {
      canPaginate,
      paginateToken,
      fetchConsultsUrlForPagination,
    } = this.props;
    const prevCanPaginate = prevProps.canPaginate;
    const prevPaginateToken = prevProps.paginateToken;
    if (
      (canPaginate !== prevCanPaginate ||
        paginateToken !== prevPaginateToken) &&
      fetchConsultsUrlForPagination
    ) {
      this.fetchConsultsForPaginationCalled = false;
    }
    if (!fetchConsultsUrlForPagination && this.listRef.current) {
      this.listRef.current.scrollTo(0, 0);
    }

  }

  fetchConsults() {
    const { dispatch, activeFilterName, authToken } = this.props;
    dispatch(
      fetchConsults({
        authToken,
        filters: [{ name: activeFilterName, isSelected: true }],
      }),
    )
  }

  searchConsults = (event) => {
    event && event.preventDefault();
    const { dispatch, activeFilterName, authToken } = this.props;
    const { searchText } = this.state;
    dispatch(resetConsults());
    dispatch(setSearchText(searchText));
    if (!searchText) {
      dispatch(
        fetchConsults({
          authToken,
          filters: [{ name: activeFilterName, isSelected: true }],
        }),
      );
    } else {
      dispatch(fetchConsults({ authToken, searchText }));
    }
  };

  fetchConsultsOnPagination = (paginateToken) => {
    const { dispatch, authToken, fetchConsultsUrlForPagination } = this.props;
    this.fetchConsultsForPaginationCalled = true;
    dispatch(
      fetchConsultsOnPagination({
        authToken,
        paginateToken,
        fetchConsultsUrlForPagination,
      }),
    );
  };

  onListScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const {
      fetchConsultsUrlForPagination,
      canPaginate,
      paginateToken,
    } = this.props;
    // console.log('onListScroll called', { scrollHeight, scrollTop, clientHeight, fetchConsultsUrlForPagination, canPaginate, paginateToken });
    if (scrollHeight - (scrollTop + clientHeight) <= 50) {
      if (
        canPaginate &&
        fetchConsultsUrlForPagination &&
        !this.fetchConsultsForPaginationCalled
      ) {
        this.fetchConsultsOnPagination(paginateToken);
      }
    }
  };

  setActiveConsult(consult) {
    const { admin, doctorType, dispatch, history, location } = this.props;
    if (admin) {
      if (doctorType === 'mbbs') {
        history.replace(`${location.pathname}#form`);
      } else {
        history.replace(`${location.pathname}#chat`);
      }
    }
    dispatch(selectConsult(consult));
  }

  showFilter() {
    const { dispatch } = this.props;
    dispatch(showFilterPane());
  }

  getTimeText = (timetoken) => {
    // const docMin = doctorTimetoken / 60;
    const min = parseInt((new Date().valueOf() / 1000 - timetoken) / 60);
    if (min < 60) {
      return `${min}min`;
    } else if (min < 3600) {
      return `${parseInt(min / 60)}h`;
    } else {
      return `${parseInt(min / (60 * 24))}days`;
    }
  };

  getDateText = (createdAt) => {
    // converting in moment format
    createdAt = createdAt * 1000;

    if (!createdAt) {
      return null;
    }

    const latestTime = moment(createdAt);

    const currentTime = moment();
    if (latestTime.format('DD') !== currentTime.format('DD')) {
      return latestTime.format('D/MMM/YYYY');
    } else {
      return latestTime.format('h:mm A');
    }
  };

  render() {
    console.log(this.props.isLoading)
    const { consults, activeConsult, admin } = this.props;
    const searchTextLink = Link.state(this, 'searchText');
    const renderConsult = (ele, idx) => {
      return (
        <TappableConsult key={idx} onClick={() => this.setActiveConsult(ele)}>
          <ConsultationItem
            userName={ele.user.userName}
            callIcon={ele.requestType}
            issues={ele.issues}
            labels={ele.labels}
            active={ele.consultationId === activeConsult.consultationId}
            timeText={this.getTimeText(ele.createdAtEpoch)}
            dateText={this.getDateText(ele.createdAt)}
            unreplied={ele.unreplied}
            requestType={ele.requestType}
            admin={admin}
          />
        </TappableConsult>
      );
    };
    if (this.props.isLoading) {
      return <LoadingComponent />;
    }
    return (
      <OuterContainer>
        <SearchContainer onSubmit={this.searchConsults}>
          <StyledTappable onTap={() => this.showFilter()}>
            <FilterIcon src={filterIcon} />
          </StyledTappable>
          <StyledInputBox
            valueLink={searchTextLink}
            placeholder="Search for patients"
            className="visit-input"
          />
          <StyledTappable onTap={() => this.searchConsults()}>
            <SearchIcon />
          </StyledTappable>
        </SearchContainer>
        <ConsultationsContainer onScroll={this.onListScroll} ref={this.listRef}>
          {consults.length === 0 ? (
            <>
              <EmptyContainer>
                <EmptyIcon src={noResultsIcon} />
                <EmptyText>
                  Sorry, we couldn’t find any result for that search, try a
                  different search.
                </EmptyText>
              </EmptyContainer>
              {this.props.insurer === 'ipru' &&
                this.state.searchText &&
               // this.props.armsoft && 
               (
                  <ButtonsContainer>
                    <PrimaryButton
                      onTap={() => {
                        this.props.history.replace(
                          `${this.props.history.location.pathname}#form`,
                        );
                        this.props.dispatch(ipruAddCase(true));
                      }}
                    >
                      <BoldText fontSize="14px" color="#fff">
                        Add Case
                      </BoldText>
                    </PrimaryButton>
                  </ButtonsContainer>
                )}
            </>
          ) : (
            consults.map(renderConsult)
          )}
          {this.props.canPaginate && consults.length >= 20 && (
            <ButtonsContainer>
              <LoadingIcon className="icon-spin" />
              <LoadingText>Loading...</LoadingText>
            </ButtonsContainer>
          )}
        </ConsultationsContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  consults: state.consults.consults,
  activeConsult: state.consults.activeConsult,
  filters: state.info.filters,
  activeFilterName: state.info.activeFilterName,
  authToken: state.user.authToken,
  canPaginate: state.info.canPaginate,
  paginateToken: state.info.paginateToken,
  fetchConsultsUrlForPagination: state.info.fetchConsultsUrlForPagination,
  admin: state.user.admin,
  doctorType: state.user.doctorType,
  isLoading: state.consults.isLoading,
  insurer: state.user.insurer,
  armsoft: state.user.armsoft
});

export default withRouter(connect(mapStateToProps)(ConsultationList));
