import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

import CardContainer from '../../common/CardContainer';
import { Text } from '../../common/Text';

const OuterContainer = styled(CardContainer)`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: 0.875rem 0.75rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
`;

const HeaderIcon = styled.div`
  background: #59ba29;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

const CheckIcon = styled(FaCheck)`
  width: 0.625rem;
  color: #fff;
`;

const HeaderText = styled(Text)`
  font-size: 0.875rem;
`;

const BodyContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DoctorIcon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.75rem;
`;

const BodyTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyTitle = styled(Text)`
  font-size: 1rem;
  color: #4d4d4d;
`;

const BodySubtitle = styled(Text)`
  font-size: 1rem;
  color: #7f7f7f;
`;

const ConsultationConfirmedMessage = ({
  heading,
  profileImg,
  title,
  subTitle,
}) => (
  <OuterContainer>
    <HeaderContainer>
      <HeaderIcon>
        <CheckIcon />
      </HeaderIcon>
      <HeaderText>{heading}</HeaderText>
    </HeaderContainer>
    <BodyContainer>
      <DoctorIcon src={profileImg} />
      <BodyTextContainer>
        <BodyTitle>{title}</BodyTitle>
        <BodySubtitle>{subTitle}</BodySubtitle>
      </BodyTextContainer>
    </BodyContainer>
  </OuterContainer>
);

ConsultationConfirmedMessage.propTypes = {
  heading: PropTypes.string,
  profileImg: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default ConsultationConfirmedMessage;
