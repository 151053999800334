import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

export function fetchMedicines(authToken, input) {
  const headers = {
    authorization: authToken,
  };

  const baseUrl = `https://api.getvisitapp.com/v3/prescriptions/medicines/search?s=${input}`;
  let url = baseUrl;

  return axios
    .get(url, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addMedicine(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  const url = `${config.apiBaseUrl}/prescriptions/medicines/add`;

  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteMedicine(authToken, medicineId, prescriptionId) {
  const headers = {
    authorization: authToken,
  };

  const url = `${config.apiBaseUrl}/prescriptions/${prescriptionId}/medicines/remove`;

  return axios
    .post(url, { medicineId }, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

// lab tests
export function getLabTests(authToken, searchQuery) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/labs/all`, {
      headers,
      params: {
        s: searchQuery,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody.labs;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addLabTest(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  const url = `${config.apiBaseUrl}/prescriptions/labs/add`;

  return axios
    .post(url, body, { headers })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function deleteLabTest(authToken, labId, prescriptionId) {
  const headers = {
    authorization: authToken,
  };

  const url = `${config.apiBaseUrl}/prescriptions/${prescriptionId}/labs/remove`;

  return axios
    .post(url, { labId }, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function previewPrescription(authToken, prescriptionId, diagnosis) {
  const headers = {
    authorization: authToken,
  };

  const url = `${config.apiBaseUrl}/prescriptions/${prescriptionId}/preview`;

  return axios
    .post(url, { prescriptionId, diagnosis }, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function sendPrescription(authToken, prescriptionId, diagnosis) {
  const headers = {
    authorization: authToken,
  };

  const url = `${config.apiBaseUrl}/prescriptions/send`;

  return axios
    .post(url, { prescriptionId, diagnosis }, { headers })
    .then((res) => {
      const responseBody = res.data;
      console.log({ res });
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
