export const defaultState = {
  consults: [],
  unrepliedChannel: null,
  activeConsult: {
    channel: null,
    consultationId: null,
    requestType: null,
    doctorId: 0,
    nonWorkableAt: false,
    rejectedAt: false,
    user: {
      userName: '',
      userId: null,
      gender: '',
      age: null,
      relativeName: null,
    },
    applicationId: '',
    applicationNo: '',
    proposalNumber: '',
    doctorType: '',
    sponsor: { sponsorName: '', id: 0 },
    issues: '',
    caseRemarks: '',
    policyAppliedType: '',
    advisorNo: '',
    qcRemarks: '',
    doctorTimetoken: null,
    userTimetoken: null,
    labels: [],
    relatives: [],
    proposerDetails: null,
    isLoading: false,
    whatsappDeliveryStatus: null,
    oneConnectLinks: [],
    showForm: true,
    insurerReinitiatedAt: null,
    insurerReinitiationRemark: null,
    source: '',
    teleRemarks: '',
    merLink: '',
  },
  userStatus: false,
  ipruAddCase: false,
  inboundText: '',
};

export default function consults(state = defaultState, action) {
  switch (action.type) {
    case 'SET_CONSULTS':
      return Object.assign({}, state, {
        consults: action.payload.consults,
      });
    case 'SET_ACTIVE_CONSULT':
      return Object.assign({}, state, {
        activeConsult: Object.assign({}, action.payload.consult, {
          proposerDetails: state.activeConsult.proposerDetails,
          applicationId: state.activeConsult.applicationId,
          applicationNo: state.activeConsult.applicationNo,
          proposalNumber: state.activeConsult.proposalNumber,
          relatives: state.activeConsult.relatives,
          whatsappDeliveryStatus: state.activeConsult.whatsappDeliveryStatus,
        }),
        ipruAddCase: false,
      });
    case 'RESET_CONSULTS':
      return Object.assign({}, state, {
        consults: defaultState.consults,
        activeConsult: defaultState.activeConsult,
        ipruAddCase: false,
      });
    case 'TOGGLE_FILTER':
      return Object.assign({}, state, {
        activeConsult: defaultState.activeConsult,
      });
    case 'RESET_ACTIVE_CONSULT':
      return Object.assign({}, state, {
        activeConsult: Object.assign({}, defaultState.activeConsult, {
          proposerDetails: state.activeConsult.proposerDetails,
          applicationId: state.activeConsult.applicationId,
          applicationNo: state.activeConsult.applicationNo,
          proposalNumber: state.activeConsult.proposalNumber,
          relatives: state.activeConsult.relatives,
          whatsappDeliveryStatus: state.activeConsult.whatsappDeliveryStatus,
        }),
        ipruAddCase: false,
      });
    case 'ADD_CONSULTS': {
      return Object.assign({}, state, {
        consults: [...state.consults, ...action.payload.consults],
      });
    }
    case 'SET_UNREPLIED_CHANNEL': {
      console.log('setting unreplied channel', action);
      return Object.assign({}, state, {
        unrepliedChannel: action.payload || 'doctor-0',
      });
    }
    case 'LOGOUT_USER': {
      return Object.assign({}, state, defaultState);
    }
    case 'SET_RELATIVES': {
      return Object.assign({}, state, {
        activeConsult: Object.assign({}, state.activeConsult, {
          relatives: action.payload,
        }),
      });
    }
    case 'SET_PROPOSER_DETAILS': {
      return Object.assign({}, state, {
        activeConsult: Object.assign({}, state.activeConsult, {
          proposerDetails: action.payload,
        }),
      });
    }
    case 'USER_STATUS': {
      return Object.assign({}, state, {
        userStatus: action.payload,
      });
    }
    case 'SET_LOADING': {
      return Object.assign({}, state, {
        isLoading: action.payload,
      });
    }
    case 'SET_WHATSAPP_DELIVERY_STATUS': {
      return Object.assign({}, state, {
        activeConsult: Object.assign({}, state.activeConsult, {
          whatsappDeliveryStatus: action.payload,
        }),
      });
    }
    case 'SET_ONECONNECT_LINKS': {
      return Object.assign({}, state, {
        activeConsult: Object.assign({}, state.activeConsult, {
          oneConnectLinks: action.payload || [],
        }),
      });
    }
    case 'IPRU_ADD_CASE': {
      return Object.assign({}, state, {
        ipruAddCase: action.payload,
      });
    }
    case 'UPDATE_CALL_ID': {
      return Object.assign({}, state, {
        activeConsult: Object.assign({}, state.activeConsult, {
          callId: action.payload,
        }),
      });
    }
    case 'UPDATE_INSURER': {
      return Object.assign({}, state, defaultState);
    }
    case 'INBOUND CALL': {
      return Object.assign({}, state, {
        inboundText: action.payload.messageBody,
      });
    }
    default:
      return state;
  }
}
