import React, { Component } from 'react';
import {boolean, string, array, func, number, oneOfType} from 'prop-types';
import NavigationButtons from './NavigationButtons';

export default class SingleSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.userResponse ? props.userResponse : '',
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.userResponse ? nextProps.userResponse : '',
    });
  }

  handleChange = (event) => {
    this.setState({
      value: event.target.value,
    });
  }

  render() {
    const renderOption = (ele, index) => (
      <div
        key={index}
        className="border border-secondary border-top-0 border-left-0 border-right-0">
        <div className="form-check d-flex flex-column">
          <input className="form-check-input d-none p-3" type="radio" name="questionOptions" id={`singleSelectOptions${index}`} value={ele} checked={ele === this.state.value} onChange={this.handleChange} />
          <span className="checkmark m-3" />
          <label className="form-check-label p-3 ml-3 flex-grow-1" htmlFor={`singleSelectOptions${index}`}>
            {ele}
          </label>
        </div>
      </div>
    );

    const sendResponse = () => {
      const selectedOption = this.state.value;
      this.props.submitResponse(selectedOption);
    };

    const progress = Number.parseInt((this.props.currentOrder-1)/this.props.totalQuestions*100);
    return (
      <div className="container-fluid h-100 d-flex flex-column">
        <div className="row hra-body">
          <div className="col-12">
            <div className="row d-block">
              <div className="col p-0">
                <div className="bg-primary p-3 text-white rounded-0 pb-1 mb-0">
                  <div className="row m-0 justify-content-center">
                    <div className="col-12">
                      <h3 className="mt-3">{this.props.title}</h3>
                    </div>
                    <div className="col-12">
                      <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={this.props.currentOrder-1} aria-valuemin="1" aria-valuemax={this.props.totalQuestions}></div>
                      </div>
                    </div>
                    <div className="col-12 mt-5">
                      <p className="h5 font-weight-normal">
                        {this.props.question}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-stretch flex-grow-1">
              <div className="col-12 p-0">
                {this.props.options.split('##').map(renderOption)}
              </div>
              {this.props.info && (
                <div className="col-12 mt-3 pb-4">
                  <a
                    className="text-primary font-weight-bold btn p-0"
                    data-toggle="collapse"
                    href="#info"
                    role="button"
                    aria-expanded="false"aria-controls="info">
                    See Information
                  </a>
                  <div className="collapse" id="info">
                    <p>
                      {this.props.info}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <NavigationButtons
         submitResponse={sendResponse}
         submitDisabled={this.state.value === ''}
         goToPrevious={this.props.goToPrevious} />
      </div>
    );
  }
}

SingleSelect.propTypes = {
  title: string.isRequired,
  question: string.isRequired,
  options: array.isRequired,
  info: string,
  userResponse: oneOfType([ string, boolean ]),
  currentOrder: number.isRequired,
  totalQuestions: number.isRequired,
  submitResponse: func.isRequired,
  goToPrevious: func.isRequired,
};
