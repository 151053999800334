import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { RegularText, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import greyTick from '../../images/common/greyTick.svg';
import purpleTick from '../../images/common/purpleTick.svg';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const OptionContainer = styled(StyledTappable)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin-right: 3rem;
`;

const OptionText = styled(RegularText)`
  /* color: white; */
  font-size: 1.4rem;
  text-align: center;
  margin: 0rem;
  margin-left: 0.25rem;
  padding: 0rem;
`;

const SelectLinkText = styled(StyledTappable)`
  padding: 0.45rem;
`;

const SelectIcon = styled.img`
  width: 2rem;
  align-self: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

class CloseConsultationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionChosen: false,
    };
  }

  optionClicked = (option) => {
    this.setState({ optionChosen: option });
  };

  closeConsultation = () => {
    this.props.closeConsult(this.state.optionChosen);
  };

  render() {
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>
                Are you sure you want to mark this completed?
              </HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <OptionsContainer>
                <OptionContainer onTap={() => this.optionClicked('complete')}>
                  <SelectLinkText>
                    {this.state.optionChosen === 'complete' ? (
                      <SelectIcon src={purpleTick} alt="Success" />
                    ) : (
                      <SelectIcon src={greyTick} alt="Success" />
                    )}
                  </SelectLinkText>
                  <OptionText>Complete</OptionText>
                </OptionContainer>
                <OptionContainer onTap={() => this.optionClicked('userIdle')}>
                  <SelectLinkText>
                    {this.state.optionChosen === 'userIdle' ? (
                      <SelectIcon src={purpleTick} alt="Success" />
                    ) : (
                      <SelectIcon src={greyTick} alt="Success" />
                    )}
                  </SelectLinkText>
                  <OptionText>User Idle</OptionText>
                </OptionContainer>
              </OptionsContainer>
              <ButtonComponent>
                <Button
                  onTap={() => this.closeConsultation()}
                  disabled={this.state.optionChosen ? false : true}
                  // loading={this.state.loading}
                >
                  <ButtonText>Mark Complete</ButtonText>
                </Button>
              </ButtonComponent>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

export default CloseConsultationModal;
