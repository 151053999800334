import { store } from '../store/index';
import { setUserInfo } from '../actions';
export const showError = (err) => {
  let errorTitle = 'Something went wrong';
  let errorDesc = 'Check your internet connection and try again';

  if (err.response && err.response.data && err.response.data.errorMessage) {
    errorDesc = err.response.data.errorMessage;
  } else if (err && err.data && err.data.errorMessage) {
    errorDesc = err.data.errorMessage;
  }

  console.log({ err, errorTitle, errorDesc });

  store.dispatch({
    type: 'SHOW_ERROR',
    payload: {
      errorTitle: errorTitle,
      errorDesc: errorDesc,
    },
  });
};

export const showInactiveError = (errorMessage) => {
  let errorTitle = 'Account Inactive';
  let errorDesc =
    'Do to inactivity for last 45 minutes, Your account is marked as inactive. Please mark it active again once you start.';

  store.dispatch({
    type: 'SHOW_ERROR',
    payload: {
      errorTitle: errorTitle,
      errorDesc: errorMessage || errorDesc,
    },
  });
  store.dispatch(
    setUserInfo({
      active: false,
    }),
  );
};
