import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from 'valuelink';
import { FaCircleNotch, FaArrowLeft } from 'react-icons/fa';
import { IoMdAttach, IoMdSend } from 'react-icons/io';
import { Input } from 'valuelink/tags';
import styled from 'styled-components';

import LoadingComponent from '../components/common/LoadingComponent';
import { SlideTransition } from '../components/transitions/transitions';
import UploadingComponent from '../components/common/UploadingComponent';
import StyledTappable from '../components/common/StyledTappable';
import UserMessage from '../components/consultations/chat-bubbles/UserMessage';
import DoctorMessage from '../components/consultations/chat-bubbles/DoctorMessage';
import UserImageMessage from '../components/consultations/chat-bubbles/UserImageMessage';
import UserFileMessage from '../components/consultations/chat-bubbles/UserFileMessage';
import DoctorImageMessage from '../components/consultations/chat-bubbles/DoctorImageMessage';
import DoctorFileMessage from '../components/consultations/chat-bubbles/DoctorFileMessage';
import ConsultationConfirmedMessage from '../components/consultations/chat-bubbles/ConsultationConfirmedMessage';
import CaseDetailsMessage from '../components/consultations/chat-bubbles/CaseDetailsMessage';
import ChatInfoMessage from '../components/consultations/chat-bubbles/ChatInfoMessage';
import { sendMessage, resetChat } from '../actions';
import { RegularText, BoldText, Text } from '../components/common/Text';
import RecommendSpecialistModal from '../components/consultations/RecommendSpecialistModal';
import {
  uploadImageAttachment,
  recommendSpecialist,
  getStandardMessages,
} from '../services';
import { initializeChatClient } from '../services/chatClient';

import star from '../images/attachments/star.svg';
import doctor from '../images/attachments/doctor.svg';
import message from '../images/attachments/message.svg';
import followup from '../images/attachments/followup.svg';
import gallery from '../images/attachments/gallery.svg';
import opd from '../images/attachments/opd.svg';
import specialistSuggestIcon from '../images/consultations/specialist.svg';
import TypingIndicator from '../components/consultations/TypingIndicator';
import { reverseDebounce } from '../utils';
import moment from 'moment';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: white;
  padding: 0.6rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const StyledUploadingComponent = styled(UploadingComponent)`
  margin: 0;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: stretch;
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.1);
  background: white;
  padding: 0 1.6rem;
`;

const AttachIcon = styled(IoMdAttach)`
  font-size: 2rem;
`;

const InputBox = styled(Input)`
  padding: 1.6rem;
  display: flex;
  align-self: stretch;
  flex: 1;
  ::placeholder {
    color: #bababa;
  }
`;

const OptionContainer = styled(StyledTappable)`
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 1.25rem;
  /* align-items: flex-start; */
`;

const Icon = styled.img`
  width: 2rem;
  margin-right: 0.625rem;
`;

const AttachmentText = styled(RegularText)`
  margin: 2rem 0.625rem;
  font-size: 1.6rem;
`;

const AttachmentContainer = styled.div`
  position: absolute;
  flex: 1 0;
  height: 350px;
  width: 300px;
  display: flex;
  align-items: flex-start;
  bottom: 5rem;
  left: 3rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  overflow-x: hidden;

  &:focus {
    border: none;
    outline: none;
  }
`;

const AttachmentBody = styled.span`
  width: 100%;
`;

const SendIcon = styled(IoMdSend)`
  opacity: 0.5;
  font-size: 2rem;
`;

const SpecialistSuggestContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.25rem;
  align-self: center;
  align-items: center;
`;

const SpecialistSuggestIcon = styled.img`
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.625rem;
`;

const SpecialistSuggestText = styled(BoldText)`
  font-size: 1.4rem;
`;

const TimestampText = styled(RegularText)`
  color: #000;
  opacity: 0.5;
  font-size: 1.2rem;
  margin-left: 0.625rem;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`;

const LoadingIcon = styled(FaCircleNotch)`
  margin-right: 0.5rem;
`;

const DateElement = styled.div`
  align-self: center;
  margin: 10px 0;
  /* background-color: #d8d8d8; */
  border-radius: 10px;
`;

const DateText = styled(RegularText)`
  text-align: center;
  font-size: 1.2rem;
  color: #000;
`;

const HeaderWithIcon = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0;
  padding: 10px;
`;

const BackArrow = styled(FaArrowLeft)`
  margin-right: 8px;
  font-size: 1.5rem;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
`;

class ConsultationChatContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatMessage: '',
      loading: true,
      uploading: false,
      uploadProgress: 0,
      showAttachmentContainer: false,
      attachmentTypes: [
        { type: 'file', name: 'Send File' },
        // { type: 'specialist', name: 'Recommend a Specialist' },
        { type: 'messages', name: 'Standard Messages' },
        // { type: 'followups', name: 'Add Follow-up' },
        // { type: 'rating', name: 'Rating Card' },
        // { type: 'opd', name: 'Group Outpatient Insurance Card' },
      ],
      standardMessages: [],
      standardMessagesStatus: 0,
      standardMessagesOpen: false,
      showSpecialistModal: false,
      fetchMessages: false,
    };
    this.conversationBodyRef = React.createRef();

    this.chatClient = initializeChatClient();
    this.channel = props.activeConsult.channel;
    console.log(props.activeConsult);
    if (props.chatInfo.chatMessages.length === 0) {
      this.chatClient.history({
        channel: this.channel,
        count: 20,
      });
    }
    // this.chatClient.subscribe(this.channel);
    console.log({ props: this.props, state: this.state });

    this.throttledTypingStatusUpdate = reverseDebounce(() => {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.chatClient.setState('TYPING', this.props.user.doctorId);
      this.timeoutId = setTimeout(() => {
        // send setState to pubnub with status: ‘STOP_TYPING’
        this.chatClient.setState('STOP_TYPING', this.props.user.doctorId);
      }, 10000);
    }, 2000);
    this.attachmentContainerRef = React.createRef();
  }

  componentDidMount = () => {
    document
      .querySelector('#chat-input-box')
      .addEventListener('keydown', this.handleKeyPress, false);
    // window.addEventListener('keydown', this.handleKeyPress, false);
    const { chatInfo } = this.props;
    if (chatInfo.chatMessages.length > 0) {
      if (this.conversationBodyRef) {
        const bodyList = this.conversationBodyRef.current;
        bodyList.scrollTop = bodyList.scrollHeight;
      }
    }
  };

  getSnapshotBeforeUpdate = (prevProps) => {
    const { chatMessages } = this.props.chatInfo;
    const { fetchMessages } = this.state;
    const previousChatMessages = prevProps.chatInfo.chatMessages;
    if (fetchMessages && chatMessages.length > previousChatMessages.length) {
      const list = this.conversationBodyRef.current;
      return list.scrollHeight - list.scrollTop;
    }
    return null;
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (snapshot) {
      const list = this.conversationBodyRef.current;
      list.scrollTop = list.scrollHeight - snapshot - 50;
      return;
    }
    const { chatInfo } = this.props;
    const previousChatInfo = prevProps.chatInfo;
    if (chatInfo.chatMessages.length > previousChatInfo.chatMessages.length) {
      if (this.conversationBodyRef) {
        const bodyList = this.conversationBodyRef.current;
        bodyList.scrollTop = bodyList.scrollHeight;
      }
    }
    if (chatInfo.consultationId !== prevProps.chatInfo.consultationId) {
      this.getStandardMessages();
      this.stopHistoryFetch = false;
    }
  };

  componentWillUnmount = () => {
    const { chatInfo, dispatch } = this.props;
    // if (this.chatClient) {
    //   this.chatClient.unsubscribe(chatInfo.chatChannel);
    // }
    // dispatch(resetChat());
  };

  handleKeyPress = (event) => {
    // console.log({ event, focus: document.activeElement.id });
    if (event.code === 'Enter') {
      this.sendMessage();
    }
  };

  getStandardMessages() {
    const { activeConsult } = this.props;
    getStandardMessages(activeConsult.user.userId)
      .then((response) => {
        const messages = response.messages;
        this.setState({
          standardMessages: messages,
          standardMessagesStatus: 1,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          standardMessagesStatus: -1,
        });
      });
  }

  sendMessage = () => {
    const { chatMessage } = this.state;
    const { dispatch } = this.props;
    if (chatMessage.trim() === '') {
      return;
    }
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.chatClient.setState('STOP_TYPING', this.props.user.doctorId);
    // dispatch(setTypingState('STOP_TYPING'));
    const messagePayload = {
      text: chatMessage,
    };
    dispatch(sendMessage(messagePayload));
    this.setState({
      chatMessage: '',
    });
  };

  attachFile = () => {
    this.setState(
      {
        showAttachmentContainer: !this.state.showAttachmentContainer,
      },
      () => {
        console.log(this.attachmentContainerRef);
        this.attachmentContainerRef.current &&
          this.attachmentContainerRef.current.focus();
      },
    );
  };

  openAttachment = () => {
    document.querySelector('#imageAttachment').click();
  };

  openAttachmentType = (ele, idx) => {
    console.log({ ele, idx });
    switch (ele.type) {
      case 'specialist':
        this.setState({
          showAttachmentContainer: false,
          showSpecialistModal: true,
        });
        break;
      case 'file': {
        this.setState({ showAttachmentContainer: false });
        this.openAttachment();
        break;
      }
      case 'messages':
        this.setState({ standardMessagesOpen: true });
        break;
      default:
        break;
    }
  };

  hideModal = () => {
    this.setState({
      showAttachmentContainer: false,
      showSpecialistModal: false,
    });
  };

  recommendSpecialist = (body) => {
    console.log({ body, activeConsult: this.props.activeConsult });

    recommendSpecialist(
      this.props.authToken,
      this.props.activeConsult.user.userId,
      body.vertical,
      body.consultType,
      this.props.activeConsult.consultationId,
    )
      .then((result) => {
        console.log({ result }, 'recommendSpecialist');
        this.hideModal();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  handleAttachment = () => {
    const { authToken } = this.props;
    const { consultationId } = this.props.chatInfo;
    const file = document.querySelector('#imageAttachment').files[0];
    document.querySelector('#imageAttachment').value = '';
    if (file) {
      this.setState({
        uploading: true,
      });
      const onUploadProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent;
          this.setState({
            uploadProgress: (loaded / total) * 100,
          });
        } else {
          this.setState({
            uploadProgress: 100,
          });
        }
      };
      uploadImageAttachment(file, consultationId, onUploadProgress, authToken)
        .then(() => {
          this.setState({
            uploading: false,
            uploadProgress: 0,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  fetchMessages() {
    const { chatChannel, chatMessages } = this.props.chatInfo;
    console.log(chatMessages[0].timetoken * 10000 - 100);
    this.chatClient
      .history({
        channel: chatChannel,
        count: 20,
        start: chatMessages[0].timetoken * 10000 - 100,
      })
      .then(() => {
        if (chatMessages.length === this.props.chatInfo.chatMessages.length) {
          // Tried fetching history, found nothing. stop fetching
          this.stopHistoryFetch = true;
        }
        this.setState({
          fetchMessages: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onListScroll = (event) => {
    const { chatMessages } = this.props.chatInfo;
    const { scrollTop } = event.target;
    const { fetchMessages } = this.state;
    if (
      !this.stopHistoryFetch &&
      scrollTop <= 150 &&
      chatMessages.length >= 20
    ) {
      if (!fetchMessages) {
        this.setState({
          fetchMessages: true,
        });
        this.fetchMessages();
      }
    }
  };

  getDateElement = (currentMsgTT, prevMsgTT) => {
    const currentMsgTime = moment(currentMsgTT);
    const prevMsgTime = moment(prevMsgTT);

    let dateText = null;

    if (currentMsgTime.format('DD') > prevMsgTime.format('DD')) {
      const todayTT = moment().startOf('day');
      const yesterdayTT = moment().startOf('day').subtract(1, 'day');
      if (currentMsgTime.valueOf() > todayTT.valueOf()) {
        dateText = 'Today';
      } else if (currentMsgTime.valueOf() > yesterdayTT.valueOf()) {
        dateText = 'Yesterday';
      } else {
        dateText = currentMsgTime.format('Do MMM YYYY');
      }
    }

    if (dateText) {
      return (
        <DateElement>
          <DateText>{dateText}</DateText>
        </DateElement>
      );
    }
    return null;
  };

  closeStandardMessages = () => {
    this.setState({
      standardMessagesOpen: false,
    });
  };

  setStandardMessage(msg) {
    this.setState({
      chatMessage: msg.message,
      standardMessagesOpen: false,
      showAttachmentContainer: false,
    });
    // Focus on the input box
    document.querySelector('#chat-input-box').focus();
  }

  closeAttachmentContainer = () => {
    this.setState({
      standardMessagesOpen: false,
      showAttachmentContainer: false,
    });
  };

  render() {
    const { chatInfo } = this.props;
    const {
      standardMessages,
      standardMessagesOpen,
      standardMessagesStatus,
    } = this.state;
    const chatMessageLink = Link.state(this, 'chatMessage').onChange((val) => {
      // if (this.timeoutId) {
      //   clearTimeout(this.timeoutId);
      // }
      this.setState({
        chatMessage: val,
      });

      // send setState to pubnub with status: ‘TYPING’
      if (val !== '') {
        this.throttledTypingStatusUpdate();
      } else {
        // send setState to pubnub with status: ‘STOP_TYPING’
        this.chatClient.setState('STOP_TYPING', this.props.user.doctorId);
      }
    });
    const renderUserMessage = (ele) => {
      switch (ele.cardType) {
        case 'text':
          return (
            <UserMessage
              key={ele.messageId}
              message={ele.text}
              timestamp={ele.timetoken}
              doctorName={ele.name}
            />
          );
        case 'chatImage':
          return (
            <UserImageMessage
              key={ele.messageId}
              filePreviewUrl={ele.filePreviewUrl}
              url={ele.url}
              timestamp={ele.timetoken}
            />
          );
        case 'documentCard':
          return (
            <UserFileMessage
              key={ele.messageId}
              fileName={ele.documentFileName || 'PDF File'}
              url={ele.url}
              timestamp={ele.timetoken}
            />
          );
        case 'prescriptionCard':
          return (
            <UserFileMessage
              key={ele.messageId}
              fileName="Prescription Card"
              url={ele.treatmentPlanPDFLink}
              timestamp={ele.timetoken}
            />
          );
        case 'treatmentPlanCard': {
          return (
            <UserFileMessage
              key={ele.messageId}
              fileName={'Prescription'}
              url={ele.prescriptionLink}
              timestamp={ele.timetoken}
            />
          );
        }
        case 'case-summary':
          return <CaseDetailsMessage caseDetails={ele.caseDetails} />;
        case 'sp-suggest':
          return (
            <SpecialistSuggestContainer>
              <SpecialistSuggestIcon src={specialistSuggestIcon} />
              <SpecialistSuggestText>
                Doctors have been recommended to the patients. Mode -{' '}
                {ele.searchType}
              </SpecialistSuggestText>
              {ele.timetoken && (
                <TimestampText>
                  {new Date(ele.timetoken).toLocaleTimeString('nu', {
                    hour: 'numeric',
                    minute: '2-digit',
                  })}
                </TimestampText>
              )}
            </SpecialistSuggestContainer>
          );
        default:
          return false;
      }
    };
    const renderPatientMessage = (ele) => {
      switch (ele.cardType) {
        case 'text':
          return (
            <DoctorMessage
              doctorImage={`https://api.samuraijack.xyz/v3/users/${ele.senderId}/profilePicture`}
              doctorName={chatInfo.chatName}
              key={ele.messageId}
              message={ele.text}
              timestamp={ele.timetoken}
            />
          );
        case 'chatImage':
          return (
            <DoctorImageMessage
              key={ele.messageId}
              filePreviewUrl={ele.filePreviewUrl}
              url={ele.url}
              timestamp={ele.timetoken}
            />
          );
        case 'documentCard':
          return (
            <DoctorFileMessage
              key={ele.messageId}
              fileName={ele.documentFileName || 'PDF File'}
              url={ele.url}
              timestamp={ele.timetoken}
            />
          );
        case 'treatmentPlanCard': {
          return (
            <DoctorFileMessage
              key={ele.messageId}
              fileName={'Prescription'}
              url={ele.prescriptionLink}
              timestamp={ele.timetoken}
            />
          );
        }
        default:
          return false;
      }
    };
    const renderBotMessage = (ele) => {
      switch (ele.cardType) {
        case 'consult-confirm':
          return (
            <ConsultationConfirmedMessage
              key={ele.messageId}
              heading={ele.text}
              title={ele.topLabel}
              profileImg={ele.profileImg}
              subTitle={ele.bottomLabel}
            />
          );
        case 'info':
          return <ChatInfoMessage key={ele.messageId} message={ele.text} />;
        default:
          return false;
      }
    };
    const renderChatMessage = (ele, idx, array) => {
      let dateElement = null;
      if (idx > 0) {
        dateElement = this.getDateElement(
          ele.timetoken,
          array[idx - 1].timetoken,
        );
      } else {
        dateElement = this.getDateElement(ele.timetoken, 0);
      }
      switch (ele.userType) {
        case 'doctor':
          return (
            <>
              {dateElement}
              {renderUserMessage(ele)}
            </>
          );
        case 'patient':
          return (
            <>
              {dateElement}
              {renderPatientMessage(ele)}
            </>
          );
        case 'user':
          return (
            <>
              {dateElement}
              {renderPatientMessage(ele)}
            </>
          );
        case 'bot':
          return (
            <>
              {dateElement}
              {renderBotMessage(ele)}
            </>
          );
        default:
          return (
            <>
              {dateElement}
              {renderUserMessage(ele)}
            </>
          );
      }
    };
    const getIcon = (ele) => {
      switch (ele.type) {
        case 'file':
          return gallery;
        case 'specialist':
          return doctor;
        case 'messages':
          return message;
        case 'followups':
          return followup;
        case 'rating':
          return star;
        case 'opd':
          return opd;
        default:
          return star;
      }
    };
    const renderAttachmentTypes = (ele, idx) => {
      const icon = getIcon(ele);
      return (
        <OptionContainer
          key={ele.name}
          onClick={() => this.openAttachmentType(ele, idx)}
        >
          <Icon src={icon} />
          <AttachmentText>{ele.name}</AttachmentText>
        </OptionContainer>
      );
    };
    const renderStandardMessage = (ele) => {
      return (
        <OptionContainer
          key={ele.id}
          onClick={() => this.setStandardMessage(ele)}
        >
          <Icon src={message} />
          <AttachmentText>{ele.message}</AttachmentText>
        </OptionContainer>
      );
    };
    const renderStandardMessages = () => {
      switch (standardMessagesStatus) {
        case 0:
          // Loading
          return <LoadingComponent />;
        case 1:
          // Success
          return (
            <>
              <HeaderWithIcon>
                <StyledTappable onTap={this.closeStandardMessages}>
                  <BackArrow />
                </StyledTappable>
                <BoldText fontSize="2rem">Standard Messages</BoldText>
              </HeaderWithIcon>
              <MessagesContainer>
                {standardMessages.map(renderStandardMessage)}
              </MessagesContainer>
            </>
          );
        case -1:
          // Failed
          return <Text>Failed to load standard messages</Text>;
        default:
          return false;
      }
    };
    return (
      <OuterContainer>
        {this.state.uploading && (
          <StyledUploadingComponent
            uploadProgress={`${this.state.uploadProgress}%`}
            borderRadius={0}
          />
        )}
        {
          <BodyContainer
            ref={this.conversationBodyRef}
            onScroll={this.onListScroll}
          >
            {this.state.fetchMessages && (
              <LoadingContainer>
                <LoadingIcon className="icon-spin" />
                <Text>Fetching Messages...</Text>
              </LoadingContainer>
            )}
            {chatInfo.chatMessages.map(renderChatMessage)}
            {this.props.chatInfo.patientTyping && (
              <TypingIndicator
                doctorImage={`https://api.samuraijack.xyz/v3/users/${this.props.chatInfo.patientTypingId}/profilePicture`}
              />
            )}
          </BodyContainer>
        }
        <InputContainer>
          <input
            type="file"
            id="imageAttachment"
            accept="image/*,.pdf"
            style={{ display: 'none' }}
            onChange={this.handleAttachment}
          />
          <StyledTappable onTap={this.attachFile}>
            <AttachIcon />
          </StyledTappable>
          <InputBox
            valueLink={chatMessageLink}
            className="visit-input"
            id="chat-input-box"
            placeholder="Type your message here"
          />
          <StyledTappable onTap={this.sendMessage}>
            <SendIcon />
          </StyledTappable>
        </InputContainer>

        {this.state.showAttachmentContainer && (
          <AttachmentContainer
            ref={this.attachmentContainerRef}
            onBlur={this.closeAttachmentContainer}
            tabIndex="1"
          >
            <SlideTransition
              in={!standardMessagesOpen}
              unmountOnExit
              classNames="slide-prev"
            >
              <AttachmentBody>
                {this.state.attachmentTypes.map(renderAttachmentTypes)}
              </AttachmentBody>
            </SlideTransition>
            <SlideTransition
              in={standardMessagesOpen}
              appear
              mountOnEnter
              unmountOnExit
              classNames="slide-next"
            >
              <AttachmentBody>{renderStandardMessages()}</AttachmentBody>
            </SlideTransition>
          </AttachmentContainer>
        )}

        {this.state.showSpecialistModal && (
          <RecommendSpecialistModal
            recommendSpecialist={(body) => this.recommendSpecialist(body)}
            hideModal={() => this.hideModal()}
          />
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  chatInfo: state.chat,
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  user: state.user,
});

export default connect(mapStateToProps)(ConsultationChatContainer);
