import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';

import { BoldText, Text } from '../common/Text';
import { PrimaryButton } from '../common/Buttons';

import { adminConsole, transferCases } from '../../services';
import { showError } from '../../utils';
import { fetchConsults } from '../../actions';

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: center;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
`;

const LabelText = styled(Text)`
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: center;
  align-self: center;
  margin-bottom: 2rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

class CloseConsultationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doctorsList: [],
      selectedDoctorIndex: -1,
      loading: false,
    };
  }

  componentDidMount() {
    adminConsole(this.props.oneConnect)
      .then((data) => {
        console.log(data);
        this.setState({ doctorsList: data.activeConsults });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.oneConnect !== prevProps.oneConnect) {
      adminConsole(this.props.oneConnect)
        .then((data) => {
          console.log(data);
          this.setState({ doctorsList: data.activeConsults });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  transferConsultation = () => {
    this.setState({ loading: true });
    const { selectedDoctorIndex } = this.state;
    const { authToken, activeFilterName, dispatch, userId } = this.props;

    transferCases([userId], selectedDoctorIndex)
      .then(() => {
        dispatch(
          fetchConsults({
            authToken,
            filters: [{ name: 'VideoCall cases', isSelected: true }],
          }),
        );
      })
      .catch((err) => {
        console.log(err);
        showError(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onDoctorSelected = (val, action) => {
    if (action.action === 'select-option') {
      this.setState({
        selectedDoctorIndex: val.value,
      });
    }
  };

  render() {
    const { doctorsList, selectedDoctorIndex } = this.state;
    const { labels } = this.props;
    const transferDoctorsList = this.props.doctorsList;
    return (
      <ModalBody>
        <ModalHeader>
          <LabelText>
            {`Please select a doctor to transfer the consult to(from ${labels[0].text})`}
          </LabelText>
        </ModalHeader>

        <Body>
          <Select
            options={doctorsList.map((ele) => ({
              value: ele.doctorId,
              label: `${ele.doctorName}(${ele.count} active consults)`,
            }))}
            onChange={this.onDoctorSelected}
            isDisabled={this.state.loading}
            classNamePrefix="react-select"
          />
          <ButtonComponent>
            <Button
              onTap={this.transferConsultation}
              disabled={selectedDoctorIndex === -1}
              loading={this.state.loading}
            >
              <ButtonText>Transfer</ButtonText>
            </Button>
          </ButtonComponent>
        </Body>
      </ModalBody>
    );
  }
}

const mapStateToProps = (state) => ({
  doctorId: state.consults.activeConsult.doctorId,
  userId: state.consults.activeConsult.user.userId,
  labels: state.consults.activeConsult.labels,
  authToken: state.user.authToken,
  activeFilterName: state.info.activeFilterName,
  doctorType: state.user.doctorType,
  doctorsList: state.info.doctorsList,
});

export default connect(mapStateToProps)(CloseConsultationModal);
