import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';

import StyledTappable from './common/StyledTappable';
import { BoldText, Text, ButtonText, RegularText } from './common/Text';
import { PrimaryButton } from './common/Buttons';
import LoadingComponent from './common/LoadingComponent';
import { getQuestionForDisease, submitAnswerForDisease } from '../services';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  ${'' /* background: rgba(0, 0, 0, 0.5); */}
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 0.5rem;
  text-align: center;
`;

const ModalTitle = styled(BoldText)`
  font-size: 1.8rem;
  margin: 0.625rem 0rem;
`;

const QuestionContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  justify-content: center;
  margin: 14px 0;
`;

const Question = styled(Text)`
  font-size: 1.8rem;
  margin-right: 24px;
  flex: 1;
  text-align: right;
`;

const StyledSelect = styled(Select)`
  flex: 1;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
`;

const StyledInput = styled(Input)`
  border: 1px solid rgb(204, 204, 204) !important;
`;

const InvalidInputText = styled(RegularText)`
  font-size: 1.4rem;
  text-align: left;
  color: #ec5d57;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin: 12px 0;
`;

const CancelButton = styled(StyledTappable)`
  margin-left: 12px;
`;

const CancelButtonText = styled(ButtonText)`
  color: #212529;
  opacity: 0.4;
`;

class DiseaseQuestionModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      questions: [],
      loading: true,
      testInput: '',
      submitting: false,
    };
  }

  componentDidMount() {
    this.getQuestionsForDisease();
  }

  componentDidUpdate(prevProps, prevState) {
    const { diseaseId } = this.props;
    if (diseaseId !== prevProps.diseaseId) {
      this.getQuestionsForDisease();
    }
  }

  getQuestionsForDisease() {
    const { diseaseId, userId, relativeId, authToken } = this.props;
    getQuestionForDisease(diseaseId, userId, relativeId, authToken)
      .then((res) => {
        const questions = res.result;
        this.inputLinks = [];
        this.setState({
          questions,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  submitAnswers = () => {
    const { questions } = this.state;
    const {
      userId,
      relativeId,
      diseaseId,
      diseaseName,
      authToken,
      onSubmit,
    } = this.props;
    const answers = questions.map((ele) => ({
      subQuestionId: ele.subQuestionId,
      answer: ele.answer || ele.defaultValue,
    }));
    this.setState({ submitting: true });
    submitAnswerForDisease(diseaseId, userId, relativeId, answers, authToken)
      .then(() => {
        onSubmit && onSubmit(diseaseId, diseaseName, relativeId);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  };

  onAnswerSelected(val, action, questionIdx) {
    console.log(val, action, questionIdx);
    const { questions } = this.state;
    if (action.action === 'select-option') {
      const updatedQuestions = Array.from(questions);
      updatedQuestions[questionIdx].answer = val.label;
      this.setState({
        questions: updatedQuestions,
      });
    }
  }

  render() {
    const { loading, questions, submitting } = this.state;
    const { onClose, diseaseName, relativeName } = this.props;
    const inputLinks = [];
    questions.forEach((ele, idx) => {
      if (ele.type === 'year') {
        const objectLink = Link.state(this, 'questions');
        const inputLink = objectLink
          .pipe((obj, prevObj) => {
            if (
              obj[idx].answer.length > 4 &&
              prevObj[idx].answer.length === 4
            ) {
              return prevObj;
            }
            // Check the character inputted is valid
            if (obj[idx].answer.length > prevObj[idx].answer.length) {
              const newCharacterStartIndex = prevObj[idx].answer.length;
              const newCharacters = obj[idx].answer.slice(
                newCharacterStartIndex,
              );
              if (!newCharacters.match(/\d+/)) {
                return prevObj;
              }
            }
            return obj;
          })
          .at(idx)
          .at('answer')
          .check((val) => val.match(/^(\d{4})$/), 'Invalid year');
        inputLinks.push({
          id: ele.subQuestionId,
          link: inputLink,
        });
      } else if (ele.type === 'date') {
        const objectLink = Link.state(this, 'questions');
        const inputLink = objectLink
          .pipe((obj, prevObj) => {
            if (
              obj[idx].answer.length > 10 &&
              prevObj[idx].answer.length === 10
            ) {
              return prevObj;
            }
            // Check the character inputted is valid
            if (obj[idx].answer.length > prevObj[idx].answer.length) {
              const newCharacterStartIndex = prevObj[idx].answer.length;
              const newCharacters = obj[idx].answer.slice(
                newCharacterStartIndex,
              );
              if (!newCharacters.match(/\d+/)) {
                return prevObj;
              }
            }
            if (
              obj[idx].answer.length < prevObj[idx].answer.length &&
              prevObj[idx].answer.endsWith('/')
            ) {
              // User is trying to remove the character before the slash. Remove both characters and return
              obj[idx].answer = obj[idx].answer.substring(
                0,
                obj[idx].answer.length - 1,
              );
            } else if (
              prevObj[idx].answer.length === 1 &&
              obj[idx].answer.endsWith('/')
            ) {
              obj[idx].answer = `0${obj[idx].answer}`;
            } else if (
              obj[idx].answer.length === 5 &&
              obj[idx].answer.endsWith('/')
            ) {
              console.log(
                obj[idx].answer.substring(0, 3),
                obj[idx].answer.substring(3),
              );
              obj[idx].answer = `${obj[idx].answer.substring(0, 3)}0${obj[
                idx
              ].answer.substring(3)}`;
            } else if (
              obj[idx].answer.length === 2 ||
              obj[idx].answer.length === 5
            ) {
              obj[idx].answer = `${obj[idx].answer}/`;
            }
            return obj;
          })
          .at(idx)
          .at('answer')
          .check(
            (val) => val.match(/^(\d{1,2}\/\d{1,2}\/\d{4})$/),
            'Invalid year',
          );
        inputLinks.push({
          id: ele.subQuestionId,
          link: inputLink,
        });
      } else if (ele.type === 'text') {
        const objectLink = Link.state(this, 'questions');
        const inputLink = objectLink.at(idx).at('answer');
        inputLinks.push({
          id: ele.subQuestionId,
          link: inputLink,
        });
      }
    });
    const submitEnabled =
      questions.every((ele) => {
        if (ele.type === 'dropdown') {
          return ele.answer.trim() !== '';
        }
        return ele.answer.trim() !== '';
      }) &&
      inputLinks.every(
        (ele) => ele.link.error === null || ele.link.error === undefined,
      );
    const getLinkForSubQuestion = (subQuestionId) => {
      const inputLinkIndex = inputLinks.findIndex(
        (ele) => ele.id === subQuestionId,
      );
      if (inputLinkIndex !== -1) {
        return inputLinks[inputLinkIndex].link;
      }
      return null;
    };
    const renderQuestion = (ele, idx) => {
      switch (ele.type) {
        case 'text':
          const inputLink = getLinkForSubQuestion(ele.subQuestionId);
          return (
            <QuestionContainer key={idx}>
              <Question>{ele.text}</Question>
              <InputContainer>
                <StyledInput
                  className="visit-input"
                  valueLink={inputLink}
                  placeholder="Specify Disease"
                />
                {inputLink.value.length > 0 && (
                  <InvalidInputText>{inputLink.error}</InvalidInputText>
                )}
              </InputContainer>
            </QuestionContainer>
          );
        case 'dropdown':
          return (
            <QuestionContainer key={idx}>
              <Question>{ele.text}</Question>
              <StyledSelect
                options={ele.options.map((ele) => ({ value: ele, label: ele }))}
                defaultValue={{
                  value: ele.defaultValue,
                  label: ele.defaultValue,
                }}
                value={
                  ele.answer.trim().length === 0
                    ? {
                        value: ele.defaultValue,
                        label: 'Select one of the options',
                      }
                    : { value: ele.answer, label: ele.answer }
                }
                onChange={(val, action) => {
                  this.onAnswerSelected(val, action, idx);
                }}
                backspaceRemovesValue={false}
              />
            </QuestionContainer>
          );
        case 'year': {
          const inputLink = getLinkForSubQuestion(ele.subQuestionId);
          return (
            <QuestionContainer key={idx}>
              <Question>{ele.text}</Question>
              <InputContainer>
                <StyledInput
                  className="visit-input"
                  valueLink={inputLink}
                  placeholder="YYYY"
                />
                {inputLink.value.length > 0 && (
                  <InvalidInputText>{inputLink.error}</InvalidInputText>
                )}
              </InputContainer>
            </QuestionContainer>
          );
        }
        case 'date': {
          const inputLink = getLinkForSubQuestion(ele.subQuestionId);
          return (
            <QuestionContainer key={idx}>
              <Question>{ele.text}</Question>
              <InputContainer>
                <StyledInput
                  className="visit-input"
                  valueLink={inputLink}
                  placeholder="DD/MM/YYYY"
                />
                {inputLink.value.length > 0 && (
                  <InvalidInputText>{inputLink.error}</InvalidInputText>
                )}
              </InputContainer>
            </QuestionContainer>
          );
        }
        default:
          return false;
      }
    };
    return (
      <ModalContainer>
        <ModalBody>
          <ModalTitle>{`${diseaseName} - Questions for ${relativeName}`}</ModalTitle>
          {loading ? (
            <LoadingComponent />
          ) : questions.length === 0 ? (
            `Are you sure you want to add ${diseaseName} to the list?`
          ) : (
            questions.map(renderQuestion)
          )}
          <ButtonsContainer>
            <PrimaryButton
              disabled={!submitEnabled}
              onTap={this.submitAnswers}
              loading={submitting}
            >
              <ButtonText>Submit</ButtonText>
            </PrimaryButton>
            <CancelButton onTap={onClose}>
              <CancelButtonText>Cancel</CancelButtonText>
            </CancelButton>
          </ButtonsContainer>
        </ModalBody>
      </ModalContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(DiseaseQuestionModal);
