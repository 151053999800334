import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import TappableCard from '../common/TappableCard';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const StyledTappableCard = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin: 0rem 0.625rem;
  border: ${(props) => (props.isSelected ? '0.1rem solid #8852cc' : 'null')};
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: ${(props) => (props.isSelected ? '#8852cc' : 'black')};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-left: 0.625rem;
  flex: 1;
`;

const LabelText = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const SelectElement = styled(Select)`
  flex: 1;
  max-width: 30rem;
  /* border: 0.1rem solid #8852cc; */
`;

class AddMedicinesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consultType: 'clinic',
      vertical: '',
    };
  }

  componentDidMount = () => {
    this.props.verticals.map((vertical) => {
      vertical.label = vertical.verticalName;
      vertical.value = vertical.verticalId;
      return vertical;
    });
  };

  selectConsultType = (consultType) => {
    this.setState({ consultType });
  };

  onVerticalSelect = (option) => {
    this.setState({
      vertical: option.value,
    });
  };

  render() {
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Recommend Specialist</HeaderText>
              <ButtonsComponent>
                <ButtonComponent>
                  <Button
                    onTap={() => this.props.recommendSpecialist(this.state)}
                    // loading={this.state.loading}
                  >
                    <ButtonText>Recommend</ButtonText>
                  </Button>
                </ButtonComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <InputContainer>
                <AgeContainer>
                  <LabelText>Select Vertical</LabelText>
                  <SelectElement
                    options={this.props.verticals}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#d5d5d5',
                        primary25: '#f5f5f5',
                        primary: '#8852cc',
                      },
                    })}
                    onChange={(option) => this.onVerticalSelect(option)}
                  />
                </AgeContainer>
              </InputContainer>
              <InputContainer>
                <StyledTappableCard
                  onTap={() => this.selectConsultType('online')}
                  isSelected={
                    this.state.consultType === 'online' ? true : false
                  }
                >
                  <TappableText
                    isSelected={
                      this.state.consultType === 'online' ? true : false
                    }
                  >
                    Online Consult
                  </TappableText>
                </StyledTappableCard>
                <StyledTappableCard
                  onTap={() => this.selectConsultType('clinic')}
                  isSelected={
                    this.state.consultType === 'clinic' ? true : false
                  }
                >
                  <TappableText
                    isSelected={
                      this.state.consultType === 'clinic' ? true : false
                    }
                  >
                    Clinic Consult
                  </TappableText>
                </StyledTappableCard>
              </InputContainer>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  verticals: state.info.verticals,
});

export default connect(mapStateToProps)(AddMedicinesModal);
