/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

import { Text, RegularText } from '../../common/Text';
import StyledTappable from '../../common/StyledTappable';
import downloadPdf from '../../../images/consultations/downloadPdf.svg';

const OuterContainer = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  margin: 0.4rem 0;
`;

const BubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem 1.2rem;
  background: #8852cc;
  border-radius: 1.4rem;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FileIcon = styled.img`
  width: 3.6rem;
  margin-right: 0.8rem;
  color: #fff;
`;

const FileName = styled(Text)`
  flex: 1;
  font-size: 1.6rem;
  color: #fff;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1.6rem;
`;

const TickIcon = styled(FaCheck)`
  color: #fff;
  opacity: 0.5;
  font-size: 1rem;
  margin-right: 0.4rem;
`;

const TimestampText = styled(RegularText)`
  color: #fff;
  opacity: 0.5;
  font-size: 1.2rem;
`;

const UserDocumentMessage = ({ fileName, url, timestamp }) => (
  <OuterContainer
    // onTap={() => document.querySelector('#document-anchor').click()}
    onTap={() => window.open(url)}
  >
    <BubbleContainer>
      <MessageContainer>
        <FileIcon src={downloadPdf} />
        <FileName>{fileName}</FileName>
      </MessageContainer>
      {timestamp && (
        <TimestampContainer>
          <TickIcon />
          <TimestampText>
            {new Date(timestamp).toLocaleTimeString('nu', {
              hour: 'numeric',
              minute: '2-digit',
            })}
          </TimestampText>
        </TimestampContainer>
      )}
    </BubbleContainer>
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      id="document-anchor"
      style={{ display: 'none' }}
    />
  </OuterContainer>
);

UserDocumentMessage.propTypes = {
  fileName: PropTypes.string,
  url: PropTypes.string,
  timestamp: PropTypes.number,
};

export default UserDocumentMessage;
