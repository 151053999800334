export const defaultState = {
  consultationId: null,
  chatMessages: [],
  chatChannel: null,
  chatName: null,
  chatIcon: null,
  chatSubtitle: null,
  receiverId: null,
  consultationSummary: null,
  patientTyping: false,
  patientTypingId: null,
};

export default function chat(state = defaultState, action) {
  switch (action.type) {
    case 'SET_CHAT_INFO':
      return Object.assign({}, state, {
        consultationId: action.payload.consultationId || state.consultationId,
        chatChannel: action.payload.chatChannel || state.chatChannel,
        // unrepliedChannel: action.payload.unrepliedChannel || state.unrepliedChannel,
        chatName: action.payload.chatName || state.chatName,
        chatIcon: action.payload.chatIcon || state.chatIcon,
        chatSubtitle: action.payload.chatSubtitle || state.chatSubtitle,
        receiverId: action.payload.receiverId || state.receiverId,
      });
    case 'SET_CHAT_MESSAGES':
      return Object.assign({}, state, {
        chatMessages: action.payload.chatMessages,
      });
    case 'ADD_CHAT_MESSAGE':
      return Object.assign({}, state, {
        chatMessages: state.chatMessages.concat([action.payload.chatMessage]),
      });
    case 'RESET_CHAT':
      return Object.assign({}, state, defaultState);
    case 'SET_CONSULTATION_SUMMARY':
      return Object.assign({}, state, {
        consultationSummary: action.payload,
      });
    case 'SET_DOCTOR_TYPING':
      return Object.assign({}, state, {
        patientTyping: action.payload.typing,
        patientTypingId: action.payload.patientId,
      });
    case 'LOGOUT_USER': {
      return Object.assign({}, state, defaultState);
    }
    default:
      return state;
  }
}
