import React from 'react';
import styled from 'styled-components';
import { FaPhone } from 'react-icons/fa';
import { RegularText, Text } from './common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: 1.2rem;
  background: ${(props) =>
    props.active ? 'RGBA(136, 82, 204, 0.3)' : 'white'};
  border-bottom: 1px solid #dfdfdf;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const UnrepliedIcon = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: #8852cc;
  margin-left: 8px;
`;

const TitleText = styled(Text)`
  font-size: 1.6rem;
`;

const CallIcon = styled(FaPhone)`
  color: #56c02c;
  margin-left: 6px;
`;

const DateText = styled(Text)`
  font-size: 1.2rem;
  opacity: 0.3;
`;

const TimeText = styled(Text)`
  padding-top: 0.25rem;
  font-size: 1.2rem;
  color: #d72323;
`;

const TagsText = styled(RegularText)`
  font-size: 1.4rem;
`;

const LabelsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => (props.color ? props.color : '#8852CC')};
  margin: 1px 0.25rem;
  border-radius: 0.4rem;
  padding: 0.4rem 0.8rem;
`;

const LabelText = styled(Text)`
  font-size: 1.2rem;
  color: white;
`;

export default ({
  userName,
  issues,
  labels,
  active,
  timeText,
  dateText,
  unreplied,
  requestType,
  admin,
}) => {
  const renderLabel = (ele, idx) => {
    if (ele.text.includes('Attempts') && !admin) {
      return false;
    }
    return (
      <LabelContainer key={idx} color={ele.color}>
        <LabelText>{ele.text}</LabelText>
      </LabelContainer>
    );
  };
  return (
    <OuterContainer active={active}>
      <TitleContainer>
        <TitleText>
          {userName}
          {requestType === 'voice' && <CallIcon />}
        </TitleText>
        <RightContainer>
          {admin ? (
            <>
              <DateText>{dateText}</DateText>
              {timeText ? <TimeText>{timeText}</TimeText> : null}
            </>
          ) : (
            ''
          )}
          {unreplied && <UnrepliedIcon />}
        </RightContainer>
      </TitleContainer>
      <TagsText>{issues}</TagsText>
      <LabelsContainer>
        <>{labels.map(renderLabel)}</>
      </LabelsContainer>
    </OuterContainer>
  );
};
