import React from 'react';
import {func,bool} from 'prop-types';

const NavigationButtons = ({ submitResponse, submitDisabled, goToPrevious }) => {
  return (
    <div className="bg-primary mt-4">
      <div className="d-flex w-100 justify-content-between">
        {goToPrevious && (
          <button
          type="button"
          onClick={goToPrevious}
          className="btn btn-primary p-3 w-100">
          <i className="fa fa-chevron-left mr-3 pt-1 float-left"></i>
          <p className="h5 m-0 float-left">Prev</p>
        </button>
        )}
        <button
          type="button"
          onClick={submitResponse}
          className="btn btn-primary p-3 w-100"
          disabled={submitDisabled} >
          <p className="h5 m-0 float-left">Next</p>
          <i className="fa fa-chevron-right ml-3"></i>
        </button>
      </div>
    </div>
  );
};

NavigationButtons.propTypes = {
  submitResponse: func.isRequired,
  submitDisabled: bool.isRequired,
  goToPrevious: func.isRequired,
};

export default NavigationButtons;
