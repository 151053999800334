import styled from 'styled-components';

const Text = styled.p`
  margin: 0;
  font-family: Inter, Lato, sans-serif;
  color: ${(props) => props.color || 'black'};
  font-size: ${(props) => props.fontSize || '1.6rem'};
`;
const SemiboldText = styled(Text)`
  font-family: Inter, sans-serif;
  font-weight: 600;
`;

const BoldText = styled(SemiboldText)`
  font-family: Inter, sans-serif;
  font-weight: 700;
`;

const BlackText = styled(SemiboldText)`
  font-family: Inter, sans-serif;
  font-weight: 900;
`;

const RegularText = styled(SemiboldText)`
  font-family: Inter, sans-serif;
  font-weight: 400;
`;

const ButtonText = styled(BoldText)`
  font-size: 1.6rem;
  color: white;
  text-align: center;
`;

const SubscriptionText = styled(ButtonText)`
  background-image: linear-gradient(to left, #ffa14f, #db4c6d);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

const LinkText = styled(BoldText)`
  display: inline-block;
  font-size: 1.6rem;
  color: #8852cc;
`;

const TextInput = styled.input`
  font-family: BrandonText-Medium, Lato, sans-serif;
  border: none;
`;

export {
  Text,
  BoldText,
  ButtonText,
  TextInput,
  BlackText,
  LinkText,
  RegularText,
  SubscriptionText,
};
