import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../containers/common/Modal';
import styled from 'styled-components';
import StyledTappable from '../common/StyledTappable';
// import { BoldText, Text } from '../components/common/Text';
import { BoldText, Text } from '../../components/common/Text';
import { FaCalendarAlt, FaTimes } from 'react-icons/fa';
// import { PrimaryButton } from './common/Buttons';
import { PrimaryButton } from '../common/Buttons';
import { getVideoDetail, setVideo } from '../../services';
import LoadingComponent from '../common/LoadingComponent';
import moment from 'moment';
const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 60%;
  overflow: hidden;
  border-left: 1px solid #ececec;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: white;
  padding: 0.6rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;
const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
  text-align: center;
  align-self: center;
`;

const LabelText = styled(Text)`
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: center;
  align-self: center;
  margin-bottom: 2rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const CalendarIcon = styled(FaCalendarAlt)`
  font-size: 1.5rem;
  color: #fff;
  margin-right: 1rem;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

class SelectVideoModal extends Component {
  constructor(props) {
    super(props);
    console.log('ss', props);
    this.state = {
      showModal: true,
      videoData: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getVideoDetail();
  }

  getVideoDetail = () => {
    this.setState({
      loading: true,
    });
    getVideoDetail(this.props.authToken, this.props.sessionId)
      .then((res) => {
        console.log(res);
        this.setState({
          videoData: res.videoCalls,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  selectVideo = (ele) => {
    this.setState({
      loading: true,
    });
    setVideo(ele, this.props.authToken)
      .then((res) => {
        this.props.hideModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div>
        <Modal>
          <ModalContainer>
            <ModalBody>
              <ModalHeader>
                <HeaderText>All Videos</HeaderText>
                <ButtonsComponent>
                  <CloseContainer onTap={this.props.hideModal}>
                    <CrossIcon />
                  </CloseContainer>
                </ButtonsComponent>
              </ModalHeader>
              {/* <Body> */}
              <div className="table-responsive mt-3">
                {this.state.loading ? (
                  <LoadingComponent />
                ) : (
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Url</th>
                        <th scope="col">Date Time</th>
                        <th scope="col">Update Recording</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.videoData.map((ele, idx) => {
                        return (
                          <tr>
                            <td>
                              <Text fontSize="14px">
                                <a
                                  href={ele.url}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  {'View'}
                                </a>
                              </Text>
                            </td>
                            <td>
                              <Text fontSize="14px">
                                {moment(ele.createdAt).format(
                                  'DD/MM/YYYY HH:mm',
                                )}
                              </Text>
                            </td>
                            <td>
                              <Text fontSize="14px">
                                <a
                                  onClick={() => this.selectVideo(ele)}
                                  style={{ color: '#8852CC' }}
                                >
                                  Update this
                                </a>
                              </Text>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              {/* </Body> */}
            </ModalBody>
          </ModalContainer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  user: state.user,
  insurer: state.user.insurer,
});

export default connect(mapStateToProps)(SelectVideoModal);
