import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';

export default styled(Tappable)`
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: ${props => (props.disabled || props.loading ? '1' : '0.2')};
  }
`;
