import { store } from "../store/index";

export const showSuccess = message => {
  let messageTitle = "Success";
  let messageBody = "This task is successfully completed.";

  if (message) {
    messageTitle = message.messageTitle;
    messageBody = message.messageBody;
  }

  console.log({ message, messageTitle, messageBody });

  store.dispatch({
    type: "SHOW_SUCCESS",
    payload: {
      messageTitle: messageTitle,
      messageBody: messageBody
    }
  });
  if (messageTitle === 'INBOUND CALL') {
    store.dispatch({
      type: 'INBOUND CALL',
      payload: {
        messageBody: message.messageBody,
      },
    });
  }
};
