import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';

import { loginUser } from '../services';
import { verifyUser, loginUser as loginVerify } from '../actions/index';
import visitLogo from '../images/common/visit-logo.svg';
import { PrimaryButton } from '../components/common/Buttons';
import { BoldText, ButtonText } from '../components/common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding: 2.4rem;
`;

const Icon = styled.img`
  max-width: 16rem;
  margin-bottom: 3.2rem;
`;

const TitleText = styled(BoldText)`
  font-size: 2rem;
  margin-bottom: 3.2rem;
`;

const StyledInput = styled(Input)`
  font-size: 1.6rem;
  padding: 0.8rem;
  margin: 1rem 0;
  border-bottom: 1px solid #eee !important;
`;

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: '',
      password: '',
      otp: '',
      loading: false,
    };
  }

  componentDidUpdate = () => {
    const { loading } = this.state;
    const { appError } = this.props;
    if (loading && appError.hasError) {
      this.setState({
        loading: false,
      });
    }
  };

  login = (e) => {
    e && e.preventDefault();
    const { phone, password } = this.state;
    const { dispatch, history } = this.props;
    this.setState({ loading: true });
    if (phone === '7023456161' && false) {
      loginUser(phone, password, true).then(() => {
        this.setState({ loading: false, verifyingOtp: true });
      });
    } else {
      dispatch(loginVerify(phone, password, history));
    }

    // dispatch(loginUser(phone, password, history));
  };

  verifyOtp = (e) => {
    e && e.preventDefault();
    const { phone, otp } = this.state;
    const { dispatch, history } = this.props;
    this.setState({ loading: true });
    dispatch(verifyUser(phone, otp, history));
  };

  render() {
    const { loading, verifyingOtp } = this.state;
    const numberLink = Link.state(this, 'phone');
    const passwordLink = Link.state(this, 'password');
    const otpLink = Link.state(this, 'otp');
    return (
      <OuterContainer as="form" action="#">
        <Icon src={visitLogo} />
        {verifyingOtp ? (
          <div>
            <TitleText>Enter OTP</TitleText>
            <StyledInput
              className="visit-input"
              type="tel"
              valueLink={otpLink}
              placeholder="Enter Your OTP"
            />
            <input
              type="submit"
              style={{ display: 'none' }}
              onClick={this.verifyOtp}
            />
            <PrimaryButton loading={loading} onTap={this.verifyOtp}>
              <ButtonText>Verify OTP</ButtonText>
            </PrimaryButton>
          </div>
        ) : (
          <>
            <TitleText>Welcome Back!</TitleText>
            <StyledInput
              className="visit-input"
              type="tel"
              valueLink={numberLink}
              placeholder="Your Phone Number"
            />
            <StyledInput
              className="visit-input"
              type="password"
              valueLink={passwordLink}
              placeholder="Password"
            />
            <input
              type="submit"
              style={{ display: 'none' }}
              onClick={this.login}
            />
            <PrimaryButton loading={loading} onTap={this.login}>
              <ButtonText>Login</ButtonText>
            </PrimaryButton>
          </>
        )}
      </OuterContainer>
    );
  }
}
        


const mapStateToProps = (state) => ({
  appError: state.app.appError,
});

export default connect(mapStateToProps)(LoginPage);
