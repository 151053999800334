export const debounce = (func, wait, immediate) => {
  let timeout;
  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const promiseDebounce = (func, wait) => {
  let timeout;
  let promiseResolve;
  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      console.log(promiseResolve);
      promiseResolve(func.apply(context, args));
    };
    if (timeout) {
      console.log('Clearing timeout');
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      console.log(promiseResolve);
      promiseResolve(new Promise((resolve, reject) => {
        promiseResolve = resolve;
      }));
    } else {
      console.log('Setting timeout');
      timeout = setTimeout(later, wait);
      return new Promise((resolve, reject) => {
        promiseResolve = resolve;
      });
    }
  }
}

export const reverseDebounce = (func, wait, immediate) => {
  let disableFuncCall = false;
  return (...args) => {
    if (!disableFuncCall || immediate) {
      func.apply(this, args);
      disableFuncCall = true;
      setTimeout(() => {
        disableFuncCall = false;
      }, wait);
    }
  };
};
