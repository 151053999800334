import axios from 'axios';
import PubNub from 'pubnub';
import { store } from '../store/index';
import config from '../config';
import { getBaseUrlForInsurer } from './utils';
import { showError } from '../utils';
import { showSuccess, userStatus } from '../actions/index';

class VideoCallClient {
  constructor(pubnub) {
    this.pubnub = pubnub;
  }

  subscribe(channelId, insurer) {
    this.pubnub.subscribe({
      channels: [channelId],
      // channelGroups: [channelId],
    });

    console.log('subscribed to channel', { channelId });
    this.messageListener = this.addMessageListener((payload, channelName) => {
      if (parseInt(channelName) === parseInt(channelId)) {
        console.log(payload);
        if (
          payload.name === 'user_joined' &&
          (payload.insurer === insurer ||
            (payload.insurer === 'icici' && insurer === 'ipru'))
        ) {
          store.dispatch(userStatus(false));
        } else if (
          payload.name === 'user_left' &&
          (payload.insurer === insurer ||
            (payload.insurer === 'icici' && insurer === 'ipru'))
        ) {
          store.dispatch(userStatus(true));
        }
        if (
          payload.name === 'SCAN_MSG' &&
          (payload.insurer === insurer ||
            (payload.insurer === 'icici' && insurer === 'ipru'))
        ) {
          store.dispatch(showSuccess('SCAN UPDATE', payload.message));
        }
      }
    });
  }

  unsubscribe(channelId) {
    console.log(
      this.pubnub.unsubscribe({
        channels: [channelId],
      }),
    );
    this.removeListener(this.messageListener);
  }

  addMessageListener(fnc) {
    const listener = {
      message: (m) => {
        console.log({ m }, 'new message');
        const channelName = parseInt(m.channel); // The channel for which the message belongs

        const payload = m.message; // The Payload

        fnc(payload, channelName, '', '');
        return;
      },
    };

    this.pubnub.addListener(listener);
    console.log(this.pubnub);
    return listener;
  }

  removeListener(fnc) {
    this.pubnub.removeListener(fnc);
  }

  publish(sessionId) {
    console.log(sessionId);

    this.pubnub.publish(
      {
        message: {
          name: 'doctor_joined',
          sessionId: sessionId,
        },
        channel: sessionId,
      },
      (status, response) => {
        if (status.error) {
          // Handle error
          console.log(status);
          return;
        }
        console.log(status, response);
      },
    );
  }
}

let client;

const initializeVideoCallClient = () => {
  if (client) {
    return client;
  } else {
    const { user } = store.getState();
    const pubnubParams = {
      subscribeKey: config.pubnubSubscribeKey,
      publishKey: config.pubnubPublishKey,
      authKey: user.doctorUuid,
      uuid: `browser::${user.doctorUuid}`,
    };

    const pubnub = new PubNub(pubnubParams);

    client = new VideoCallClient(pubnub);
    return client;
  }
};

export { initializeVideoCallClient };

export function scheduleCall(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/schedule-call`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function joinCall(callId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/join-call?callId=${callId}&userType=doctor`;
  return axios
    .get(url, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        if (responseBody.message === 'User already joined') {
          const err = new Error('Doctor already joined.');
          err.data = 'Doctor already joined';
          return err;
        } else {
          const err = new Error('Invalid response');
          err.data = responseBody;
          throw err;
        }
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function cancelScheduleCall(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/cancelled-call`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
export function joinLog(body) {
  const url = `${getBaseUrlForInsurer()}/join-call-log`;
  return axios
    .post(url, body)
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function videoScreenshot(formData, callId, type) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/video-screenshot?callId=${callId}&type=${type}`;
  return axios
    .post(url, formData, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function videoScreenshotForRelative(formData, relativeId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const url = `${getBaseUrlForInsurer()}/video-screenshot?relativeId=${relativeId}`;
  return axios
    .post(url, formData, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function markCasePartialTele(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${getBaseUrlForInsurer()}/mark-case-partial-tele`;
  return axios
    .post(url, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}






export function scanPatient(userId, callId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const body = {
    callID: callId,
    userId: userId
  };

  const url = `${getBaseUrlForInsurer()}/scan-patient`;
  return axios.post(url, body, { headers }).then((res) => {
    const responseBody = res.data;
    if (responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    }
  })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
export function recordTimestamp(consultationId, timestamps) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const body = {
    timestamps,
    consultationId: consultationId
  };

  const url = `${getBaseUrlForInsurer()}/record-timestamps`;
  return axios.post(url, body, { headers }).then((res) => {
    const responseBody = res.data;
    if (responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    }
  })
    .catch((err) => {
      showError(err);
      throw err;
    });
}









