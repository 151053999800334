import React, { Component } from 'react';
import { connect } from 'react-redux';
import { number } from 'prop-types';
import axios from 'axios';

import EndScreen from './EndScreen';
import SingleSelect from './SingleSelect';
import RelativeSelect from './RelativeSelect';
import MultipleSelect from './MultipleSelect';
import SingleSelectArray from './SingleSelectArray';
import StringInput from './StringInput';
import config from '../../config';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: null,
      end: false,
      userResponse: null,
      relativeId: null,
      relatives: [],
    };
  }

  componentWillMount() {
    // Call the api for the user to check for progress
    const { userId } = this.props;
    const { relativeId } = this.state;
    if (userId) {
      if (!relativeId) {
        this.getRelatives();
      } else {
        this.getFormQuestion();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { userId } = this.props;
    if (prevProps.userId !== userId) {
      this.setState({
        currentQuestion: null,
        end: false,
        userResponse: null,
        relativeId: null,
        relatives: [],
      });
      // The user reset. Get the relatives list
      this.getRelatives();
    }
  }

  getRelatives() {
    const { userId, authToken } = this.props;
    axios
      .get(`${config.apiBaseUrl}/diseases/selected/${userId}`, {
        headers: {
          authorization: authToken,
        },
      })
      .then((response) => {
        const responseBody = response.data;
        if (responseBody.message === 'success') {
          this.setState({ relatives: responseBody.result });
        } else {
          const err = new Error('Invalid response');
          err.data = responseBody;
          throw err;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getFormQuestion() {
    const { userId, authToken } = this.props;
    const { relativeId } = this.state;
    /* eslint-disable no-console */
    axios
      .post(
        `${config.apiBaseUrl}/forms/new-start`,
        {
          userId,
          relativeId,
        },
        {
          headers: {
            authorization: authToken,
          },
        },
      )
      .then((response) => {
        const responseBody = response.data;
        console.log(responseBody);
        if (responseBody.message === 'success') {
          this.setState({
            currentQuestion: responseBody.result,
          });
        } else if (responseBody.message === 'end') {
          this.setState({
            end: true,
          });
        } else {
          // Something went wrong
          console.log('Something went wrong');
        }
      })
      .catch((err) => {
        console.error(err);
      });
    /* eslint-enable */
  }

  goToPrevious = () => {
    /* eslint-disable no-console */
    // axios.get(`${config.apiBaseUrl}health-risk-assessment/${this.props.userId}/form/${this.state.currentQuestion.parentOrder}/${this.state.currentQuestion.questionOrder}`)
    //   .then((response) => {
    //     const responseBody = response.data;
    //     console.log(responseBody);
    //     if (responseBody.message === 'success') {
    //       this.setState({
    //         currentQuestion: responseBody.question,
    //         userResponse: responseBody.userResponse,
    //       });
    //     } else if(responseBody.message === 'end') {
    //       this.setState({
    //         end: true,
    //       });
    //     } else {
    //       // Something went wrong
    //       console.log('Something went wrong');
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
    /* eslint-enable */
  };

  selectRelative = (response) => {
    this.setState(
      {
        relativeId: response,
      },
      () => {
        this.getFormQuestion();
      },
    );
  };

  submitResponse = (response) => {
    const { userId, authToken } = this.props;
    const { currentQuestion } = this.state;
    /* eslint-disable no-console */
    const requestBody = {
      userId,
      answer: response,
      relativeId: currentQuestion.relativeId,
      orderId: currentQuestion.orderId,
    };
    axios
      .post(`${config.apiBaseUrl}/forms/new-next`, requestBody, {
        headers: {
          authorization: authToken,
        },
      })
      .then((response) => {
        const responseBody = response.data;
        console.log(responseBody);
        if (responseBody.message === 'success') {
          this.setState({
            currentQuestion: responseBody.result,
          });
        } else if (responseBody.message === 'nextRelative') {
          this.setState({
            relativeId: null,
          });
          this.getRelatives();
        } else if (responseBody.message === 'end') {
          this.setState({
            end: true,
          });
        } else {
          // Something went wrong
          console.log('Something went wrong');
        }
      })
      .catch((err) => {
        console.error(err);
      });
    /* eslint-enable */
  };

  onRelativesChange = (relatives) => {
    console.log(relatives);
    this.setState({
      relatives,
    });
  };

  renderScreen() {
    if (this.state.end) {
      return <EndScreen userId={this.props.userId} />;
    }

    if (!this.state.relativeId) {
      return (
        <RelativeSelect
          title="Choose Relative"
          question="Please select a relative to continue"
          options={this.state.relatives}
          onChange={this.onRelativesChange}
          submitResponse={this.selectRelative}
          closeConsultation={this.props.closeConsultation}
        />
      );
    }

    if (!this.state.currentQuestion) {
      return false;
    }

    switch (this.state.currentQuestion.type) {
      case 'single_select':
        return (
          <SingleSelect
            title={this.state.currentQuestion.title}
            question={this.state.currentQuestion.text}
            options={this.state.currentQuestion.options}
            info={this.state.currentQuestion.info}
            userResponse={this.state.userResponse}
            currentOrder={this.state.currentQuestion.currentOrder}
            totalQuestions={this.state.currentQuestion.totalQuestions}
            submitResponse={this.submitResponse}
          />
        );
      // goToPrevious={this.goToPrevious} />;
      case 'multiple_select':
        return (
          <MultipleSelect
            title={this.state.currentQuestion.title}
            question={this.state.currentQuestion.text}
            options={this.state.currentQuestion.options}
            info={this.state.currentQuestion.info}
            userResponse={this.state.userResponse}
            currentOrder={this.state.currentQuestion.currentOrder}
            totalQuestions={this.state.currentQuestion.totalQuestions}
            submitResponse={this.submitResponse}
          />
        );
      case 'single_select_array':
        return (
          <SingleSelectArray
            title={this.state.currentQuestion.title}
            question={this.state.currentQuestion.text}
            options={this.state.currentQuestion.options}
            info={this.state.currentQuestion.info}
            userResponse={this.state.currentQuestion.options}
            currentOrder={this.state.currentQuestion.currentOrder}
            totalQuestions={this.state.currentQuestion.totalQuestions}
            submitResponse={this.submitResponse}
          />
        );
      // goToPrevious={this.goToPrevious} />;
      case 'text_input':
        return (
          <StringInput
            title={this.state.currentQuestion.title}
            question={this.state.currentQuestion.text}
            info={this.state.currentQuestion.info}
            userResponse={this.state.userResponse}
            currentOrder={this.state.currentQuestion.currentOrder}
            totalQuestions={this.state.currentQuestion.totalQuestions}
            submitResponse={this.submitResponse}
          />
        );
      // goToPrevious={this.goToPrevious} />;
      default:
        return <h1>Hello World</h1>;
    }
  }

  render() {
    return this.renderScreen();
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(App);

App.propTypes = {
  userId: number.isRequired,
};
