import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../containers/common/Modal';
import StyledTappable from './common/StyledTappable';
import { connect } from 'react-redux';
import { Text, BoldText } from './common/Text';
import { PrimaryButton } from './common/Buttons';
import { FaTimes } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { updateMemberInfo, getUserMembers } from '../services';
import { resetActiveConsult } from '../actions';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

class UpdateInfoModalLic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showAlert: false,
      alertText: '',
      alertType: 'success',
      name: '',
      branchCode: '',
      proposalNo: '',
    };
    this.state.name = props.relative.name;
    this.state.proposalNo = props.relative.proposalNumber;
    this.state.branchCode = props.relative.branchCode;
  }

  componentDidMount() {
    console.log('here');
  }

  setName = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        name: value,
      });
    } else {
      this.setState({
        name: '',
      });
    }
  };
  setProposalNo = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        proposalNo: value,
      });
    } else {
      this.setState({
        proposalNo: '',
      });
    }
  };
  setBranchCode = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        branchCode: value,
      });
    } else {
      this.setState({
        branchCode: '',
      });
    }
  };

  addCase = () => {
    const { name, branchCode, proposalNo } = this.state;
    const userId = this.props.userId;
    this.setState({
      loading: true,
      showAlert: false,
    });
    const memberInfo = { ...this.props.relative, name, branchCode, proposalNo };
    const body = { memberInfo, userId };
    updateMemberInfo(body)
      .then((res) => {
        if (res.message === 'warning') {
          this.setState({
            alertText:
              res.errorMessage ||
              'Something went wrong. Please contact administrator',
            alertType: 'warning',
            showAlert: true,
          });
          return;
        }
        this.setState({
          alertText: 'updated successfully successfully',
          alertType: 'success',
          showAlert: true,
        });
        this.getUserMembers();
        return;
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertText:
            err.response.errorMessage ||
            'Something went wrong. Please try again later',
          alertType: 'danger',
          showAlert: true,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
        // this.fetchConsults();
      });
  };

  getUserMembers = () => {
    //   getUserMembers(this.props.userId)
    //   .then(res=>{
    this.props.dispatch(resetActiveConsult());
    this.props.hideModal();
    //   })
  };

  render() {
    const {
      alertText,
      alertType,
      name,
      showAlert,
      loading,
      branchCode,
      proposalNo,
    } = this.state;
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Update Info</HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div
                      className={`alert collapse ${showAlert ? 'show' : ''} ${
                        alertType === 'success'
                          ? 'alert-success'
                          : alertType === 'warning'
                          ? 'alert-warning'
                          : 'alert-danger'
                      }`}
                      role="alert"
                    >
                      {alertText}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group row no-gutters">
                      <label
                        htmlFor="user-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Name</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="user-name-input"
                          value={name}
                          onChange={this.setName}
                        />
                      </div>
                      <label
                        htmlFor="user-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Proposal Number</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="user-name-input"
                          value={proposalNo}
                          onChange={this.setProposalNo}
                        />
                      </div>
                      <label
                        htmlFor="user-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Branch Code</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="user-name-input"
                          value={branchCode}
                          onChange={this.setBranchCode}
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <PrimaryButton loading={loading} onTap={this.addCase}>
                        <BoldText fontSize="16px" color="white">
                          Update
                        </BoldText>
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
});

export default connect(mapStateToProps)(UpdateInfoModalLic);
