import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaCalendarAlt, FaTimes } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { PrimaryButton } from './common/Buttons';
import Modal from '../containers/common/Modal';
import StyledTappable from './common/StyledTappable';
import { BoldText, Text } from '../components/common/Text';
import LoadingComponent from '../components/common/LoadingComponent';



const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width:60%;
  overflow: hidden;
  border-left:1px solid #ececec;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: white;
  padding: 0.6rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;
const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;


const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
  text-align: center;
  align-self: center;
`;

const LabelText = styled(Text)`
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: center;
  align-self: center;
  margin-bottom: 2rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const CalendarIcon = styled(FaCalendarAlt)`
  font-size: 1.5rem;
  color: #fff;
  margin-right: 1rem;
`;


const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;



class FormContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: null,
            loading: false,
            scheduleDate: new Date(),
            confirmModal: false
        };
    }

    componentDidMount = () => {
    };

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        const { activeConsult } = this.props
        return (
            <Modal>
                <ModalContainer>
                    <ModalBody>
                        <ModalHeader>
                            <HeaderText>Video Call</HeaderText>
                            <ButtonsComponent>
                                <CloseContainer onTap={this.props.hideModal}>
                                    <CrossIcon />
                                </CloseContainer>
                            </ButtonsComponent>
                        </ModalHeader>

                        <Body>
                            <LabelText>{`Video call has been successfully scheduled`}</LabelText>
                        </Body>
                    </ModalBody>
                </ModalContainer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    authToken: state.user.authToken,
    activeConsult: state.consults.activeConsult,
    user: state.user,
});

export default connect(mapStateToProps)(FormContainer);
