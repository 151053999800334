import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import DatePicker from 'react-datepicker';
import { addInDailyReport } from '../../services';
import moment from 'moment';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
  text-align: center;
  align-self: center;
`;

const LabelText = styled(Text)`
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: center;
  align-self: center;
  margin-bottom: 2rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

class SendInDailyReportModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionChosen: false,
      scheduleDate: new Date(),
    };
  }

  closeConsultation = () => {};
  onScheduleDateChanged = (date) => {
    this.setState({
      scheduleDate: date,
    });
  };

  setSchedule = () => {
    const body = {
      userId: this.props.userId,
      updatedDate: moment(this.state.scheduleDate).format('YYYY-MM-DD'),
    };
    this.setState({
      loading: true,
    });
    addInDailyReport(body)
      .catch(() => {})
      .finally(() => {
        this.setState({
          loading: false,
        });
        this.props.hideModal();
      });
  };

  render() {
    const { scheduleDate } = this.state;
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add in IPRU daily report</HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <DatePicker
                selected={scheduleDate}
                onChange={this.onScheduleDateChanged}
                showTimeSelect={false}
                dateFormat="yyyy-MM-dd"
              />
              <Button onTap={this.setSchedule} loading={this.state.loading}>
                <ButtonText>Confirm</ButtonText>
              </Button>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

export default SendInDailyReportModal;
