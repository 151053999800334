import app from './app';
import chat from './chat';
import user from './user';
import consults from './consults';
import info from './info';
import prescription from './prescription';
import videoCall from './video-call';

export default {
  app,
  chat,
  consults,
  user,
  info,
  prescription,
  videoCall
};
