import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';

import {
  fetchConsults,
  showFilterPane,
  toggleFilter,
  setSelectedSponsorId,
  setFilterDoctorId,
} from '../actions/index';
import StyledTappable from './common/StyledTappable';
import { FadeInTransition } from './transitions/transitions';

import selectedIcon from '../images/common/purpleTick.svg';
import unSelectedIcon from '../images/common/greyTick.svg';
import { BoldText, RegularText } from './common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  overflow-y: scroll;
  border-right: 1px solid #dfdfdf !important;

  &:focus {
    outline: none;
    border: none;
  }
`;

const HeaderBoldText = styled(BoldText)`
  margin: 2rem 0.625rem;
  font-size: 1.6rem;
`;

const FilterText = styled(RegularText)`
  margin: 2rem 0.625rem;
  font-size: 1.6rem;
`;

const SelectedFilterText = styled(RegularText)`
  margin: 2rem 0.625rem;
  font-size: 1.6rem;
  color: #8852cc;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: stretch;
`;

const Filters = styled(StyledTappable)`
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 1.25rem;
  /* align-items: flex-start; */
`;

const Icon = styled.img`
  width: 2rem;
  margin-right: 0.625rem;
`;

class FilterPane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // filters: [
      //   { name: 'All', isSelected: false },
      //   { name: 'Unattented', isSelected: false },
      //   { name: 'Ongoing', isSelected: false },
      //   { name: 'Followups', isSelected: false },
      //   { name: 'Completed', isSelected: false },
      // ],
      // selectedSponsorId: null,
      selectedDoctor: null,
    };
    this.containerRef = React.createRef();
  }

  componentDidMount = () => {
    this.props.sponsors.map((sponsor) => {
      sponsor.label = sponsor.sponsorName;
      sponsor.value = sponsor.sponsorId;
      return sponsor;
    });
    this.containerRef.current && this.containerRef.current.focus();
  };

  setActiveFilter = (ele, index) => {
    const { dispatch, filters } = this.props;
    console.log('Toggling filter');
    dispatch(toggleFilter(index));
    this.submitFilters(filters[index].name);
    dispatch(showFilterPane());
    // const filters = [...this.state.filters];
    // filters[index].isSelected = !filters[index].isSelected;
    // this.setState({
    //   filters,
    // })
  };

  onOptionSelect = (option) => {
    console.log({ option });
    const { dispatch } = this.props;
    dispatch(setSelectedSponsorId(option.sponsorId));
    // this.setState({
    //   selectedSponsorId: option.sponsorId,
    // });
  };

  submitFilters = (activeFilterName) => {
    const { dispatch, authToken, selectedSponsorId } = this.props;
    console.log(activeFilterName);
    // dispatch(showFilterPane());
    dispatch(
      fetchConsults({
        authToken,
        filters: [{ name: activeFilterName, isSelected: true }],
        selectedSponsorId,
      }),
    );
  };

  hideFilters = () => {
    // const { dispatch } = this.props;
    // console.log(ev.target, ev.relatedTarget, this.containerRef.current);
    // if (
    //   !ev.target.contains(ev.relatedTarget) &&
    //   ev.relatedTarget !== this.containerRef.current
    // ) {
    //   dispatch(showFilterPane());
    // }
  };

  onDoctorSelected = (ele, action) => {
    const { dispatch, activeFilterName } = this.props;
    if (action.action === 'set-value' || action.action === 'select-option') {
      this.setState({
        selectedDoctor: ele,
      });
      dispatch(setFilterDoctorId(ele.value));
      if (activeFilterName) {
        this.submitFilters(activeFilterName);
      }
      // dispatch(
      //   fetchConsults({
      //     authToken,
      //     filters: [{ name: 'Doctor Id', isSelected: true, value: ele.value }],
      //     selectedSponsorId,
      //   }),
      // );
      // dispatch(showFilterPane());
    }
  };

  render() {
    const {
      activeFilterName,
      admin,
      doctorType,
      doctorsList,
      filterDoctorId,
      qc,
      insurer,
    } = this.props;
    const selectedDoctorId = filterDoctorId;
    let selectedDoctor = doctorsList.find(
      (ele) => ele.doctorId === selectedDoctorId,
    );
    if (selectedDoctorId === -1) {
      selectedDoctor = {
        doctorId: -1,
        doctorName: 'All',
      };
    }
    if (selectedDoctorId === 'unassigned') {
      selectedDoctor = {
        doctorId: 'unassigned',
        doctorName: 'Unassigned',
      };
    }
    const updatedDoctorsList = [
      {
        doctorId: -1,
        doctorName: 'All',
      },
      {
        doctorId: 'unassigned',
        doctorName: 'Unassigned',
      },
    ].concat(doctorsList);
    const renderFilter = (ele, index) => {
      let element = null;
      if (ele.isHidden) {
        return false;
      }
      if (ele.admin && !admin) {
        return false;
      }
      if (ele.qc && !qc) {
        return false;
      }
      if (
        ele.specificInsurer &&
        ele.insurers &&
        !ele.insurers.includes(insurer)
      ) {
        return false;
      }
      if (ele.name === activeFilterName) {
        element = (
          <Filters
            key={ele.name}
            onClick={() => this.setActiveFilter(ele, index)}
          >
            <Icon src={selectedIcon} />
            <SelectedFilterText>{ele.name}</SelectedFilterText>
          </Filters>
        );
      } else {
        element = (
          <Filters
            key={ele.name}
            onClick={() => this.setActiveFilter(ele, index)}
          >
            <Icon src={unSelectedIcon} />
            <FilterText>{ele.name}</FilterText>
          </Filters>
        );
      }

      return element;
    };

    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <OuterContainer
          ref={this.containerRef}
          tabIndex="1"
          onBlur={this.hideFilters}
        >
          <HeaderBoldText>Filter users</HeaderBoldText>
          <FiltersContainer>
            <Filters>
              {admin && doctorType === 'mbbs' && (
                <Select
                  options={updatedDoctorsList.map((ele) => ({
                    label: ele.doctorName,
                    value: ele.doctorId,
                  }))}
                  className="w-100"
                  classNamePrefix="react-select"
                  defaultValue={{
                    value: -1,
                    label: 'All',
                  }}
                  isSearchable={true}
                  onMenuClose={() => {
                    if (this.containerRef.current) {
                      this.containerRef.current.focus();
                    }
                  }}
                  value={
                    selectedDoctor
                      ? {
                          label: selectedDoctor.doctorName,
                          value: selectedDoctor.doctorId,
                        }
                      : null
                  }
                  onChange={this.onDoctorSelected}
                />
              )}
            </Filters>
            {this.props.filters.map(renderFilter)}
          </FiltersContainer>

          {/* <ButtonComponent>
            <Button
              onTap={() => this.submitFilters()}
              loading={this.state.loading}
            >
              <ButtonText>Apply</ButtonText>
            </Button>
          </ButtonComponent> */}
        </OuterContainer>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  sponsors: state.info.sponsors,
  filters: state.info.filters,
  activeFilterName: state.info.activeFilterName,
  selectedSponsorId: state.info.selectedSponsorId,
  filterDoctorId: state.info.filterDoctorId,
  doctorsList: state.info.doctorsList,
  admin: state.user.admin,
  qc: state.user.qc,
  doctorType: state.user.doctorType,
  insurer: state.user.insurer,
});

export default connect(mapStateToProps)(FilterPane);
