export const defaultState = {
  prescriptionId: null,
  medicines: [],
  labTests: [],
  probableDiagnosis: '',
  generalHealthAdvice: '',
  treatmentPlan: {},
};

export default function prescription(state = defaultState, action) {
  switch (action.type) {
    case 'RESET_PRESCRIPTION': {
      return Object.assign({}, state, defaultState);
    }
    case 'SET_PRESCRIPTION_ID':
      return Object.assign({}, state, {
        prescriptionId: action.payload.prescriptionId,
      });
    case 'ADD_MEDICINES':
      return Object.assign({}, state, {
        medicines: [ ...state.medicines, ...action.payload.medicines ],
      });
    case 'REMOVE_MEDICINES': {
      return Object.assign({}, state, {
        medicines: state.medicines.filter((medicine, index) => medicine.medicineId !== action.payload.medicine.medicineId),
      });
    }
    case 'ADD_LAB_TESTS':
      return Object.assign({}, state, {
        labTests: [...state.labTests, ...action.payload.labTests],
      });
    case 'REMOVE_LAB_TEST': {
      return Object.assign({}, state, {
        labTests: state.labTests.filter((lab, index) => lab.labId !== action.payload.lab.labId),
      });
    }
    case 'SET_PROBABLE_DIAGNOSIS':
      return Object.assign({}, state, {
        probableDiagnosis: action.payload,
      });
    case 'SET_GENERAL_HEALTH_ADVICE':
      return Object.assign({}, state, {
        generalHealthAdvice: action.payload,
      });
    case 'SET_TREATMENT_PLAN':
      return Object.assign({}, state, {
        treatmentPlan: action.payload.treatmentPlan,
      });
    case 'LOGOUT_USER': {
      return Object.assign({}, state, defaultState);
    }
    default:
      return state;
  }
}
