export * from './debounce';
export * from './errorHandler';
export * from './successHandler';

const values = '1234567890';

export function generatePasswordWithPrefix(prefix) {
  let suffix = '';
  for (let i = 0; i < 4; i++) {
    suffix += values[Math.floor(Math.random() * 10)];
  }
  return prefix + suffix;
}
