import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RegularText } from '../../common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  margin: 0.25rem 0;
`;

const BubbleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 0.5rem 0.75rem;
  background: rgba(136, 82, 204, 0.1);
  border-radius: 2rem;
`;

const MessageText = styled(RegularText)`
  font-size: 0.75rem;
  color: #4d4d4d;
`;

const ChatInfoMessage = ({ message }) => (
  <OuterContainer>
    <BubbleContainer>
      <MessageText>{message}</MessageText>
    </BubbleContainer>
  </OuterContainer>
);

ChatInfoMessage.propTypes = {
  message: PropTypes.string,
};

export default ChatInfoMessage;
