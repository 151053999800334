import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck, FaEnvelopeSquare } from 'react-icons/fa';

import { RegularText, Text } from '../../common/Text';
import { Checkbox } from 'antd';
import  { CheckboxChangeEvent } from 'antd/es/checkbox';
import { toggleFeedbackAck , getFeedbackRepliesId , retrieveApplicationId} from '../../../services';

import { Collapse } from 'antd';


const { Panel } = Collapse;

const OuterContainerEle = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  margin: 0.4rem 0;
`;


const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 100%;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: flex-end;
  margin: 0.4rem 0;
`;

const BubbleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 1rem 1.2rem;
  background: #8852cc;
  border-radius: 1.4rem;
`;


const MessageText = styled(RegularText)`
  font-size: 1.6rem;
  color: #fff;
`;

const ReplyMessageText = styled(RegularText)`
  font-size: 1.6rem;
  color: #fff;
  padding: 0.4rem;

`;

const DoctorText = styled(Text)`
font-size:1.2rem;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1.6rem;
`;

const TickIcon = styled(FaCheck)`
  color: #fff;
  opacity: 0.5;
  font-size: 1rem;
  margin-right: 0.4rem;
`;

const ReplyEnvelopeIcon = styled(FaEnvelopeSquare)`
  opacity: 1;
  font-size: 1.6rem;
`;

const TimestampText = styled(RegularText)`
  color: #fff;
  opacity: 0.5;
  font-size: 1.2rem;
`;



const handleChangeAck = (e) => {
   const ack = e.target.ack;
    const id = e.target.id;

    toggleFeedbackAck(id, ack )
    .then((res)=> {
        if(res.message === "success"){
            //alert(`Acknowledgement updated`);
        
        }
        else{
            alert("Something went wrong");
        }
    })
    .catch((err)=> {
        console.log(err)
        alert("FATAL ERROR",err);
    });
}




const FeedbackBubble = ({
  id,
  message,
  timestamp,
  doctorName,
  ack,
  given,
  givenToDoctorId,
  doctorId,
  insurer,
  appId
}) => {
  const [feedReply, setFeedReply] = useState([]);



  

  function getReplies(id) {
    getFeedbackRepliesId(id)
      .then((res) => {
        if (res.message === 'success' && res.data != undefined) {
          setFeedReply(res.data);
        } else {
          setFeedReply([{ replies: 'Feedback hasnot been set for this' }]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <OuterContainer>
        <BubbleContainer>
          <MessageText>
            {' '}
            {appId ? `Application No: ${appId}, Feedback: ` : ''} {message}
          </MessageText>
          {timestamp && (
            <TimestampContainer>
              <TickIcon />
              <TimestampText>{timestamp.substring(0, 10)}</TimestampText>
            </TimestampContainer>
          )}
        </BubbleContainer>

        <div
          style={{ display: 'flex', justifyContent: 'center', gap: '1.3rem' }}
        >
          {givenToDoctorId !== doctorId ? (
            <DoctorText> {ack ? 'Acknowledged' : ''}</DoctorText>
          ) : (
            <>
              <input
                type="checkbox"
                defaultChecked={ack ? true : false}
                onChange={(e) => {
                  handleChangeAck({
                    target: {
                      ack: ack,
                      id: id,
                    },
                  });
                }}
              />
              <DoctorText>
                {' '}
                {ack ? 'Already Acknowledged' : 'I acknowledge'}
              </DoctorText>
            </>
          )}

          <DoctorText color="rgba(0,0,0,0.8)">to Doctor: {given}</DoctorText>

          <DoctorText color="rgba(0,0,0,0.8)">
            Feedback By: {doctorName}
          </DoctorText>
        </div>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          onChange={() => getReplies(id)}
        >
          <Panel header="View Replies" key="1">
            {feedReply.map((ele) => (
              <BubbleContainer>
                <ReplyMessageText>{ele.replies}</ReplyMessageText>
                <TimestampContainer>
                  <TickIcon />
                  <TimestampText>
                    {`replied by : ${ele.replyBy}  at : ${ele.replyTime}`}
                  </TimestampText>
                </TimestampContainer>
              </BubbleContainer>
            ))}
          </Panel>
        </Collapse>
        <hr />
      </OuterContainer>

      <hr />
    </>
  );
};




FeedbackBubble.propTypes = {
  message: PropTypes.string,
  timestamp: PropTypes.number,
  doctorName: PropTypes.string,
};

export default FeedbackBubble;
