import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../../containers/common/Modal';
import { BoldText } from '../common/Text';
import { PrimaryButton } from '../common/Buttons';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const LabelText = styled(BoldText)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  text-align: center;
  color: #000;
  display: flex;
  flex-direction: row;
`;

class AddLabTestsModal extends Component {
  render() {
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Success</HeaderText>
              <ButtonsComponent>
                <ButtonComponent>
                  <Button
                    onTap={this.props.hideModal}
                    // loading={this.state.loading}
                  >
                    <ButtonText>Done</ButtonText>
                  </Button>
                </ButtonComponent>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <LabelText>Prescription Sent Successfully!</LabelText>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

export default AddLabTestsModal;
