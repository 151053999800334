import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../containers/common/Modal';
import StyledTappable from './common/StyledTappable';
import { connect } from 'react-redux';
import { Text, BoldText } from './common/Text';
import { PrimaryButton } from './common/Buttons';
import { FaCheckCircle, FaPlusCircle, FaTimes } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { adminConsole, createNewCase, createNewNivaCase } from '../services';
import { fetchConsults } from '../actions';
import moment from 'moment';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

class AddNewCaseModalNiva extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showAlert: false,
      alertText: '',
      alertType: 'success',
      name: '',
      phone: '',
      applicationNo: '',
      applicationId: null,
      dob: '',
      gender: '',
      newMemberDob: '',
      newMemberGender: '',
      newMemberName: '',
      members: [],
    };
  }

  componentDidMount() {
    adminConsole()
      .then((data) => {
        this.setState({ doctorsList: data.activeConsults });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchConsults = () => {
    const { dispatch, authToken } = this.props;
    dispatch(
      fetchConsults({
        authToken,
        filters: [],
      }),
    );
  };
  setName = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        name: value,
      });
    } else {
      this.setState({
        name: '',
      });
    }
  };

  setPhone = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        phone: value,
      });
    } else {
      this.setState({
        phone: '',
      });
    }
  };

  setPhoneNumber = (e) => {
    console.log(e.target.value);
    const re = /^[0-9\b]+$/;
    if (!e.target.value) {
      this.setState({ phone: '' });
    }
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ phone: e.target.value });
    }
  };

  setapplicationNo = (e) => {
    const value = e.target.value;
    if (value) {
      this.setState({
        applicationNo: value,
      });
    } else {
      this.setState({
        applicationNo: '',
      });
    }
  };

  setapplicationId = (e) => {
    const value = e.target.value;
    if (value) {
      this.setState({
        applicationId: value,
      });
    } else {
      this.setState({
        applicationId: '',
      });
    }
  };

  setEmail = (e) => {
    const value = e.target.value;
    if (value) {
      this.setState({
        email: value,
      });
    } else {
      this.setState({
        email: '',
      });
    }
  };

  addCase = () => {
    const { name, phone, applicationNo, dob, gender, applicationId, members } =
      this.state;
    this.setState({
      loading: true,
      showAlert: false,
    });
    const body = {
      applicationId: applicationId,
      applicationNo: applicationNo,
      name: name,
      dateOfBirth: dob ? moment(dob).format('DD-MMM-YYYY') : dob,
      gender: gender,
      phonenumber: phone,
      altphonenumber: '',
      source: 'ONLINE',
      businessType: 'New Business',
      process: 'MANUAL',
      merType: 'TELEMER',
      insuredDetails: members.map((ele) => {
        return {
          ...ele,
          dateOfBirth: moment(ele.dateOfBirth).format('DD-MMM-YYYY'),
        };
      }),
      Doctor_Declaration: ' ',
      Priority: 'No',
      channel: 'Agency',
      agentmobileno: '',
      telePreferreddate: '',
      telePreferredslot: '',
      telePreferredlanguage: 'hindi',
      agentname: '',
      readyForTeleMER: 'Yes',
      proposerTitle: '',
    };
    createNewNivaCase(body)
      .then((res) => {
        if (!res.success) {
          this.setState({
            alertText:
              res.errorMessage ||
              'Something went wrong. Please contact administrator',
            alertType: 'warning',
            showAlert: true,
          });
          return;
        }
        this.setState({
          alertText: 'new case created successfully',
          alertType: 'success',
          showAlert: true,
          name: null,
          phone: null,
          applicationNo: null,
          dob: null,
          gender: null,
          applicationId: null,
          members: null,
          newMemberDob: null,
          newMemberName: null,
          newMemberGender: null,
        });
        return;
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertText:
            JSON.stringify(err.response ? err.response.errorMessage : '') ||
            'Something went wrong. Please try again later',
          alertType: 'danger',
          showAlert: true,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
        this.fetchConsults();
      });
  };

  render() {
    const {
      alertText,
      alertType,
      name,
      showAlert,
      loading,
      phone,
      applicationNo,
      applicationId,
      dob,
      gender,
      newMemberDob,
      newMemberGender,
      newMemberName,
    } = this.state;
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add a new case</HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div
                      className={`alert collapse ${showAlert ? 'show' : ''} ${
                        alertType === 'success'
                          ? 'alert-success'
                          : alertType === 'warning'
                          ? 'alert-warning'
                          : 'alert-danger'
                      }`}
                      role="alert"
                    >
                      {alertText}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group row no-gutters">
                      <label
                        htmlFor="doctor-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Application No</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="doctor-name-input"
                          value={applicationNo}
                          onChange={this.setapplicationNo}
                        />
                      </div>
                      <label
                        htmlFor="doctor-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Application Id</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="doctor-name-input"
                          value={applicationId}
                          onChange={this.setapplicationId}
                        />
                      </div>
                      <label
                        htmlFor="user-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Name</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="user-name-input"
                          value={name}
                          onChange={this.setName}
                        />
                      </div>
                      <label
                        htmlFor="doctor-phone-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Phone</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="doctor-phone-input"
                          value={phone}
                          onChange={this.setPhoneNumber}
                        />
                      </div>
                      <label
                        htmlFor="doctor-phone-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Gender</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <select
                          className="form-control"
                          value={this.state.gender}
                          onChange={(e) => {
                            this.setState({
                              gender: e.target.value,
                            });
                          }}
                        >
                          <option value={''}>Choose Gender</option>

                          <option value={'Male'}>Male</option>
                          <option value={'Female'}>Female</option>
                        </select>
                      </div>
                      <label
                        htmlFor="doctor-phone-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Date of birth</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="date"
                          className="form-control"
                          id="doctor-phone-input"
                          dateFormat={'yyyy-mm-dd'}
                          value={dob}
                          onChange={(e) => {
                            this.setState({
                              dob: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <table class="table table-striped mt-4">
                        <thead>
                          <tr>
                            <th scope="col">Member Name</th>
                            <th scope="col">Member DOB</th>
                            <th scope="col">Member Gender</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.members.map((ele, idx) => {
                            return (
                              <tr>
                                <td>
                                  <Text fontSize="14px">{ele.name}</Text>
                                </td>
                                <td>
                                  <Text fontSize="14px">{ele.dateOfBirth}</Text>
                                </td>
                                <td>
                                  <Text fontSize="14px">{ele.gender}</Text>
                                </td>
                                <td></td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                value={newMemberName}
                                onChange={(e) => {
                                  this.setState({
                                    newMemberName: e.target.value,
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="date"
                                className="form-control"
                                dateFormat={'yyyy-mm-dd'}
                                value={newMemberDob}
                                onChange={(e) => {
                                  this.setState({
                                    newMemberDob: e.target.value,
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <select
                                className="form-control"
                                value={this.state.newMemberGender}
                                onChange={(e) => {
                                  this.setState({
                                    newMemberGender: e.target.value,
                                  });
                                }}
                              >
                                <option value={''}>Choose Gender</option>

                                <option value={'Male'}>Male</option>
                                <option value={'Female'}>Female</option>
                              </select>
                            </td>
                            <td>
                              {newMemberName &&
                              newMemberDob &&
                              newMemberGender ? (
                                <FaCheckCircle
                                  style={{ fontSize: '16px' }}
                                  onClick={() => {
                                    this.setState({
                                      members: [
                                        ...this.state.members,
                                        {
                                          name: newMemberName,
                                          dateOfBirth: newMemberDob,
                                          gender: newMemberGender,
                                          memberId:
                                            (applicationId || 1) +
                                            '-' +
                                            this.state.members.length,
                                          Doctor_Declaration: '',
                                          medicalPractitionerID: '',
                                          insuredTitle: '',
                                          insuredHeight: '',
                                          insuredWeight: '',
                                        },
                                      ],
                                      newMemberDob: null,
                                      newMemberName: null,
                                      newMemberGender: null,
                                    });
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <PrimaryButton
                      loading={loading}
                      onTap={this.addCase}
                      disabled={
                        !phone || !applicationNo || !name || !dob || !gender
                      }
                    >
                      <BoldText fontSize="16px" color="white">
                        Add New Case
                      </BoldText>
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(AddNewCaseModalNiva);
