import React, { Component } from 'react';
import styled from 'styled-components';
import {
  OTSession,
  OTPublisher,
  OTStreams,
  OTSubscriber,
  preloadScript,
} from 'opentok-react';
import { FaTimes } from 'react-icons/fa';
import config from '../config';
import { stopVideoCall } from '../services';
import StyledTappable from '../components/common/StyledTappable';

const Body = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /*centers items on the line (the x-axis by default)*/
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
  margin-bottom: 20rem;
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #dadada;
`;

const CloseIcon = styled(FaTimes)`
  align-self: center;
  width: 3rem;
  height: 3rem;
`;

class VideoCallContainer extends Component {
  constructor(props) {
    super(props);

    const { state } = props.location;
    const { sessionId, token } = state;

    this.state = {
      sessionId,
      token,
    };
  }

  stopVideoCall = () => {
    console.log('stopVideoCall');
    stopVideoCall(
      this.props.location.state.authToken,
      this.props.location.state.doctorId,
      this.props.location.state.sessionId,
    )
      .then((result) => {
        console.log({ result }, 'result from stopVideoCall');
        this.props.history.goBack();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  render() {
    console.log(config.opentokApiKey, this.state);
    return (
      <>
        <OTSession
          apiKey={config.opentokApiKey.toString()}
          sessionId={this.state.sessionId}
          token={this.state.token}
        >
          <OTPublisher />
          <OTStreams>
            <OTSubscriber />
          </OTStreams>
        </OTSession>
        <Body>
          <CloseContainer onTap={() => this.stopVideoCall()}>
            <CloseIcon />
          </CloseContainer>
        </Body>
      </>
    );
  }
}

export default preloadScript(VideoCallContainer);
