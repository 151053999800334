import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ConsultationList from '../components/ConsultationList';
import FilterPane from '../components/FilterPane';
import DashboardHeader from '../components/DashboardHeader';
import ConsultationContainer from './ConsultationContainer';
import ProfileContainer from './ProfileContainer';
import { BoldText } from '../components/common/Text';
import VideoCallContainer from '../components/VideoCallContainer';
import DyteVideoContainer from '../components/DyteVideoContainer';
import { initializeChatClient } from '../services/chatClient';
import { initializeAppClient } from '../services/appClient';
import {
  endVideoCall,
  initiateVideoCall,
  setUnrepliedChannel,
} from '../actions';
import ChangeInsurer from '../components/ChangeInsurer';
import DoctorInfoUpdate from '../components/DoctorInfoUpdate';
import DyteBridge from './DyteBridge';
const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
`;

const OfflineBar = styled.div`
  background: #ec5d57;
  padding: 6px 0;
  color: white;
  text-align: center;
`;

const ReconnectedBar = styled(OfflineBar)`
  background: #56c02c;
`;

const OfflineText = styled(BoldText)`
  font-size: 1.8rem;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1 0;
  align-items: stretch;
  overflow-y: hidden;
`;

class DashboardHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showReconnectedBar: false,
      showInfoUpdateModal: false,
    };
  }
  dispatch(action) {
    this.props.dispatch(action);
  }
  componentDidMount() {
    this.dispatch(endVideoCall());
    if (!this.props.user.authToken) {
      this.props.history.push('/');
      return;
    } else {
      this.chatClient = initializeChatClient();
      this.appClient = initializeAppClient();
      // this.channel = this.props.unrepliedChannel ? this.props.unrepliedChannel : `visit-inbound-doctor-${this.props.user.doctorId}`;
      this.channel = this.props.unrepliedChannel;

      if (!this.props.unrepliedChannel) {
        this.dispatch(setUnrepliedChannel(this.channel));
      }
      console.log({ props: this.props });
      this.chatClient.subscribe(this.channel);
      if (
        [355, 94, 1450, 199, 1451].includes(this.props.user.doctorId) &&
        this.props.user.insurer === 'ipru'
      ) {
        this.appClient.subscribe('app');
      }

      if (
        !this.props.user.panNo ||
        !this.props.user.email ||
        !this.props.user.language
      ) {
        this.showUpdateInfoModal();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { appState } = this.props;
    const prevAppState = prevProps.appState;
    if (prevAppState === 'OFFLINE' && appState === 'ONLINE') {
      this.setState(
        {
          showReconnectedBar: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              showReconnectedBar: false,
            });
          }, 3000);
        },
      );
    }
  }

  componentWillUnmount() {
    if (this.chatClient) {
      this.chatClient.unsubscribe(this.channel);
    }
  }

  initiateVideoCall = () => {
    this.dispatch(initiateVideoCall());
    // this.setState({ videoCallInitiated: true });
  };
  endVideoCall = () => {
    this.dispatch(endVideoCall());
    // this.setState({ videoCallInitiated: false });
  };

  hideModal = () => {
    this.setState({ showInfoUpdateModal: false });
  };

  showUpdateInfoModal = () => {
    this.setState({ showInfoUpdateModal: true });
  };

  render() {
    const { appState, user } = this.props;
    const { showReconnectedBar, showInfoUpdateModal } = this.state;
    const loadFilterPane = () => {
      let filterPane = null;
      if (this.props.showFilterPane) {
        filterPane = <FilterPane />;
      }
      return filterPane;
    };

    return (
      <OuterContainer>
        {appState === 'OFFLINE' && (
          <OfflineBar>
            <OfflineText>You are offline</OfflineText>
          </OfflineBar>
        )}
        {showReconnectedBar && (
          <ReconnectedBar>
            <OfflineText>Reconnecting...</OfflineText>
          </ReconnectedBar>
        )}
        <DashboardHeader
          history={this.props.history}
          initiateVideoCall={this.initiateVideoCall}
          showUpdateInfoModal={this.showUpdateInfoModal}
        />
        {user.changeInsurer && !this.props.videoCallInitiated ? (
          <ChangeInsurer />
        ) : (
          ''
        )}
        <BodyContainer>
          {loadFilterPane()}
          {!this.props.videoCallInitiated && <ConsultationList />}
          <ConsultationContainer
            videoCallInitiated={this.props.videoCallInitiated}
          />
          {!this.props.videoCallInitiated && <ProfileContainer />}
          {this.props.videoCallInitiated && (
            <VideoCallContainer endVideoCall={this.endVideoCall} />
          )}
          {/* {!videoCallInitiated && <ConsultationList />}
          <ConsultationContainer videoCallInitiated={videoCallInitiated} />
          {!videoCallInitiated && <ProfileContainer />}
          {videoCallInitiated && (
            <DyteVideoContainer />
          )} */}
        </BodyContainer>
        {showInfoUpdateModal && <DoctorInfoUpdate hideModal={this.hideModal} />}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  activeConsult: state.consults.activeConsult,
  unrepliedChannel: state.consults.unrepliedChannel,
  showFilterPane: state.app.filterPane,
  user: state.user,
  pubnubChannelGroup: state.info.pubnubChannelGroup,
  appState: state.app.appState,
  videoCallInitiated: state.videoCall.videoCallInitiated,
});

export default connect(mapStateToProps)(DashboardHome);
