import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Async, components } from 'react-select';
import { debounce } from '../../utils/debounce';
import { BoldText, Text } from '../common/Text';
import { fetchDiseaseSuggestions, removeDisease } from '../../services';
import DiseaseQuestionModal from '../DiseaseQuestionModal';
import StyledTappable from '../common/StyledTappable';

class RelativeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.userResponse ? props.userResponse : '',
      selectedRelative: -1,
      selectedRelativeName: '',
      selectedDisease: -1,
      selectedDiseaseName: '',
      showModal: false,
      questionModalVisible: false,
    };
    this.debounceFetchDiseaseSuggestions = debounce(
      this.fetchDiseaseSuggestions,
      500,
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.userResponse ? nextProps.userResponse : '',
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      this.setState({ showModal: false });
    }
  }

  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      value: event.target.value,
    });
  };

  fetchDiseaseSuggestions = (inputValue, callback) => {
    console.log(`Searching ${inputValue}`);
    const { authToken } = this.props;
    fetchDiseaseSuggestions(inputValue, authToken)
      .then((res) => {
        const { result } = res;
        callback(
          result.map((ele) => ({ value: ele.diseaseId, label: ele.name })),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onDiseaseSelected = (val, action, relativeId, relativeName) => {
    console.log(val, action);
    const { authToken, options, onChange } = this.props;
    switch (action.action) {
      case 'select-option':
        // An option has been selected. Show modal to ask further questions
        this.setState({
          selectedRelative: relativeId,
          selectedRelativeName: relativeName,
          selectedDisease: action.option.value,
          selectedDiseaseName: action.option.label,
          showModal: true,
        });
        break;
      case 'remove-value':
        // Remove the disease
        const diseaseId = action.removedValue.value;
        removeDisease(diseaseId, relativeId, authToken)
          .then(() => {
            const relativeIndex = options.findIndex(
              (ele) => ele.relativeId === relativeId,
            );
            if (relativeIndex !== -1) {
              const diseaseIndex = options[relativeIndex].diseases.findIndex(
                (ele) => ele.diseaseId === diseaseId,
              );
              if (diseaseIndex !== -1) {
                const updatedRelatives = Array.from(options);
                updatedRelatives[relativeIndex].diseases.splice(
                  diseaseIndex,
                  1,
                );
                onChange && onChange(updatedRelatives);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      default:
        break;
    }
  };

  onCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  onSubmit = (diseaseId, diseaseName, relativeId) => {
    const { options, onChange } = this.props;
    const updatedRelatives = Array.from(options);
    const index = options.findIndex((ele) => ele.relativeId === relativeId);
    console.log(index, options, relativeId);
    if (index !== -1) {
      // Update the relative with the disease
      // Check if the disease already exists
      const diseaseIndex = updatedRelatives[index].diseases.findIndex(
        (ele) => ele.diseaseId === diseaseId,
      );
      if (diseaseIndex === -1) {
        updatedRelatives[index].diseases = updatedRelatives[
          index
        ].diseases.concat([
          {
            diseaseId,
            diseaseName,
          },
        ]);
        onChange && onChange(updatedRelatives);
      }
    }
    this.onCloseModal();
  };

  render() {
    const {
      selectedRelative,
      selectedRelativeName,
      selectedDisease,
      selectedDiseaseName,
      showModal,
    } = this.state;
    const { userId } = this.props;
    const SelectedValueContainer = (props) => {
      return (
        <StyledTappable
          onTap={() => {
            this.onDiseaseSelected(
              null,
              {
                action: 'select-option',
                option: props.data,
              },
              props.selectProps.relativeId,
              props.selectProps.relativeName,
            );
          }}
        >
          <components.MultiValueContainer {...props} />
        </StyledTappable>
      );
    };
    const renderOption = (ele, index) => (
      <div
        key={index}
        className="border border-secondary border-top-0 border-left-0 border-right-0 p-3 mb-2"
      >
        <label
          className="form-check-label flex-grow-1 mb-2"
          htmlFor={`singleSelectOptions${index}`}
        >
          <BoldText fontSize="1.8rem">{ele.relativeName}</BoldText>
          <Text fontSize="1.2rem">
            {`${ele.relativeGender}, ${ele.relativeDob}`}
          </Text>
        </label>
        <Async
          isMulti
          value={ele.diseases.map((ele) => ({
            value: ele.diseaseId,
            label: ele.diseaseName,
          }))}
          components={{ MultiValueContainer: SelectedValueContainer }}
          relativeId={ele.relativeId}
          relativeName={ele.relativeName}
          loadOptions={this.debounceFetchDiseaseSuggestions}
          onChange={(val, action) =>
            this.onDiseaseSelected(
              val,
              action,
              ele.relativeId,
              ele.relativeName,
            )
          }
          backspaceRemovesValue={false}
          loadingMessage={() => 'Fetching diseases'}
          noOptionsMessage={() => 'Type a disease name to search'}
          placeholder="No diseases added. Type a disease name to search"
        />
      </div>
    );

    return (
      <div className="container-fluid h-100 d-flex flex-column">
        <div className="row hra-body">
          <div className="col-12">
            <div className="row d-block">
              <div className="col p-0">
                <h3 className="mt-3 ml-3">
                  Please select a relative to continue
                </h3>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row justify-content-stretch flex-grow-1">
              <div className="col-12 p-0">
                {this.props.options.map(renderOption)}
              </div>
              {this.props.info && (
                <div className="col-12 mt-3 pb-4">
                  <a
                    className="text-primary font-weight-bold btn p-0"
                    data-toggle="collapse"
                    href="#info"
                    role="button"
                    aria-expanded="false"
                    aria-controls="info"
                  >
                    See Information
                  </a>
                  <div className="collapse" id="info">
                    <p>{this.props.info}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {allRelativesComplete ? (
          <button
            type="button"
            onClick={this.props.closeConsultation}
            className="btn btn-primary p-3 w-100 mt-3 text-center"
          >
            <p className="h5 m-0">Close Consultation</p>
          </button>
        ) : (
          <NavigationButtons
            submitResponse={sendResponse}
            submitDisabled={this.state.value === ''}
            goToPrevious={this.props.goToPrevious}
          />
        )} */}
        {showModal && (
          <DiseaseQuestionModal
            userId={userId}
            diseaseId={selectedDisease}
            diseaseName={selectedDiseaseName}
            relativeId={selectedRelative}
            relativeName={selectedRelativeName}
            onClose={this.onCloseModal}
            onSubmit={this.onSubmit}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.consults.activeConsult.user.userId,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(RelativeSelect);
