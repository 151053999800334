import axios from 'axios';
import config from '../config';
import { store } from '../store';
import { showError } from '../utils';

export function loginUser(phone, password, sendOtp = false) {
  const body = {
    phone,
    password,
    sendOtp,
  };
  console.log(body);

  return axios
    .post(`${config.apiBaseUrl}/doctor-auth/login`, body)
    .then((res) => {
      const responseBody = res.data;
      const responseHeaders = res.headers;
      if (responseBody.message === 'OTP sent successfully') {
        return {
          body: responseBody,
          // headers: responseHeaders,
        };
      } else if (responseBody.message === 'loggedIn') {
        return {
          body: responseBody,
          headers: responseHeaders,
        };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function verifyUser(phone, otp) {
  const body = {
    phone,
    otp,
  };
  console.log(body);

  return axios
    .post(`${config.apiBaseUrl}/doctor-auth/verify-otp`, body)
    .then((res) => {
      const responseBody = res.data;
      const responseHeaders = res.headers;
      if (responseBody.message === 'loggedIn') {
        return {
          body: responseBody,
          headers: responseHeaders,
        };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function goActive(doctorId) {
  const state = store.getState();
  const { authToken } = state.user;
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/consultations/change-active`,
      {
        active: true,
        doctorId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function goInactive(doctorId) {
  const state = store.getState();
  const { authToken } = state.user;
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/consultations/change-active`,
      {
        active: false,
        doctorId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function loginInfo() {
  const state = store.getState();
  const { authToken } = state.user;
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/doctor-auth/login-info`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'loggedIn') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function changeSelfInsurer(doctorId, insurer) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const body = {
    doctorId,
    insurer,
  };

  return axios
    .post(`${config.apiBaseUrl}/doctor-auth/change-self-insurer`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      const responseHeaders = res.headers;
      if (responseBody.message === 'success') {
        return {
          body: responseBody,
          headers: responseHeaders,
        };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateDoctorInfo(panNo, email, language) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const body = {
    panNo,
    email,
    language
  };

  return axios
    .post(`${config.apiBaseUrl}/doctor-auth/update-info`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      const responseHeaders = res.headers;
      if (responseBody.message === 'success') {
        return {
          body: responseBody,
          headers: responseHeaders,
        };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
export function setAvailableForCallStatus(isDoctorAvailableForCall) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const body = {
    availableForCall: isDoctorAvailableForCall,
  };

  return axios
    .put(`${config.apiBaseUrl}/doctor-auth/update-call-status`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return {
          body: responseBody,
        };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
