import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import AdminPanel from './AdminPanel';
import { BoldText, Text } from '../components/common/Text';
import StyledTappable from '../components/common/StyledTappable';
import ConsultationChatContainer from './ConsultationChatContainer';
import PrescriptionContainer from './PrescriptionContainer';
import { resetActiveConsult } from '../actions';
import UserNotesContainer from './UserNotesContainer';
import FormContainer from './FormContainer';
import DoctorsFeedback from './DoctorsFeedback';
import ProfileContainer from './ProfileContainer';


const OuterContainer = styled.div`
  display: flex;
  flex: 2 0;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid #d8d8d8;
`;

const TabContainer = styled.div`
  display: flex;
  background: #f5f5f5;
  align-items: stretch;
  justify-content: space-between;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.23);
`;

const TabItemContainer = styled(StyledTappable)`
  padding: 1rem 4rem;
  border-bottom: ${(props) => (props.active ? '3px solid #8852CC' : 'none')};
`;

const TabActiveItemText = styled(BoldText)`
  font-size: 1.6rem;
  color: #8852cc;
`;

const TabItemText = styled(Text)`
  font-size: 1.6rem;
  opacity: 0.4;
`;

const ConsultationContainer = ({
  location,
  history,
  admin,
  dispatch,
  doctorType,
  videoCallInitiated,
  doctorId,
  insurer,
}) => {
  const currentHash = location.hash;
  const currentTab = currentHash.split('-')[0];
  const renderTab = () => {
    switch (currentTab) {
      case '#chat':
        return <ConsultationChatContainer />;
      case '#prescriptions':
        return <PrescriptionContainer />;
      case '#notes':
        return <UserNotesContainer />;
      case '#feedback':
        return <DoctorsFeedback />;
      case '#admin':
        return <AdminPanel />;
      case '#form':
        return <FormContainer />;
      case '#profile':
        return <ProfileContainer only={'profile'} />;
      case '#script':
        return <ProfileContainer only={'script'} />;
      default:
        return admin ? (
          <AdminPanel />
        ) : doctorType === 'mbbs' ? (
          <>
            <FormContainer />
            {insurer}
          </>
        ) : (
          <ConsultationChatContainer />
        );
    }
  };
  return (
    <OuterContainer>
      <TabContainer>
        {admin &&
          !videoCallInitiated &&
          ![651, 281, 650].includes(doctorId) && (
            <TabItemContainer
              active={currentTab === '#admin' || (admin && currentTab === '')}
              onTap={() => {
                dispatch(resetActiveConsult());
                history.replace(`${location.pathname}#admin`);
              }}
            >
              {currentTab === '#admin' || (admin && currentTab === '') ? (
                <TabActiveItemText>Admin</TabActiveItemText>
              ) : (
                <TabItemText>Admin</TabItemText>
              )}
            </TabItemContainer>
          )}
        {videoCallInitiated && (
          <TabItemContainer
            active={currentTab === '#profile'}
            onTap={() => {
              history.replace(`${location.pathname}#profile`);
            }}
          >
            {currentTab === '#profile' ? (
              <TabActiveItemText>profile</TabActiveItemText>
            ) : (
              <TabItemText>profile</TabItemText>
            )}
          </TabItemContainer>
        )}
        {videoCallInitiated && (
          <TabItemContainer
            active={currentTab === '#script'}
            onTap={() => {
              history.replace(`${location.pathname}#script`);
            }}
          >
            {currentTab === '#script' ? (
              <TabActiveItemText>script</TabActiveItemText>
            ) : (
              <TabItemText>script</TabItemText>
            )}
          </TabItemContainer>
        )}
        {!videoCallInitiated && (
          <TabItemContainer
            active={currentTab === '#notes'}
            onTap={() => history.replace(`${location.pathname}#notes`)}
          >
            {currentTab === '#notes' || (!admin && currentTab === '') ? (
              <TabActiveItemText>Notes</TabActiveItemText>
            ) : (
              <TabItemText>Notes</TabItemText>
            )}
          </TabItemContainer>
        )}
        {doctorType !== 'mbbs' ||
          (insurer === 'max' && (
            <TabItemContainer
              active={currentTab === '#chat' || (!admin && currentTab === '')}
              onTap={() => history.replace(`${location.pathname}#chat`)}
            >
              {currentTab === '#chat' || (!admin && currentTab === '') ? (
                <TabActiveItemText>Chat</TabActiveItemText>
              ) : (
                <TabItemText>Chat</TabItemText>
              )}
            </TabItemContainer>
          ))}
        {doctorType === 'mbbs' && (
          <TabItemContainer
            active={currentTab === '#form' || (!admin && currentTab === '')}
            onTap={() => history.replace(`${location.pathname}#form`)}
          >
            {currentTab === '#form' || (!admin && currentTab === '') ? (
              <TabActiveItemText>Form</TabActiveItemText>
            ) : (
              <TabItemText>Form</TabItemText>
            )}
          </TabItemContainer>
        )}
        {doctorType === 'mbbs' && (
          <TabItemContainer
            active={currentTab === '#feedback' || (!admin && currentTab === '')}
            onTap={() => history.replace(`${location.pathname}#feedback`)}
          >
            {currentTab === '#feedback' || (!admin && currentTab === '') ? (
              <TabActiveItemText>Feedback</TabActiveItemText>
            ) : (
              <TabItemText>Feedback</TabItemText>
            )}
          </TabItemContainer>
        )}
      </TabContainer>
      {renderTab()}
    </OuterContainer>
  );
};

const mapStateToProps = (state) => ({
  admin: state.user.admin,
  doctorType: state.user.doctorType,
  doctorId: state.user.doctorId,
  insurer: state.user.insurer,
});

export default withRouter(connect(mapStateToProps)(ConsultationContainer));
