import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';

import CardContainer from './CardContainer';

const StyledTappable = styled(Tappable)`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
  &.visit-tappable-active {
    opacity: 0.2;
  }
`;

const TappableCard = ({ children, className, key, onTap }) => (
  <StyledTappable
    stopPropagation
    onTap={onTap}
    key={key}
    classBase="visit-tappable">
    <CardContainer className={className}>{children}</CardContainer>
  </StyledTappable>
);

TappableCard.propTypes = {
  children: PropTypes.element.isRequired,
  onTap: PropTypes.func,
};

export default TappableCard;
