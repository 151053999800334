import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import TappableCard from '../common/TappableCard';
import { fetchMedicines } from '../../services/';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: center;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const StyledInputBox = styled(Input)`
  flex: 1;
  border-bottom: 1px solid #979797 !important;
  padding: 0.33rem 0;
  margin: 0rem 0.625rem;
  /* width: ${(props) => (props.width ? props.width : 'auto')}; */
`;

const StyledInputBoxSmall = styled(Input)`
  /* flex: 1; */
  border-bottom: 1px solid #979797 !important;
  padding: 0.33rem 0;
  margin: 0rem 0.625rem;
  width: 8rem;
`;

const StyledTappableCard = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin: 0rem 0.625rem;
  border: ${(props) => (props.isSelected ? '0.1rem solid #8852cc' : 'null')};
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: ${(props) => (props.isSelected ? '#8852cc' : 'black')};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1.625rem 0.625rem;
`;

const AgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 1;
`;

const LabelText = styled(Text)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const SelectElement = styled(Select)`
  /* flex: 1; */
  width: 25rem;
  /* border: 0.1rem solid #8852cc; */
`;

const DosageContainer = styled.div`
  display: flex;
  /* align-self: stretch; */
  flex-direction: row;
`;

const dosageOptions = [
  { value: '1-0-0', label: '1-0-0' },
  { value: '0-1-0', label: '0-1-0' },
  { value: '0-0-1', label: '0-0-1' },
  { value: '1-0-1', label: '1-0-1' },
  { value: '1-1-1', label: '1-1-1' },
  { value: '1-1-1-1', label: '1-1-1-1' },
  { value: '1-1-1-1-1', label: '1-1-1-1-1' },
  { value: 'SOS', label: 'SOS' },
];

const durationOptions = [
  { value: 'Days', label: 'Days' },
  { value: 'Weeks', label: 'Weeks' },
  { value: 'Months', label: 'Months' },
];

const customStyles = {
  option: (provided) => ({
    ...provided,
    padding: 10,
    fontSize: 16,
    textAlign: 'left',
  }),
  placeholder: (provided) => ({
    ...provided,
    // padding: 10,
    fontSize: 12,
    textAlign: 'left',
  }),
  valueContainer: (provided) => ({
    ...provided,
    // padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   width: 200,
  // }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // }
};

class AddMedicinesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      medicineName: '',
      lastMedicines: [],
      genericName: '',
      notes: '',
      mealPref: 'after',
      durationValue: '',
      dosage: '',
      duration: durationOptions[0].value,
    };

    this.medicineSelectRef = React.createRef();
    this.dosageSelectRef = React.createRef();
    this.durationSelectRef = React.createRef();
  }

  clearFields = () => {
    this.setState({
      medicineName: '',
      genericName: '',
      notes: '',
      mealPref: 'after',
      durationValue: '',
      dosage: '',
      duration: durationOptions[0].value,
    });
    console.log(this.dosageSelectRef.current, 'reference');
    // this.medicineSelectRef.current.state.defaultOptions = [{ label: 'dfd', value: 'fdfd' }];
    // setting the value of react-select component to null
    this.medicineSelectRef.current.select.state.value = null;
    this.dosageSelectRef.current.state.value = null;
    this.durationSelectRef.current.state.value = durationOptions[0];
  };

  removeMedicine = () => {
    this.setState({
      medicineName: '',
      genericName: '',
    });
    console.log(this.medicineSelectRef.current, 'reference');
    // setting the value of react-select component to null
    this.medicineSelectRef.current.select.state.value = null;
    this.medicineSelectRef.current.state.defaultOptions = null;
  };

  selectMealPref = (mealPref) => {
    this.setState({ mealPref });
  };

  onDosageSelect = (option) => {
    this.setState({
      dosage: option.value,
    });
  };

  onMedicineSelect = (option) => {
    if (option) {
      this.setState({
        medicineName: option.value ? option.value : '',
        genericName: option.generic_name ? option.generic_name : '',
      });
    } else {
      this.setState({
        medicineName: '',
        genericName: '',
      });
      this.medicineSelectRef.current.state.defaultOptions = null;
    }
  };

  onDurationSelect = (option) => {
    this.setState({
      duration: option.value,
    });
  };

  getMedicines = (input) => {
    return fetchMedicines(this.props.authToken, input)
      .then((result) => {
        console.log({ result }, 'getMedicine result');
        result.forEach((medicine) => {
          console.log({ medicine });
          medicine.label = medicine.brand_name;
          medicine.value = medicine.brand_name;
        });
        this.setState({ lastMedicines: result });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  promiseOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getMedicines(input.toLowerCase()));
      }, 500);
    });
  };

  render() {
    // const medicineNameLink = Link.state(this, "medicineName");
    const genericNameLink = Link.state(this, 'genericName');
    const notesLink = Link.state(this, 'notes');
    const durationValue = Link.state(this, 'durationValue');
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add Medicine</HeaderText>
              <ButtonsComponent>
                <ButtonComponent>
                  <Button
                    onTap={() => {
                      this.props.addMedicine(this.state, true);
                      this.clearFields();
                    }}
                    // loading={this.state.loading}
                  >
                    <ButtonText>Save</ButtonText>
                  </Button>
                </ButtonComponent>
                <ButtonComponent>
                  <Button
                    onTap={() => {
                      this.props.addMedicine(this.state);
                      this.clearFields();
                    }}
                    // loading={this.state.loading}
                  >
                    <ButtonText>Save & Add New</ButtonText>
                  </Button>
                </ButtonComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <InputContainer>
                <AgeContainer>
                  <LabelText>Add Medicine</LabelText>
                  <AsyncCreatableSelect
                    // options={dosageOptions}
                    ref={this.medicineSelectRef}
                    styles={customStyles}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#d5d5d5',
                        primary25: '#f5f5f5',
                        primary: '#8852cc',
                      },
                    })}
                    placeholder="Search Medicine"
                    cacheOptions
                    isClearable={true}
                    defaultOptions={this.state.lastMedicines}
                    loadOptions={this.promiseOptions}
                    onChange={(option) => this.onMedicineSelect(option)}
                    formatCreateLabel={(input) => `Add Medicine: '${input}'`}
                  />
                </AgeContainer>
                {/* <CloseContainer onTap={() => this.removeMedicine()}>
                  <RemoveIcon />
                </CloseContainer> */}
              </InputContainer>
              <InputContainer>
                <StyledInputBox
                  type="text"
                  className="visit-input"
                  valueLink={genericNameLink}
                  placeholder="Generic Name"
                />

                <StyledTappableCard
                  onTap={() => this.selectMealPref('before')}
                  isSelected={this.state.mealPref === 'before' ? true : false}
                >
                  <TappableText
                    isSelected={this.state.mealPref === 'before' ? true : false}
                  >
                    Before food
                  </TappableText>
                </StyledTappableCard>
                <StyledTappableCard
                  onTap={() => this.selectMealPref('after')}
                  isSelected={this.state.mealPref === 'after' ? true : false}
                >
                  <TappableText
                    isSelected={this.state.mealPref === 'after' ? true : false}
                  >
                    After food
                  </TappableText>
                </StyledTappableCard>
              </InputContainer>
              <InputContainer>
                <AgeContainer>
                  <LabelText>Dosage</LabelText>
                  <SelectElement
                    options={dosageOptions}
                    ref={this.dosageSelectRef}
                    styles={customStyles}
                    theme={(theme) => ({
                      ...theme,
                      // borderRadius: '0.25rem',
                      colors: {
                        ...theme.colors,
                        primary50: '#d5d5d5',
                        primary25: '#f5f5f5',
                        primary: '#8852cc',
                      },
                    })}
                    placeholder="Select Dosage"
                    onChange={(option) => this.onDosageSelect(option)}
                  />
                </AgeContainer>
                <AgeContainer>
                  <LabelText>Duration</LabelText>
                  <DosageContainer>
                    <StyledInputBoxSmall
                      type="text"
                      className="visit-input"
                      valueLink={durationValue}
                      placeholder="0"
                    />
                    <SelectElement
                      options={durationOptions}
                      ref={this.durationSelectRef}
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        // borderRadius: '0.25rem',
                        colors: {
                          ...theme.colors,
                          primary50: '#d5d5d5',
                          primary25: '#f5f5f5',
                          primary: '#8852cc',
                        },
                      })}
                      defaultValue={durationOptions[0]}
                      placeholder="Days, Weeks, Months..."
                      onChange={(option) => this.onDurationSelect(option)}
                    />
                  </DosageContainer>
                </AgeContainer>
              </InputContainer>
              <InputContainer>
                <StyledInputBox
                  type="text"
                  className="visit-input"
                  valueLink={notesLink}
                  placeholder="Notes (optional)"
                />
              </InputContainer>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(AddMedicinesModal);
