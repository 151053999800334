import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { Text, BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import {
  saveRemarksForCall,
  loginInfo,
  updateLanguage,
  markFollowupUser,
} from '../../services';
import Select, { Creatable } from 'react-select';
import { PrimaryButton } from '../common/Buttons';
import { Button } from 'antd';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const LabelText = styled(Text)`
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: center;
  align-self: center;
  margin-bottom: 2rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const AddCallRemarksContainer = styled.div`
  padding: 8px;
  margin: 8px;
`;
const EndCallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const FollowupModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 35%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ButtonComp = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

class VoiceCallSuccessModal extends Component {
  state = {
    showFollowupModal: false,
    followupDate: new Date(),
    followupRemark: null,
    savingRemarks: false,
    savingRemarksFor: '',
    submissionRemarks: '',
    callRemarks: {},
    callRemarksOptions: [],
    language: '',
    languageUpdated: false,
    languageSelect: false,
    languageOptions: [
      { value: 'Telugu', label: 'Telugu' },
      { value: 'Hindi', label: 'Hindi' },
      { value: 'English', label: 'English' },
      { value: 'Tamil', label: 'Tamil' },
      { value: 'Malayalam', label: 'Malayalam' },
      { value: 'Kannada', label: 'Kannada' },
      { value: 'Bengali', label: 'Bengali' },
      { value: 'Marathi', label: 'Marathi' },
      { value: 'Gujarati', label: 'Gujarati' },
      { value: 'Assamese', label: 'Assamese' },
      { value: 'Kashmiri', label: 'Kashmiri' },
      { value: 'Konkani', label: 'Konkani' },
      { value: 'Manipuri', label: 'Manipuri' },
      { value: 'Nepali', label: 'Nepali' },
      { value: 'Oriya', label: 'Oriya' },
      { value: 'Punjabi', label: 'Punjabi' },
      { value: 'Urdu', label: 'Urdu' },
    ],
    sopCallbackOption: [
      { value: 'Not Answered', label: 'Not Answered' },
      { value: 'Busy', label: 'Busy' },
      { value: 'Not Reachable', label: 'Not Reachable' },
      {
        value: 'User Disconnected the call',
        label: 'User Disconnected the call',
      },
      { value: 'Switched off', label: 'Switched off' },
      {
        value: 'Temporarily out of service',
        label: 'Temporarily out of service',
      },
      { value: 'Unavailable', label: 'Unavailable' },
      {
        value: 'Out of network coverage area',
        label: 'Out of network coverage area',
      },
    ],
  };

  componentDidMount() {
    this.getCallRemarksOptions();
  }

  getCallRemarksOptions() {
    return loginInfo()
      .then((res) => {
        const { callRemarksOptions } = res;
        this.setState({
          callRemarksOptions: [
            ...callRemarksOptions.map((ele) => ({
              value: ele.id,
              label: ele.label,
              textBoxRemarks: ele.optional === 0,
            })),
            {
              value: 99,
              label: 'Call Answered by customer',
              textBoxRemarks: 0,
            },
          ],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setRemarksForCall =
    (ucid, isCategory = false) =>
    (val, action) => {
      if (action.action === 'select-option' || action.action === 'set-value') {
        if (val.label === 'Language Preference') {
          this.setState({ languageSelect: true });
        } else if (val.label === 'SOP callback') {
          this.props.setSopCallback(true);
          this.setState({ languageSelect: false });
        } else {
          this.props.setSopCallback(false);
          this.setState({ languageSelect: false });
        }
      }
      if (action.action === 'select-option' || action.action === 'set-value') {
        if (
          val.label === 'User requested callback' ||
          (val.label === 'Call Later' && isCategory === false)
        ) {
          this.setState({
            showFollowupModal: ucid,
            followupRemark: {
              label: isCategory ? null : val.label,
              value: isCategory ? null : val.value,
              categoryId: val.categoryId,
              remarksRequired: val.textBoxRemarks,
              textBoxRemarks: val.textBoxRemarks ? '' : null,
            },
          });
          return;
        }
        this.setState({
          callRemarks: {
            label: isCategory ? null : val.label,
            value: isCategory ? null : val.value,
            categoryId: val.categoryId,
            remarksRequired: val.textBoxRemarks,
            textBoxRemarks: val.textBoxRemarks ? '' : null,
          },
        });
      }
    };

  saveRemarksForCall = (ucid) => () => {
    const { activeConsult } = this.props;

    const { callRemarks, activeCallLogTab } = this.state;
    const userId = activeConsult.user.userId;

    this.setState({
      savingRemarks: true,
      savingRemarksFor: ucid,
    });
    console.log(callRemarks);
    if (callRemarks.value === 99) {
      this.setState({
        savingRemarks: false,
        savingRemarksFor: '',
      });
      this.props.hideModal();
      return;
    }
    saveRemarksForCall(
      ucid,
      callRemarks.value,
      callRemarks.textBoxRemarks,
      activeCallLogTab,
      userId,
    )
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          savingRemarks: false,
          savingRemarksFor: '',
        });
        if (this.props.isSopCallback && !this.props.isAlternateCall) {
          this.props.sopCallback();
        } else {
          this.props.hideModal();
        }
      });
  };

  setCallTextBoxRemarks = (e) => {
    let { callRemarks } = this.state;
    callRemarks = Object.assign(callRemarks, {
      textBoxRemarks: e.target.value,
    });
    this.setState({
      callRemarks: callRemarks,
    });
  };
  setRemarksForSop = (val, a) => {
    console.log(val, a);
    let { callRemarks } = this.state;
    callRemarks = Object.assign(callRemarks, {
      textBoxRemarks: val.value,
    });
    this.setState({
      callRemarks: callRemarks,
    });
  };
  findRemarkById(remarkId) {
    const { callRemarksOptions } = this.state;
    let callRemarks = callRemarksOptions;
    const remark = callRemarks.find((ele) => ele.value === remarkId);
    if (remark) {
      return remark.label;
    } else {
      return null;
    }
  }
  updateLanguage = (ucid = null, languageProp) => {
    const { activeConsult } = this.props;
    const { callRemarks, activeCallLogTab } = this.state;
    let languageSelect, language;
    if (this.state.language) {
      languageSelect = this.state.languageSelect;
      language = languageSelect ? this.state.language : this.state.language;
    }
    if (languageProp) {
      languageSelect = languageProp;
      language = languageProp;
    }

    console.log(
      this.state.languageSelect,
      this.state.language,
      languageProp,
      'selectedOption',
    );

    const userId = activeConsult.user.userId;
    const body = {
      language,
      userId,
    };

    updateLanguage(body)
      .then(() => {
        this.setState(
          {
            languageUpdated: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                languageUpdated: false,
                language: '',
                languageSelect: false,
              });
            }, 600);
          },
        );
      })
      .then(() => {
        if (ucid) {
          callRemarks[ucid] = {
            ...callRemarks[ucid],
            textBoxRemarks: language,
          };
          this.setState(
            {
              callRemarks: Object.assign({}, callRemarks),
              savingRemarks: true,
              savingRemarksFor: ucid,
            },
            () => {
              console.log(callRemarks);
              return saveRemarksForCall(
                ucid,
                callRemarks[ucid].value,
                language,
                activeCallLogTab,
                userId,
              ).finally(() => {
                this.setState({
                  savingRemarks: false,
                  savingRemarksFor: '',
                });
              });
            },
          );
          console.log('done');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleLanguageChange = (selectedOption, ucid) => {
    console.log(selectedOption, 'check');
    // console.log(selectedOption.value);
    //const newRemarkVal = selectedOption.value + this.state.remarks.textBoxRemarks;
    this.setState({
      language: selectedOption.value,
    });
    if (ucid) {
      this.updateLanguage(ucid, selectedOption.value);
    }

    //this.setState({newRemarkVal: selectedOption.value + this.state.remarks.textBoxRemarks });
  };

  findCategoryLabel = (categoryId) => {
    let label = '';
    const { callRemarksOptions } = this.state;
    callRemarksOptions.forEach((e) => {
      if (e.categoryId === categoryId) {
        label = e.label;
      }
    });
    console.log(label, 'working ?');
    return label;
  };
  confirmFollowup = () => {
    this.setState({
      showFollowupModal: true,
      followupDate: moment().add(60, 'mins').toDate(),
    });
  };

  onFollowupDateChanged = (date) => {
    this.setState({
      followupDate: date,
    });
  };

  setFollowup = () => {
    const { activeConsult, dispatch } = this.props;
    const { followupDate, callRemarks, activeCallLogTab } = this.state;
    const userId = activeConsult.user.userId;

    markFollowupUser(
      activeConsult.user.userId,
      moment(followupDate).format('YYYY-MM-DD HH:mm:ss'),
    )
      .then(() => {
        callRemarks[this.state.showFollowupModal] = {
          ...this.state.followupRemark,
          textBoxRemarks: `Call At ${moment(followupDate).format(
            'MMMM Do YYYY, h:mm a',
          )}`,
        };
        this.setState(
          {
            callRemarks: Object.assign({}, callRemarks),
            savingRemarks: true,
            savingRemarksFor: this.state.showFollowupModal,
          },
          () => {
            console.log(callRemarks);
            return saveRemarksForCall(
              this.state.showFollowupModal,
              callRemarks[this.state.showFollowupModal].value,
              callRemarks[this.state.showFollowupModal].textBoxRemarks,
              activeCallLogTab,
              userId,
            ).finally(() => {
              this.setState({
                savingRemarks: false,
                savingRemarksFor: '',
                showFollowupModal: false,
              });
            });
          },
        );
        console.log('done');
      })
      .then(() => {
        console.log('done2', this.state.showFollowupModal);
      })
      .then(() => {
        this.props.hideModal();
        // dispatch(resetActiveConsult());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          voiceCallLoading: false,
        });
      });
  };
  endCallHandler = () => {
    this.setState({
      savingRemarks: false,
      savingRemarksFor: '',
      submissionRemarks: '',
      callRemarks: {},
      callRemarksOptions: [],
      language: '',
      languageUpdated: false,
      languageSelect: false,
    });
    this.props.hideModal();
  };
  render() {
    const { insurer, isSopCallback, activeConsult } = this.props;
    const {
      languageSelect,
      callRemarks,
      callRemarksOptions,
      savingRemarks,
      savingRemarksFor,
      showFollowupModal,
      followupDate,
    } = this.state;
    const now = new Date();
    now.setSeconds(0);
    now.setMilliseconds(0);
    const remarks = callRemarks;
    console.log(this.props, 'active');
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Voice Call</HeaderText>
              {/* <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent> */}
            </ModalHeader>

            <Body>
              <LabelText>Voice call has been initiated successfully!</LabelText>
              <AddCallRemarksContainer>
                <BoldText fontSize="12px" color="#72777A" className="mt-3">
                  Add Call Remarks voice
                </BoldText>
                {insurer === 'ipru' ? (
                  <div>
                    <Select
                      options={callRemarksOptions.map((ele) => ({
                        label: ele.label,
                        value: ele.categoryId,
                        categoryId: ele.categoryId,
                        categoryLabel: ele.label,
                      }))}
                      // isDisabled={qc}
                      classNamePrefix="react-select"
                      value={
                        remarks.categoryId
                          ? {
                              label: this.findCategoryLabel(remarks.categoryId),
                              value: remarks.categoryId,
                            }
                          : null
                      }
                      onChange={this.setRemarksForCall(this.props.ucid, true)}
                      className="mt-2 select-options"
                    />{' '}
                    {remarks.categoryId ? (
                      <Select
                        options={callRemarksOptions.filter((ele) => {
                          if (ele.categoryId === remarks.categoryId) {
                            console.log(ele);
                            return ele.options;
                          }
                        })}
                        // isDisabled={qc}
                        classNamePrefix="react-select"
                        value={
                          remarks.label
                            ? {
                                label: remarks.label,
                                value: remarks.value,
                              }
                            : null
                        }
                        onChange={this.setRemarksForCall(this.props.ucid)}
                        className="mt-2 select-options"
                      />
                    ) : (
                      ''
                    )}{' '}
                  </div>
                ) : (
                  <Select
                    options={callRemarksOptions}
                    // isDisabled={qc}
                    classNamePrefix="react-select"
                    value={
                      remarks.label
                        ? {
                            label: remarks.label,
                            value: remarks.value,
                          }
                        : null
                    }
                    onChange={this.setRemarksForCall(this.props.ucid)}
                    className="mt-2 select-options"
                  />
                )}
                {languageSelect && (
                  <Select
                    placeholder="Please Select a Language"
                    onChange={(e) => {
                      console.log(e);
                      this.handleLanguageChange(e, this.props.ucid);
                    }}
                    className="mt-3 mr-2"
                    options={this.state.languageOptions}
                  />
                )}
                {isSopCallback ? (
                  <Creatable
                    placeholder="Please Select a option"
                    className="mt-3 mr-2"
                    options={this.state.sopCallbackOption}
                    onChange={this.setRemarksForSop}
                  />
                ) : (
                  <input
                    className="form-control mt-3 p-2"
                    type="text"
                    placeholder={`Remarks for above reason ${
                      remarks.remarksRequired ? '(Mandatory)' : ''
                    }`}
                    value={remarks.textBoxRemarks || ''}
                    onChange={this.setCallTextBoxRemarks}
                    // disabled={qc}
                  />
                )}

                <PrimaryButton
                  type="button"
                  className="mt-3 float-left"
                  onTap={this.saveRemarksForCall(this.props.ucid)}
                  disabled={
                    // qc ||
                    remarks === null ||
                    remarks === undefined ||
                    !remarks.label ||
                    savingRemarks ||
                    (remarks.remarksRequired && !remarks.textBoxRemarks)
                  }
                >
                  <Text fontSize="16px" color="white">
                    {savingRemarksFor === this.props.ucid
                      ? 'Saving'
                      : 'Save Remarks'}
                  </Text>
                </PrimaryButton>
              </AddCallRemarksContainer>
              {showFollowupModal && (
                <Modal>
                  <ModalContainer>
                    <FollowupModalBody>
                      <ModalHeader>
                        <HeaderText> Schedule Followup </HeaderText>{' '}
                        <ButtonsComponent>
                          <CloseContainer
                            onTap={() => {
                              this.setState({
                                showFollowupModal: false,
                              });
                            }}
                          >
                            <CrossIcon />
                          </CloseContainer>{' '}
                        </ButtonsComponent>{' '}
                      </ModalHeader>{' '}
                      <Body>
                        <LabelText>
                          {' '}
                          {`Set a time to followup with ${activeConsult.user.userName}`}{' '}
                        </LabelText>{' '}
                        <DatePicker
                          selected={followupDate}
                          minDate={now}
                          minTime={
                            new Date().getDate() ===
                            new Date(
                              this.state.followupDate || new Date(),
                            ).getDate()
                              ? new Date().setMinutes(
                                  new Date().getMinutes() + 6,
                                )
                              : new Date().setHours(6, 0, 0)
                          }
                          maxTime={new Date().setHours(21, 59, 59)}
                          onChange={this.onFollowupDateChanged}
                          showTimeSelect
                          timeFormat="hh:mm a"
                          timeIntervals={10}
                          dateFormat="yyyy-MM-dd HH:mm"
                        />
                        <ButtonComponent>
                          <ButtonComp
                            onTap={this.setFollowup}
                            loading={this.state.voiceCallLoading}
                          >
                            <ButtonText> Confirm </ButtonText>{' '}
                          </ButtonComp>{' '}
                        </ButtonComponent>{' '}
                      </Body>{' '}
                    </FollowupModalBody>{' '}
                  </ModalContainer>{' '}
                </Modal>
              )}{' '}
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

export default VoiceCallSuccessModal;
