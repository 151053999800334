import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../containers/common/Modal';
import StyledTappable from './common/StyledTappable';
import { Text, BoldText } from './common/Text';
import { PrimaryButton } from './common/Buttons';
import { FaTimes } from 'react-icons/fa';
import { createNewCaseArmsoft, getArmsoftData } from '../services'; //cjamhe

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 40%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
  max-height: 90%;
  overflow: auto;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

class CaseSingUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showAlert: false,
      alertText: '',
      alertType: 'success',
      doctorName: '',
      doctorPassword: '',
      tuNotDoneCheck: false,
      disableChecker: false,
      updateChecker: false,
    };
  }

  toggleTuNotDoneCheck = (e) => {
    if (e.target.value === 'TU NOT DONE') {
      this.setState({ tuNotDoneCheck: true });
    } else {
      this.setState({ tuNotDoneCheck: false });
    }
  };


  checkData = () => {
     const applicationNo = document.querySelector('#application-input').value;

     if (!applicationNo) {
      this.setState({
        alertText: 'Please add applicationNo',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }

     this.setState({disableChecker:false});

     //check res vals set to querieselcetor
     getArmsoftData(applicationNo).then((res) => {
       
        if (res.message === 'warning') {
          this.setState({
            alertText:
              res.errorMessage ||
              'Something went wrong. Please contact administrator',
            alertType: 'warning',
            showAlert: true,
          });
          return;
        }

        const armsoftData = res.data[0];

        if(armsoftData.length === 0 || typeof armsoftData === undefined){
          this.setState({updateChecker:false});
        }
        else{
        document.querySelector('#application-input').value = armsoftData.application_no;
        document.querySelector('#attempt-input').value = armsoftData.attempt1;
        document.querySelector('#asser-input').value = armsoftData.Asser_Name;
        document.querySelector('#case-type-input').value = armsoftData.case_type;
        document.querySelector('#reason-referral-input').value = armsoftData.reason_for_referal;
        document.querySelector('#remark-input').value = armsoftData.remark;
        document.querySelector('#firstStatus').value = armsoftData.First_Status;
        document.querySelector('#contactibility-input').value = armsoftData.Contactability;
        document.querySelector('#mars-input').value = armsoftData.MARS_Filled;
        document.querySelector('#mars-comment-input').value =  armsoftData.MARS_COMMENT;
        document.querySelector('#nri-input').value = armsoftData.nriOrnonnri;
        
       
          
        document.querySelector('#video-input').value = armsoftData.videoOrtele.charAt(0).concat(armsoftData.videoOrtele.slice(1).toLowerCase());
        
        document.querySelector('#vcapturing-input').value = armsoftData.VIDEO_CAPTURING;
      
        document.querySelector('#adversity-input').value = armsoftData.Adversity_Flag.split(" ").map((word) => { return word[0].toUpperCase() + word.substring(1).toLowerCase() }).join(" ");
       
        document.querySelector('#remarks-adversity-input').value =armsoftData.Remarks_Of_Adversity;
        document.querySelector('#attempt-number-input').value = armsoftData.NumberOfAttempts;
        }
        
        this.setState({
          alertText: 'Case Data Loaded successfully',
          alertType: 'success',
          showAlert: true,
        });
        return;
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertText:
            err.response.errorMessage ||
            'Something went wrong. Please try again later',
          alertType: 'danger',
          showAlert: true,
        });
      })
      .finally(() => {
        console.log('armsoft check final block');
        this.setState({ loading: false });
      });

  };

  createCase = () => {
    const { doctorPassword, updateChecker, doctorName } = this.state;

    const applicationNo = document.querySelector('#application-input').value;
    const attempt = document.querySelector('#attempt-input').value;
    const asser = document.querySelector('#asser-input').value;
    const case_type = document.querySelector('#case-type-input').value;
    const reason_referral = document.querySelector(
      '#reason-referral-input',
    ).value;
    const adversity_flag = document.querySelector('#adversity-input').value;
    const remark = document.querySelector('#remark-input').value; //check
    const first_status = document.querySelector('#firstStatus').value;
    const contactibility = document.querySelector(
      '#contactibility-input',
    ).value;
    const mars_filled = document.querySelector('#mars-input').value;
    const mars_comment = document.querySelector('#mars-comment-input').value;
    const nriOrNonNri = document.querySelector('#nri-input').value;
    const videoOrTele = document.querySelector('#video-input').value;
    const video_capturing = document.querySelector('#vcapturing-input').value;

    const remarks_adversity = document.querySelector(
      '#remarks-adversity-input',
    ).value;
    const number_of_attempt = document.querySelector(
      '#attempt-number-input',
    ).value;

    if (applicationNo.length < 10 || applicationNo.length > 15) {
      this.setState({
        alertText:
          'Application No should be more than 15 digits and not less than 10 digits.',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }

    if (!applicationNo) {
      this.setState({
        alertText: 'Please add applicationNo',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!attempt) {
      this.setState({
        alertText: 'Please add Attempt 1 Remark / Final Remark',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!asser) {
      this.setState({
        alertText: 'Please add asser name',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!case_type) {
      this.setState({
        alertText: 'Please add case_type',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!reason_referral) {
      this.setState({
        alertText: 'Please add reason for referral',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!remark) {
      this.setState({
        alertText: 'Please add remark',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!first_status) {
      this.setState({
        alertText: 'Please add final status ',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!contactibility) {
      this.setState({
        alertText: 'Please add contactibility',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!mars_filled) {
      this.setState({
        alertText: 'Please add mars_filled',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!mars_comment) {
      this.setState({
        alertText: 'Please add mars_comment',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!nriOrNonNri) {
      this.setState({
        alertText: 'Please add nri Or NonNri',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!videoOrTele) {
      this.setState({
        alertText: 'Please add video Or Tele',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!video_capturing) {
      this.setState({
        alertText: 'Please add video_capturing',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    if (!number_of_attempt) {
      this.setState({
        alertText: 'Please add number_of_attempt',
        alertType: 'danger',
        showAlert: true,
      });
      return null;
    }
    createNewCaseArmsoft(
      applicationNo,
      attempt,
      asser,
      case_type,
      reason_referral,
      remark,
      first_status,
      contactibility,
      mars_filled,
      mars_comment,
      nriOrNonNri,
      videoOrTele,
      video_capturing,
      number_of_attempt,
      adversity_flag,
      remarks_adversity,
      updateChecker,
    )
      .then((res) => {
        if (res.message === 'warning') {
          this.setState({
            alertText:
              res.errorMessage ||
              'Something went wrong. Please contact administrator',
            alertType: 'warning',
            showAlert: true,
          });
          return;
        }

        document.querySelector('#application-input').value = '';
        document.querySelector('#attempt-input').value = '';
        document.querySelector('#asser-input').value = '';
        document.querySelector('#case-type-input').value = '';
        document.querySelector('#reason-referral-input').value = '';
        document.querySelector('#remark-input').value = '';
        document.querySelector('#firstStatus').value = '';
        document.querySelector('#contactibility-input').value = '';
        document.querySelector('#mars-input').value = '';
        document.querySelector('#mars-comment-input').value = '';
        document.querySelector('#nri-input').value = '';
        document.querySelector('#video-input').value = '';
        document.querySelector('#vcapturing-input').value = '';
        document.querySelector('#adversity-input').value = '';
        document.querySelector('#remarks-adversity-input').value = '';
        document.querySelector('#attempt-number-input').value = '';
        this.setState({
          alertText: 'Case created successfully',
          alertType: 'success',
          showAlert: true,
        });
        return;
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertText:
            err.response.errorMessage ||
            'Something went wrong. Please try again later',
          alertType: 'danger',
          showAlert: true,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      alertText,
      alertType,
      doctorName,
      doctorPassword,
      showAlert,
      loading,
      tuNotDoneCheck,
      disableChecker,
      updateChecker,
    } = this.state;
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Add a new Case</HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <div className="container">
                <div className="row">
                  <div className="col-">
                    <div
                      className={`alert collapse ${showAlert ? 'show' : ''} ${
                        alertType === 'success'
                          ? 'alert-success'
                          : alertType === 'warning'
                          ? 'alert-warning'
                          : 'alert-danger'
                      }`}
                      role="alert"
                    >
                      {alertText}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group row no-gutters">
                      <label
                        htmlFor="application-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Application No</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="application-input"
                        />
                      </div>
                      <label
                        htmlFor="case-type-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Case Type</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <select className="custom-select" id="case-type-input" disabled= {this.state.disableChecker}>
                          <option value=""></option>
                          <option value="ARMSOFTECH INBOUND">
                            ARMSOFTECH INBOUND
                          </option>
                          <option value="ARMSOFTECH OUTBOUND">
                            ARMSOFTECH OUTBOUND
                          </option>
                        </select>
                      </div>
                      <label
                        htmlFor="reason-referral-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Reason For Refferal</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          list="cars"
                          className="custom-select"
                          id="reason-referral-input"
                          placeholder="BRE TU OR REASON FOR DISCREPANCY"
                          defaultValue={'BRE TU'}
                          disabled= {this.state.disableChecker}
                        />
                        <datalist id="cars">
                          <option>BRE TU</option>
                        </datalist>
                        {/* <select
                          className="custom-select"
                          id="reason-referral-input"
                        >
                          <option value=""></option>
                          <option value="BRE Tu">BRE TU</option>
                          <option value="Discrepancy">Discrepancy </option>
                        </select> */}
                      </div>
                      <label
                        htmlFor="remark-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Remark </Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <select
                          className="custom-select"
                          id="remark-input"
                          onChange={this.toggleTuNotDoneCheck}
                           disabled= {this.state.disableChecker}
                        >
                          <option value=""></option>
                          <option value="TI Done">TI Done</option>
                          <option value="TU NOT DONE">TU NOT DONE</option>
                          <option value="DISCREPANCY CALL NOT DONE">DISCREPANCY CALL NOT DONE</option>
                          <option value="DISCREPANCY CALL DONE">DISCREPANCY CALL DONE</option>
                        </select>
                      </div>

                      <label
                        htmlFor="firstStatus"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Final Status</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <select className="custom-select" id="firstStatus"  disabled= {this.state.disableChecker}>
                          <option value=""></option>
                          <option value="Communication Problem Language ">
                            Communication Problem Language{' '}
                          </option>
                          <option value="Call Later">Call Later </option>
                          <option value="Medicals Done by LA ">
                            Medicals Done by LA{' '}
                          </option>
                          <option value="Not Contactable ">
                            Not Contactable{' '}
                          </option>
                          <option value="Not Interested">
                            Not Interested{' '}
                          </option>
                          <option value="TU Already done">
                            TU Already done{' '}
                          </option>
                          <option value="Others">Others </option>
                          <option value="TU Done in Sim App ">
                            TU Done in Sim App{' '}
                          </option>
                          <option value="Outscope">Outscope </option>
                          <option value="TU SUCCESSFUL">TU SUCCESSFUL </option>
                        </select>
                      </div>
                      <label
                        htmlFor="attempt-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">
                          Attempt 1 Remark / Final Remark
                        </Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="attempt-input"
                           disabled= {this.state.disableChecker}
                        />
                      </div>
                      <label
                        htmlFor="asser-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Asser Name</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="asser-input"
                           disabled= {this.state.disableChecker}
                        />
                      </div>

                      <label
                        htmlFor="contactibility-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Contactibility</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <select
                          className="custom-select"
                          id="contactibility-input"
                           disabled= {this.state.disableChecker}
                        >
                          <option value=""></option>
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                        </select>
                      </div>

                      <label
                        htmlFor="attempt-number-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Number of Attempts</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="attempt-number-input"
                           disabled= {this.state.disableChecker}
                        />
                      </div>

                      <label
                        htmlFor="mars-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">MARS Field</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <select className="custom-select" id="mars-input"  disabled= {this.state.disableChecker}>
                          <option value=""></option>
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                        </select>
                      </div>

                      <label
                        htmlFor="mars-comment-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">MARS Comment</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <select
                          className="custom-select"
                          id="mars-comment-input"
                           disabled= {this.state.disableChecker}
                        >
                          <option value=""></option>
                          <option value="IForm Filled">IForm Filled</option>
                          <option value="Not Filled">Not Filled</option>
                        </select>
                      </div>

                      <label
                        htmlFor="nri-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">NRI/NON-NRI</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <select className="custom-select" id="nri-input"  disabled= {this.state.disableChecker}>
                          <option value=""></option>
                          <option value="NRI">NRI</option>
                          <option value="NON-NRI">NON-NRI</option>
                        </select>
                      </div>

                      <label
                        htmlFor="video-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Video/Tele</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <select className="custom-select" id="video-input"  disabled= {this.state.disableChecker}>
                          <option value=""></option>
                          <option value="Video">Video</option>
                          <option value="Tele">Tele</option>
                        </select>
                      </div>
                      <label
                        htmlFor="vcapturing-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">VIDEO CAPTURING</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <select className="custom-select" id="vcapturing-input"  disabled= {this.state.disableChecker}>
                          <option value=""></option>
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                        </select>
                      </div>
                      <label
                        htmlFor="adversity-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text
                          disabled={this.state.disableChecker  && this.state.tuNotDoneCheck}
                          fontSize="14px"
                        >
                          Adversity Flag
                        </Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <select
                          className="custom-select"
                          id="adversity-input"
                           disabled= {this.state.disableChecker  && this.state.tuNotDoneCheck}
                        >
                          <option value=""></option>
                          <option value="Single">Single</option>
                          <option value="No Adversity">No Adversity</option>
                          <option value="Multiple">Multiple</option>
                        </select>
                      </div>

                      <label
                        htmlFor="remarks-adversity-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text
                          fontSize="14px"
                          disabled={this.state.disableChecker  && this.state.tuNotDoneCheck}
                        >
                          Remarks of Adversity
                        </Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="remarks-adversity-input"
                          disabled={this.state.tuNotDoneCheck}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    {this.state.disableChecker ?  (<PrimaryButton loading={loading} onTap={this.checkData}>
                      <BoldText fontSize="16px" color="white">
                        Check Data
                      </BoldText>
                    </PrimaryButton>):  (<PrimaryButton loading={loading} onTap={this.createCase}>
                      <BoldText fontSize="16px" color="white">
                        Create Case
                      </BoldText>
                    </PrimaryButton>)}
                   
                  </div>
                </div>
              </div>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

export default CaseSingUp;
