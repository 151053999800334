import React from 'react';
import { func, number } from 'prop-types';

import config from '../../config';

const EndScreen = ({ userId, retakeHra }) => (
  <div className="container-fluid h-100 end-screen">
    <div className="row">
      <div className="col pt-5 mt-5">
        <div className="jumbotron bg-white rounded-0 pb-0 mb-0">
          <div className="row m-0 justify-content-center text-center">
            <div className="col">
              <div className="row justify-content-center">
                <div className="col-4">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/visit-hra/hraicon.png"
                    className="w-100"
                    alt="HRA Icon"
                  />
                </div>
              </div>
              <h1 className="display-4 mt-3">Health Risk Assessment</h1>
            </div>
          </div>
          <div className="row mt-3 justify-content-center text-center">
            <div className="col-3">
              <img
                src="https://s3.ap-south-1.amazonaws.com/visit-hra/completeicon.png"
                className="w-100"
                alt="Complete"
              />
            </div>
            <div className="col-12">
              <p className="h5 mt-3">
                You have completed your Health Risk Assessment successfully.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row justify-content-center text-center mt-1">
      <div className="col-9 mt-5 justify-content-center">
        <a
          href={`${config.apiBaseUrl}health-risk-assessment/${userId}/form/generate`}
          target="_blank"
          rel="noopener noreferrer"
          type="button"
          className="btn btn-primary w-100 text-uppercase p-3"
        >
          <p className="h5 m-0">download report</p>
        </a>
      </div>
      <div className="col-9 mt-5 justify-content-center">
        <button onClick={retakeHra} className="h6 btn m-0 text-primary">
          <i className="fa fa-repeat mr-3"></i>Take HRA again
        </button>
      </div>
      <div className="col-12 mt-5 justify-content-center">
        <p>
          *A PDF file of the report has been sent to your registered email
          address
        </p>
      </div>
    </div>
  </div>
);

EndScreen.propTypes = {
  userId: number.isRequired,
  retakeHra: func.isRequired,
};

export default EndScreen;
