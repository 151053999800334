import React, { Component } from 'react';
import Axios from 'axios';
import { Result, Typography } from 'antd';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { RiScreenshot2Fill } from 'react-icons/ri';
import StyledTappable from '../components/common/StyledTappable';
import { connect } from 'react-redux';

import config from '../config';
import {
  initializeVideoCallClient,
  joinCall,
  joinLog,
  videoScreenshot,
  videoScreenshotForRelative,
} from '../services/videoCall';
import LoadingComponent from '../components/common/LoadingComponent';
import ErrorModal from '../containers/common/ErrorModal';
import Modal from '../containers/common/Modal';
import modalIcon from '../images/common/paymentFail.svg';
import { BoldText, RegularText } from '../components/common/Text';
import { videoCallMade, userStatus } from '../actions';
import html2canvas from 'html2canvas';
import { PrimaryButton } from './common/Buttons';
import Select from 'react-select';

import { useDyteClient, DyteProvider } from '@dytesdk/react-web-core';
import DyteVideoContainer from './DyteVideoContainer';

const { Text } = Typography;

const CloseContainer = styled(StyledTappable)`
  margin-bottom: 20rem;
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #dadada;
  z-index: 110;
  position: absolute;
  bottom: 0;
  left: 50%;
`;
const Screenshot = styled(StyledTappable)`
  padding: 5px;
  height: 5rem;
  border-radius: 5rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  font-weight: bold;
  z-index: 110;
  position: absolute;
  top: 10px;
  left: 50%;
`;

const CloseIcon = styled(FaTimes)`
  width: 3rem;
  height: 3rem;
`;

const CameraIcon = styled(RiScreenshot2Fill)`
  width: 3rem;
  height: 3rem;
`;

const CloseContainerModal = styled(StyledTappable)`
  align-self: flex-end;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  max-width: 400px;
  padding: 1.8rem 2rem 3rem 2rem;
  border-radius: 0.8rem;
  margin: 0 1.6rem;
  text-align: center;
`;

const ModalIcon = styled.img`
  width: 9.1rem;
`;

const ModalText = styled(RegularText)`
  font-size: 1.6rem;
`;

const ModalTitle = styled(BoldText)`
  font-size: 1.1rem;
  margin: 0.625rem 0rem;
`;

const PreviewButton = styled(PrimaryButton)`
  float: left;
  color: white;
  font-size: 14px;
`;

class CallScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      failed: false,
      errorMessage: '',
      showError: false,
      session: null,
      showUserLeftModal: false,
      subscriber: null,
      publisher: null,
      viewRelative: false,
      selectedRelative: '',
      sessionId: null,
      roomName: '',
      dyteCheck: false,
    };
  }

  componentDidMount() {
    console.log(this.props.callId);
    this.props.dispatch(userStatus(false));
    if (this.props.callId) {
      this.videoCallClient = initializeVideoCallClient();
      // this.videoCallClient.publish(this.props.callId)
      this.videoCallClient.subscribe(this.props.callId, this.props.insurer);

      joinCall(this.props.callId).then((res) => {
        if (res.data === 'Doctor already joined') {
          this.setState({ showError: true });
        } else {
          if (!res.dyteChecker) {
            this.connectToOpenTok(res.sessionId, res.token);
          }

          this.setState({
            sessionId: res.sessionId,
            token: res.token,
            roomName: res.roomName,
            dyteCheck: res.dyteChecker,
          });
        }
      });
    }
  }

  componentDidUpdate() {
    if (this.props.userStatus && !this.state.showUserLeftModal) {
      this.setState({ showUserLeftModal: true });
    }
    if (!this.props.userStatus && this.state.showUserLeftModal) {
      this.setState({ showUserLeftModal: false });
    }
  }

  connectToOpenTok = (sessionId, token) => {
    const session = window.OT.initSession(config.opentokApiKey, sessionId);
    this.setState({ session: session });
    let subscriber = '';

    session.on('streamCreated', (event) => {
      console.log(event);
      subscriber = session.subscribe(
        event.stream,
        'subscriber',
        {
          insertMode: 'append',
          width: '100%',
          height: '100%',
        },
        (err) => {
          console.log(err);
        },
      );
      this.setState({ subscriber: subscriber });
    });

    session.on('sessionDisconnected', function (event) {
      console.log('The session disconnected. ' + event.reason);
    });

    // Create a publisher
    var publisher = window.OT.initPublisher(
      'publisher',
      {
        insertMode: 'append',
        width: '100%',
        height: '100%',
      },
      (err) => {
        console.log(err);
      },
    );

    session.connect(token, (err, res) => {
      if (err) {
        console.log(err, 'Error connecting to open tok session');
        this.setState({
          failed: true,
          errorMessage: err.message,
        });
        return;
      }
      if (res.target.connection.id) {
        const body = {
          callId: this.props.callId,
          userType: 'doctor',
          connectionId: session.connection.connectionId,
          doctorId: this.props.doctorId,
        };
        console.log(body);
        joinLog(body)
          .then((res) => {
            console.log('Response from join video call api');
          })
          .catch((err) => {
            console.log(err);
          });
      }
      console.log('connection successful');
      // Subscribe to a newly created stream
      session.publish(publisher, (err) => {
        if (err) {
          console.log(err);
          this.setState({
            failed: true,
            errorMessage: err.message,
          });
          return;
        }
        session.signal(
          {
            type: 'userConnected',
            data: 'userConnect',
          },
          (err) => {
            if (err) {
              console.log(err, 'Failed to send signal');
              return;
            }
          },
        );
        this.setState({ publisher: publisher });
      });
    });
  };

  stopVideoCall = () => {
    if (this.state.session) {
      this.state.session.disconnect();
      this.props.dispatch(videoCallMade(true));
    }
    this.videoCallClient.unsubscribe(this.props.callId);

    this.setState({ showError: false });
    this.props.dispatch(userStatus(false));
    this.props.endVideoCall();
  };

  takeScreenshot = (type) => {
    if (['abhi', 'mchi'].includes(this.props.insurer)) {
      return this.viewRelativeForScreenshot();
    }
    if (this.state.subscriber) {
      var subscriberImage =
        'data:image/png;base64,' + this.state.subscriber.getImgData();
      if (subscriberImage.length < 30) {
        return;
      }

      const img = document.querySelector('#screenshot');
      img.src = subscriberImage;
      img.style.display = 'block';
      let blob = this.dataURItoBlob(subscriberImage);
      let formData = new FormData();
      formData.append('file', blob);
      videoScreenshot(formData, this.props.callId, type)
        .then((result) => { })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setTimeout(() => {
            const img = document.querySelector('#screenshot');
            if (img) {
              img.style.display = 'none';
              img.src = null;
            }
          }, 5000);
        });
    }
  };

  takeScreenshotFromPopUp = (relativeId) => {
    this.setState({ loading: true });
    if (this.state.subscriber) {
      var subscriberImage =
        'data:image/png;base64,' + this.state.subscriber.getImgData();
      if (subscriberImage.length < 30) {
        return;
      }

      const img = document.querySelector('#screenshot');
      img.src = subscriberImage;
      img.style.display = 'block';
      let blob = this.dataURItoBlob(subscriberImage);
      let formData = new FormData();
      formData.append('file', blob);
      videoScreenshotForRelative(formData, relativeId)
        .then((result) => {
          this.setState({ viewRelative: false, selectedRelative: '' });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.setState({ loading: false });
          setTimeout(() => {
            const img = document.querySelector('#screenshot');
            if (img) {
              img.style.display = 'none';
              img.src = null;
            }
          }, 5000);
        });
    }
  };

  viewRelativeForScreenshot = () => {
    this.setState({
      viewRelative: true,
    });
  };
  hideViewRelativeModal = () => {
    this.setState({
      viewRelative: false,
    });
  };
  dataURItoBlob = (dataURI) => {
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };
  selectRelative = (val) => {
    this.setState({ selectedRelative: val.value });
  };

  render() {
    const { errorMessage, failed, dyteCheck } = this.state;

    if (failed) {
      return (
        <Result
          status="warning"
          title="Oops! We couldn't connect you to your call"
          extra={<Text>{`Error message: ${errorMessage}`}</Text>}
        />
      );
    }

    if (dyteCheck && false) {
      return <DyteVideoContainer />;
    } else {
      return (
        <div id="videos">
          {dyteCheck ? (

            <DyteVideoContainer
              stopCall={() => {
                this.props.dispatch(videoCallMade(true));
                this.setState({ showError: false });
                this.props.dispatch(userStatus(false));
                this.props.endVideoCall();
              }}
            />
          ) : (
            <div
              className="callScreen"
              style={{ height: '100%', width: '100%' }}
            >
              <div id="subscriber"></div>
              <div id="publisher"></div>
              <img src={null} id="screenshot" alt="img"></img>
            </div>
          )}

          {this.state.showError && (
            <ErrorModal>
              <ModalContainer>
                <ModalBody>
                  <CloseContainerModal onTap={this.stopVideoCall}>
                    <FaTimes />
                  </CloseContainerModal>
                  <ModalIcon src={modalIcon} />
                  <ModalTitle>Error While joining</ModalTitle>
                  <ModalText>Doctor already joined.</ModalText>
                </ModalBody>
              </ModalContainer>
            </ErrorModal>
          )}
          {this.state.showUserLeftModal && (
            <Modal>
              <ModalContainer>
                <ModalBody>
                  <CloseContainerModal
                    onTap={() => {
                      this.props.dispatch(userStatus(false));
                    }}
                  >
                    <FaTimes />
                  </CloseContainerModal>
                  <ModalIcon src={modalIcon} />
                  <ModalTitle>User has disconnected.</ModalTitle>
                </ModalBody>
              </ModalContainer>
            </Modal>
          )}
          {this.state.viewRelative && !dyteCheck && (
            <Modal>
              <ModalContainer>
                <ModalBody>
                  <CloseContainerModal
                    onTap={() => {
                      this.hideViewRelativeModal();
                    }}
                  >
                    <FaTimes />
                  </CloseContainerModal>
                  <ModalTitle>
                    Select Relative to save screenshot for.
                  </ModalTitle>
                  <Select
                    options={this.props.activeConsult.relatives.map((ele) => ({
                      label: ele.name,
                      value: ele.relativeId,
                    }))}
                    classNamePrefix="react-select"
                    onChange={this.selectRelative}
                    className="mt-2 select-options relative-select"
                  />
                  <PreviewButton
                    loading={this.state.loading}
                    disabled={!this.state.selectedRelative}
                    onTap={() =>
                      this.takeScreenshotFromPopUp(this.state.selectedRelative)
                    }
                    className="mt-3"
                  >
                    <CameraIcon /> Click And Save.{' '}
                  </PreviewButton>
                </ModalBody>
              </ModalContainer>
            </Modal>
          )}
          {!dyteCheck ? (
            <CloseContainer onTap={() => this.stopVideoCall()}>
              <CloseIcon />
            </CloseContainer>
          ) : (
            ''
          )}
          {this.state.publisher &&
            this.state.subscriber &&
            !this.props.userStatus &&
            !dyteCheck && (
              <div
                style={{
                  display: 'flex',
                }}
              >
                <Screenshot onTap={() => this.takeScreenshot('photoid')}>
                  <CameraIcon />
                  <br />
                  <p
                    style={{
                      margin: ' 0 10px',
                      fontSize: '14px',
                    }}
                  >
                    Photo Id
                  </p>
                </Screenshot>
                {this.props.insurer === 'aegon' ||
                  this.props.insurer === 'absli-vv' ? (
                  <Screenshot
                    style={{ marginLeft: '14px', left: '63%' }}
                    onTap={() => this.takeScreenshot('full')}
                  >
                    <CameraIcon />
                    <br />
                    <p
                      style={{
                        margin: ' 0 10px',
                        fontSize: '14px',
                      }}
                    >
                      Full Length
                    </p>
                  </Screenshot>
                ) : (
                  ''
                )}
              </div>
            )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    callId: state.consults.activeConsult.callId,
    userStatus: state.consults.userStatus,
    insurer: state.user.insurer,
    activeConsult: state.consults.activeConsult,
    doctorId: state.user.doctorId,
  };
};

export default connect(mapStateToProps)(CallScreen);
