import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { PrimaryButton } from './common/Buttons';
import Modal from '../containers/common/Modal';
import StyledTappable from './common/StyledTappable';
import { BoldText, Text } from './common/Text';
import { scheduleCall, getUserMembers, initiateMeeting } from '../services';
import { resetActiveConsult, updateCallId } from '../actions';

import VideoCallSuccessModal from '../components/videoCallSuccessModal';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
  text-align: center;
  align-self: center;
`;

const LabelText = styled(Text)`
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: center;
  align-self: center;
  margin-bottom: 2rem;
  color: #000;
  display: flex;
  flex-direction: row;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

class VideoCallScheduleModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      loading: false,
      scheduleDate: new Date(),
      confirmModal: false,
      videoCallLoading: false,
      showVideoCallConfirmModal: false,
      oneConnect: false,
      relatives: [],
    };
  }

  componentDidMount = () => {
    if (
      this.props.activeConsult.user &&
      this.props.activeConsult.user.userId &&
      this.props.insurer === 'abhi'
    ) {
      console.log(this.props.activeConsult.user.userId);
      this.getUserMembers(this.props.activeConsult.user.userId);
    }
  };

  componentDidUpdate(prevProps, prevState) {}

  getUserMembers = (userId) => {
    console.log(userId);
    getUserMembers(userId)
      .then((res) => {
        console.log(res);
        const relatives = [];
        res.relatives.forEach((e, idx) => {
          relatives.push({
            relativeId: e.relativeId,
            name: e.name,
            scheduleTime: new Date(
              new Date().getTime() + 30 * (idx + 1) * 60000,
            ),
          });
        });
        this.setState({ relatives: relatives });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onScheduleDateChanged = (date) => {
    this.setState({
      scheduleDate: date,
    });
  };
  setSchedule = () => {
    this.setState({ videoCallLoading: true });
    const { user, activeConsult } = this.props;
    let { scheduleDate, oneConnect, relatives } = this.state;
    let newScheduleDate = new Date(scheduleDate);
    newScheduleDate = new Date(
      newScheduleDate.setHours(newScheduleDate.getHours() + 5),
    );
    newScheduleDate = newScheduleDate.setMinutes(
      newScheduleDate.getMinutes() + 30,
    );
    const body = {
      scheduleTime: new Date(newScheduleDate),
      scheduleBy: user.doctorId,
      consultationId: activeConsult.consultationId,
      oneConnect: false,
      relatives,
    };
    scheduleCall(body)
      .then((res) => {
        if (res.callId) {
          this.props.dispatch(updateCallId(res.callId));
        }
        this.props.updateData();
        this.setState({
          showVideoCallConfirmModal: true,
          videoCallLoading: false,
        });
      })
      .catch((err) => {
        this.setState({ videoCallLoading: false });
      });
  };

  render() {
    const { loading, scheduleDate } = this.state;
    const { activeConsult } = this.props;
    console.log(this.state.showVideoCallConfirmModal);
    if (this.state.showVideoCallConfirmModal) {
      return <VideoCallSuccessModal hideModal={this.props.hideModal} />;
    } else {
      return (
        <Modal>
          <ModalContainer>
            <ModalBody>
              <ModalHeader>
                <HeaderText>Schedule a video call</HeaderText>
                <ButtonsComponent>
                  <CloseContainer onTap={this.props.hideModal}>
                    <CrossIcon />
                  </CloseContainer>
                </ButtonsComponent>
              </ModalHeader>

              <Body>
                {this.props.insurer === 'abhi' && this.state.oneConnect ? (
                  <div>
                    {this.state.relatives.map((r, idx) => {
                      return (
                        <ButtonComponent>
                          <LabelText>{`${r.name}:   `}</LabelText>
                          &nbsp;
                          <DatePicker
                            selected={r.scheduleTime}
                            onChange={(date) => {
                              const relatives = [...this.state.relatives];
                              relatives[idx].scheduleTime = date;
                              this.setState({
                                relatives,
                              });
                            }}
                            showTimeSelect
                            timeFormat="hh:mm a"
                            timeIntervals={15}
                            dateFormat="yyyy-MM-dd HH:mm"
                            minDate={new Date()}
                          />
                        </ButtonComponent>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    <LabelText>
                      {`Set a time to schedule videocall with ${activeConsult.user.userName}`}
                    </LabelText>
                    <DatePicker
                      selected={scheduleDate}
                      onChange={this.onScheduleDateChanged}
                      showTimeSelect
                      timeFormat="hh:mm a"
                      timeIntervals={15}
                      dateFormat="yyyy-MM-dd HH:mm"
                      minDate={new Date()}
                    />
                  </>
                )}
                {this.props.insurer === 'abhi' && this.props.user.admin && (
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '10px',
                      justifyContent: 'center',
                    }}
                  >
                    <input
                      type="checkbox"
                      id="oneconnect"
                      name="oneconnect"
                      defaultChecked={this.state.oneConnect}
                      onChange={() => {
                        this.setState({
                          oneConnect: !this.state.oneConnect,
                        });
                      }}
                    />
                    <label htmlFor="oneconnect"> One connect</label>
                  </div>
                )}
                <Button
                  onTap={this.setSchedule}
                  loading={this.state.videoCallLoading}
                >
                  <ButtonText>Confirm</ButtonText>
                </Button>
              </Body>
            </ModalBody>
          </ModalContainer>
        </Modal>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  user: state.user,
  insurer: state.user.insurer,
});

export default connect(mapStateToProps)(VideoCallScheduleModal);
