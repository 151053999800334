import React, { Component } from 'react';
import {string, array, func, number} from 'prop-types';
import NavigationButtons from './NavigationButtons';

export default class SingleSelectArray extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.userResponse ? props.userResponse.map((ele) => {
        return {
          orderId: ele.orderId,
          choice: 'No',
        };
      }) : [],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.userResponse ? nextProps.userResponse : [],
    });
  }

  handleChange = (event) => {
      const index = this.state.value.findIndex((item) => {
        return item.orderId.toString() === event.target.value.toString();
      });
      console.log(this.state.value, index, event.target.value);
      const newValues = Array.from(this.state.value);
      newValues[index].choice = this.isChecked(event.target.value) ? 'No' : 'Yes';
      this.setState({
        value: newValues,
      });
  }

  isChecked = (orderId) => {
    const found = this.state.value.find((item) => {
      return item.orderId.toString() === orderId.toString() && item.choice === 'Yes';
    });
    return found ? true : false
  }

  render() {
    const renderOption = (ele, index) => (
      <div
        key={index}
        className="p-3 border border-secondary border-top-0 border-left-0 border-right-0">
        <div className="form-check d-flex">
          <input className="form-check-input d-none" type="checkbox" name="questionOptions" id={`multiSelectOptions${index}`} value={ele.orderId} checked={this.isChecked(ele.orderId)} onChange={this.handleChange} />
          <span className="checkmark" />
          <label className="form-check-label ml-3 flex-grow-1" htmlFor={`multiSelectOptions${index}`}>
            {ele.text}
          </label>
        </div>
      </div>
    );

    const sendResponse = () => {
      this.props.submitResponse(this.state.value);
    };

    const progress = Number.parseInt((this.props.currentOrder-1)/this.props.totalQuestions*100);
    return (
      <div className="container-fluid h-100 d-flex flex-column">
        <div className="row hra-body">
          <div className="col-12">
            <div className="row d-block">
              <div className="col p-0">
                <div className="bg-primary p-3 text-white rounded-0 pb-1 mb-0">
                  <div className="row m-0 justify-content-center">
                    <div className="col-12">
                      <h3 className="mt-3">{this.props.title}</h3>
                    </div>
                    <div className="col-12">
                      <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={this.props.currentOrder-1} aria-valuemin="1" aria-valuemax={this.props.totalQuestions}></div>
                      </div>
                    </div>
                    <div className="col-12 mt-5">
                      <p className="h5 font-weight-normal">
                        {this.props.question}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-stretch flex-grow-1">
              <div className="col-12 p-0">
                {this.props.options.map(renderOption)}
              </div>
              <div className="col-12 mt-3">
                {this.props.info && (
                  <div className="col-12 mt-3 pb-4">
                    <a
                      className="text-primary font-weight-bold btn p-0"
                      data-toggle="collapse"
                      href="#info"
                      role="button"
                      aria-expanded="false"
                      aria-controls="info">
                      See Information
                    </a>
                    <div className="collapse" id="info">
                      <p>
                        {this.props.info}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <NavigationButtons
          submitResponse={sendResponse}
          submitDisabled={this.state.value.length === 0}
          goToPrevious={this.props.goToPrevious} />
      </div>
    );
  }
}

SingleSelectArray.propTypes = {
  title: string.isRequired,
  question: string.isRequired,
  options: array.isRequired,
  info: string,
  userResponse: array,
  currentOrder: number.isRequired,
  totalQuestions: number.isRequired,
  submitResponse: func.isRequired,
  goToPrevious: func.isRequired,
};
