import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RegularText, Text } from '../../common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.4rem 0;
`;

const DoctorImage = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.6rem;
  border-radius: 2.4rem;
`;

const BubbleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem 1.2rem;
  background: #f7f7f7;
  border-radius: 1.4rem;
`;

const DoctorName = styled(Text)`
  font-size: 1.2rem;
  opacity: 0.5;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MessageText = styled(RegularText)`
  font-size: 1.6rem;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1.6rem;
`;

const TimestampText = styled(RegularText)`
  opacity: 0.5;
  font-size: 1.2rem;
`;

const DoctorMessage = ({ message, doctorImage, doctorName, timestamp }) => (
  <OuterContainer>
    <DoctorImage src={doctorImage} />
    <BubbleContainer>
      <DoctorName>{doctorName}</DoctorName>
      <MessageContainer>
        <MessageText>{message}</MessageText>
        {timestamp && (
          <TimestampContainer>
            <TimestampText>
              {new Date(timestamp).toLocaleTimeString('nu', {
                hour: 'numeric',
                minute: '2-digit',
              })}
            </TimestampText>
          </TimestampContainer>
        )}
      </MessageContainer>
    </BubbleContainer>
  </OuterContainer>
);

DoctorMessage.propTypes = {
  doctorName: PropTypes.string,
  doctorImage: PropTypes.string,
  message: PropTypes.string,
  timestamp: PropTypes.number,
};

export default DoctorMessage;
