import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CardContainer from '../../common/CardContainer';
import { Text, BoldText, RegularText } from '../../common/Text';

const OuterContainer = styled(CardContainer)`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
  padding: 0.75rem 1rem;
`;

const Title = styled(BoldText)`
  font-size: 1rem;
`;

const SymptomContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0.5rem;
`;

const SymptomIconContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.15rem;
  margin-right: 0.875rem;
  box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
`;

const SymptomIcon = styled.img`
  width: 1.125rem;
`;

const SymptomText = styled(Text)`
  font-size: 1rem;
`;

const SymptomTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.75rem;
`;

const SymptomTag = styled(RegularText)`
  font-size: 0.875rem;
  opacity: 0.5;
  display: list-item;
  list-style-position: inside;
  margin: 0.5rem 0;
`;

const CaseDetailsMessage = ({ caseDetails }) => {
  const renderSymptom = (ele, idx) => {
    const renderSymptomTag = (tag, tagIdx) => (
      <SymptomTag key={tagIdx}>{tag}</SymptomTag>
    );
    return (
      <SymptomContainer key={idx}>
        <SymptomIconContainer>
          <SymptomIcon src={ele.icon} />
        </SymptomIconContainer>
        <SymptomTagsContainer>
          <SymptomText>{ele.title}</SymptomText>
          {ele.tags.map(renderSymptomTag)}
        </SymptomTagsContainer>
      </SymptomContainer>
    );
  };
  return (
    <OuterContainer>
      <Title>Your Case Details</Title>
      {caseDetails.map(renderSymptom)}
    </OuterContainer>
  );
};

CaseDetailsMessage.propTypes = {
  caseDetails: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

export default CaseDetailsMessage;
