import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from 'valuelink';
import { FaCircleNotch } from 'react-icons/fa';
import { IoMdSend } from 'react-icons/io';
import { Input } from 'valuelink/tags';
import styled from 'styled-components';

import StyledTappable from '../components/common/StyledTappable';
import UserMessage from '../components/consultations/chat-bubbles/UserMessage';
import { RegularText, Text } from '../components/common/Text';
import { getUserNotes, addUserNote } from '../services';

import moment from 'moment';
import LoadingComponent from '../components/common/LoadingComponent';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: white;
  padding: 0.6rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: stretch;
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.1);
  background: white;
  padding: 0 1.6rem;
`;

const InputBox = styled(Input)`
  padding: 1.6rem;
  display: flex;
  align-self: stretch;
  flex: 1;
  ::placeholder {
    color: #bababa;
  }
`;

const SendIcon = styled(IoMdSend)`
  opacity: 0.5;
  font-size: 2rem;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`;

const LoadingIcon = styled(FaCircleNotch)`
  margin-right: 0.5rem;
`;

const DateElement = styled.div`
  align-self: center;
  margin: 10px 0;
  /* background-color: #d8d8d8; */
  border-radius: 10px;
`;

const DateText = styled(RegularText)`
  text-align: center;
  font-size: 1.2rem;
  color: #000;
`;

class UserNotesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatMessage: '',
      errorMessage: null,
      notes: [],
      loading: true,
      fetchMessages: false,
    };
    this.conversationBodyRef = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.handleKeyPress, false);
    this.getUserNotes();
  };

  getUserNotes() {
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;
    this.setState({ loading: true });
    getUserNotes(userId)
      .then(res => {
        const notes = res.result;
        this.setState({ notes });
      })
      .catch(err => {
        this.setState({
          errorMessage:
            (err.data || {}).errorMessage ||
            'Something went wrong. Please try again later',
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleKeyPress = event => {
    // console.log({ event, focus: document.activeElement.id });
    if (event.code === 'Enter') {
      this.sendMessage();
    }
  };

  sendMessage = () => {
    const { chatMessage } = this.state;
    const { activeConsult } = this.props;
    const userId = activeConsult.user.userId;
    if (chatMessage.trim() === '') {
      return;
    }
    this.setState({ loading: true });
    addUserNote(userId, chatMessage)
      .then(res => {
        const notes = res.result;
        this.setState({ notes });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          loading: false,
          chatMessage: '',
        });
      });
  };

  getDateElement = (currentMsgTT, prevMsgTT) => {
    const currentMsgTime = moment(currentMsgTT);
    const prevMsgTime = moment(prevMsgTT);

    let dateText = null;

    if (currentMsgTime.format('DD') > prevMsgTime.format('DD')) {
      const todayTT = moment().startOf('day');
      const yesterdayTT = moment()
        .startOf('day')
        .subtract(1, 'day');
      if (currentMsgTime.valueOf() > todayTT.valueOf()) {
        dateText = 'Today';
      } else if (currentMsgTime.valueOf() > yesterdayTT.valueOf()) {
        dateText = 'Yesterday';
      } else {
        dateText = currentMsgTime.format('Do MMM YYYY');
      }
    }

    if (dateText) {
      return (
        <DateElement>
          <DateText>{dateText}</DateText>
        </DateElement>
      );
    }
    return null;
  };

  render() {
    const { notes, loading } = this.state;
    const chatMessageLink = Link.state(this, 'chatMessage').onChange(val => {
      // if (this.timeoutId) {
      //   clearTimeout(this.timeoutId);
      // }
      this.setState({
        chatMessage: val,
      });
    });
    const renderUserMessage = ele => {
      return (
        <UserMessage
          key={ele.commentId}
          message={ele.comment}
          timestamp={new Date(ele.createdAt).valueOf()}
          doctorName={ele.doctorName}
        />
      );
    };
    const renderChatMessage = (ele, idx, array) => {
      let dateElement = null;
      if (idx > 0) {
        dateElement = this.getDateElement(
          ele.timetoken,
          array[idx - 1].timetoken,
        );
      } else {
        dateElement = this.getDateElement(ele.timetoken, 0);
      }
      switch (ele.userType) {
        default:
          return (
            <>
              {dateElement}
              {renderUserMessage(ele)}
            </>
          );
      }
    };
    if (loading) {
      return <LoadingComponent />;
    }
    return (
      <OuterContainer>
        {
          <BodyContainer ref={this.conversationBodyRef}>
            {this.state.fetchMessages && (
              <LoadingContainer>
                <LoadingIcon className="icon-spin" />
                <Text>Fetching Messages...</Text>
              </LoadingContainer>
            )}
            {notes.length === 0 ? (
              <Text>No notes added yet.</Text>
            ) : (
              notes.map(renderChatMessage)
            )}
          </BodyContainer>
        }
        <InputContainer>
          <InputBox
            valueLink={chatMessageLink}
            className="visit-input"
            id="chat-input-box"
            placeholder="Type your message here"
          />
          <StyledTappable onTap={this.sendMessage}>
            <SendIcon />
          </StyledTappable>
        </InputContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = state => ({
  chatInfo: state.chat,
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  user: state.user,
});

export default connect(mapStateToProps)(UserNotesContainer);
