import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';

import { RegularText, Text } from '../../common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 100%;
  align-items: flex-end;
  justify-content: flex-start;
  align-self: flex-end;
  margin: 0.4rem 0;
`;

const BubbleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 1rem 1.2rem;
  background: #8852cc;
  border-radius: 1.4rem;
`;

const MessageText = styled(RegularText)`
  font-size: 1.6rem;
  color: #fff;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1.6rem;
`;

const TickIcon = styled(FaCheck)`
  color: #fff;
  opacity: 0.5;
  font-size: 1rem;
  margin-right: 0.4rem;
`;

const TimestampText = styled(RegularText)`
  color: #fff;
  opacity: 0.5;
  font-size: 1.2rem;
`;

const UserMessage = ({ message, timestamp, doctorName }) => (
  <OuterContainer>
    <BubbleContainer>
      <MessageText>{message}</MessageText>
      {timestamp && (
        <TimestampContainer>
          <TickIcon />
          <TimestampText>
            {timestamp}
          </TimestampText>
        </TimestampContainer>
      )}
    </BubbleContainer>
    <Text font-size="1rem" color="rgba(0,0,0,0.8)">
      <p> {doctorName} </p>
    </Text>
  </OuterContainer>
);

UserMessage.propTypes = {
  message: PropTypes.string,
  timestamp: PropTypes.number,
  doctorName: PropTypes.string,
};

export default UserMessage;
