import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../containers/common/Modal';
import StyledTappable from './common/StyledTappable';
import { connect } from 'react-redux';
import { Text, BoldText } from './common/Text';
import { PrimaryButton } from './common/Buttons';
import { FaTimes } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { updateDoctorInfo } from '../services';
import { resetActiveConsult } from '../actions';
import moment from 'moment';
const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 30%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

class DoctorInfoUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showAlert: false,
      alertText: '',
      alertType: 'success',
      language: '',
      languageOptions: [
        { value: 'Telugu', label: 'Telugu' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'English', label: 'English' },
        { value: 'Tamil', label: 'Tamil' },
        { value: 'Malayalam', label: 'Malayalam' },
        { value: 'Kannada', label: 'Kannada' },
        { value: 'Bengali', label: 'Bengali' },
        { value: 'Marathi', label: 'Marathi' },
        { value: 'Gujarati', label: 'Gujarati' },
        { value: 'Assamese', label: 'Assamese' },
        { value: 'Kashmiri', label: 'Kashmiri' },
        { value: 'Konkani', label: 'Konkani' },
        { value: 'Manipuri', label: 'Manipuri' },
        { value: 'Nepali', label: 'Nepali' },
        { value: 'Oriya', label: 'Oriya' },
        { value: 'Punjabi', label: 'Punjabi' },
        { value: 'Urdu', label: 'Urdu' },
      ],
    };
    console.log(props.user);
    this.state.panNo = props.user.panNo;
    this.state.email = props.user.email;
  }

  componentDidMount() {}

  setName = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        panNo: value,
      });
    } else {
      this.setState({
        panNo: '',
      });
    }
  };
  setEmail = (ev) => {
    const value = ev.target.value;
    if (value) {
      this.setState({
        email: value,
      });
    } else {
      this.setState({
        email: '',
      });
    }
  };

  handleLanguageChange = (selectedOption) => {
    let languageContainer = '';
    selectedOption.forEach((ele) => {
      languageContainer += ele['value'] + ';;';
    });
    this.setState({ language: languageContainer });
  };

  updateDoctorInfo = () => {
    this.setState({
      loading: true,
    });
    const { panNo, email, language } = this.state;
    console.log('ue4', language);
    updateDoctorInfo(panNo, email, language)
      .then(() => {
        this.setState({
          alertText: 'Updated successfully',
          alertType: 'success',
          showAlert: true,
        });
      })
      .catch((err) => {
        this.setState({
          alertText:
            err.response.errorMessage ||
            'Something went wrong. Please try again later',
          alertType: 'danger',
          showAlert: true,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { alertText, alertType, panNo, email, showAlert, loading } =
      this.state;
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>Update Info</HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div
                      className={`alert collapse ${showAlert ? 'show' : ''} ${
                        alertType === 'success'
                          ? 'alert-success'
                          : alertType === 'warning'
                          ? 'alert-warning'
                          : 'alert-danger'
                      }`}
                      role="alert"
                    >
                      {alertText}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group row no-gutters">
                      <label
                        htmlFor="user-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Pan No:</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="user-name-input"
                          value={panNo}
                          onChange={this.setName}
                        />
                      </div>
                    </div>
                    <div className="form-group row no-gutters">
                      <label
                        htmlFor="user-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Email:</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <input
                          type="text"
                          className="form-control"
                          id="user-name-input"
                          value={email}
                          onChange={this.setEmail}
                        />
                      </div>
                    </div>
                    <div className="form-group row no-gutters">
                      <label
                        htmlFor="user-name-input"
                        className="col-sm-5 mt-3 col-form-label"
                      >
                        <Text fontSize="14px">Language Select</Text>
                      </label>
                      <div className="col-sm-7 mt-3">
                        <Select
                          placeholder="Please Select a Language"
                          isMulti
                          onChange={(e) => {
                            this.handleLanguageChange(e);
                          }}
                          className="mt-3 mr-2"
                          options={this.state.languageOptions}
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <PrimaryButton
                        loading={loading}
                        onTap={this.updateDoctorInfo}
                        disabled={
                          !panNo ||
                          !email ||
                          panNo.length < 8 ||
                          email.length < 6
                        }
                      >
                        <BoldText fontSize="16px" color="white">
                          Update
                        </BoldText>
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
});

export default connect(mapStateToProps)(DoctorInfoUpdate);
