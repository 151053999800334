import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';
import styled from 'styled-components';

import { FaTimes } from 'react-icons/fa';
import StyledTappable from '../components/common/StyledTappable';
import TappableCard from '../components/common/TappableCard';
import { PrimaryButton } from '../components/common/Buttons';
import { BoldText, Text, ButtonText, RegularText } from '../components/common/Text';
import AddMedicinesModal from '../components/prescription/AddMedicinesModal';
import AddLabTestModal from '../components/prescription/AddLabTestModal';
import PrescriptionSentSuccessModal from '../components/prescription/PrescriptionSentSuccessModal';
import MedicineCard from '../components/prescription/MedicineCard';
import { addMedicine, deleteMedicine, addLabTest, deleteLabTest, previewPrescription, sendPrescription } from '../services';
import { setPrescriptionId, addMedicines, addLabTests, removeMedicine, removeLabTest, setTreatmentPlan, resetPrescription } from '../actions';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  padding: 1.6rem 2rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const SectionContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
`;

const SectionTitle = styled(BoldText)`
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
`;

const StyledInputBox = styled(Input)`
  padding: 0.9rem 0;
  flex: 1 0;
  border-bottom: 1px solid #cecece !important;
`;

const StyledTappableCard = styled(TappableCard)`
  align-self: flex-start;
  padding: 1rem 3rem;
  margin-top: 0.625rem;
`;

const TappableText = styled(Text)`
  font-size: 1.4rem;
  text-align: center;
  color: #8852cc;
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 1.4rem 1.8rem;
`;

const StyledButton = styled(PrimaryButton)`
  margin: 0 0.7rem;
`;

const AddedTestsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  /* box-shadow: 0 -0.3rem 0.625rem 0 rgba(0, 0, 0, 0.09); */
  padding: 0.625rem 1rem;
  margin-bottom: 0.5rem;
  background: white;
  flex-shrink: 0;
`;

const AddedTestsInnerContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1.75rem;
`;

const AddedTestContainer = styled(StyledTappable)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0.5rem;
  background: #f4f4f4;
  box-shadow: 0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
`;

const RemoveIcon = styled(FaTimes)`
  margin-left: 1.125rem;
`;

const LabText = styled(RegularText)`
  font-size: 1.6rem;
`;

class PrescriptionContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      probableDiagnosis: props.probableDiagnosis,
      generalHealthAdvice: props.generalHealthAdvice,
      medicinesModalVisible: false,
      labTestModalVisible: false,
      selectedMedicineToEdit: {},
      showSendPrescriptionSuccess: false,
    };

    console.log({ props, state: this.state });
  }

  hideModal = () => {
    this.setState({
      medicinesModalVisible: false,
      labTestModalVisible: false,
    });
  };

  hidePrescriptionSentSuccessModal = () => {
    this.setState({
      showSendPrescriptionSuccess: false,
    });
  }

  openAddMedicineDialog = () => {
    console.log('openAddMedicineDialog called');
    this.setState({
      medicinesModalVisible: true,
    });
  }

  openAddLabTestDialog = () => {
    console.log('openAddLabTestDialog called');
    this.setState({
      labTestModalVisible: true,
    });
  }

  addMedicine = (medicine, closeModal) => {
    console.log({ medicine });
    const { dispatch } = this.props;

    const body = {
      user: this.props.activeConsult.user,
      consultationId: this.props.activeConsult.consultationId,
      prescriptionId: this.props.prescriptionId,
      doctorType: this.props.activeConsult.doctorType,
      name: medicine.medicineName,
      genericName: medicine.genericName,
      dosage: medicine.dosage,
      duration: medicine.durationValue,
      durationType: medicine.duration,
      meal: medicine.mealPref,
      notes: medicine.notes,
    };

    addMedicine(this.props.authToken, body)
      .then((result) => {
        console.log({ result }, 'result from addMedicine');
        if (result.prescriptionId) {
          body.prescriptionId = result.prescriptionId;
          dispatch(setPrescriptionId(result.prescriptionId));
        }

        body.medicineId = result.medicineId;
        dispatch(addMedicines([body]));

        // check if close modal then close
        if (closeModal) {
          this.setState({ medicinesModalVisible: false });
        }
      })
      .catch((err) => {
        console.log({ err }, 'error in add medicine');
      })
  }

  addLabTest = (lab) => {
    console.log({ lab });
    const { dispatch } = this.props;

    const body = {
      user: this.props.activeConsult.user,
      consultationId: this.props.activeConsult.consultationId,
      prescriptionId: this.props.prescriptionId,
      doctorType: this.props.activeConsult.doctorType,
      labId: lab.id,
      name: lab.name,
      code: lab.code,
    };

    addLabTest(this.props.authToken, body)
      .then((result) => {
        console.log({ result }, 'result from addLabTest');
        if (result.prescriptionId) {
          body.prescriptionId = result.prescriptionId;
          dispatch(setPrescriptionId(result.prescriptionId));
        }

        dispatch(addLabTests([body]));
      })
      .catch((err) => {
        console.log({ err }, 'error in add lab test');
      })
  }

  removeLabTest = (lab) => {
    deleteLabTest(this.props.authToken, lab.labId, lab.prescriptionId)
      .then((result) => {
        this.props.dispatch(removeLabTest(lab));
      })
      .catch((err) => {
        console.log({ err });
      })
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch({ type: 'SET_PROBABLE_DIAGNOSIS', payload: this.state.probableDiagnosis });
    dispatch({ type: 'SET_GENERAL_HEALTH_ADVICE', payload: this.state.generalHealthAdvice });
  }

  deleteMedicine = (medicine, index) => {
    deleteMedicine(this.props.authToken, medicine.medicineId, medicine.prescriptionId)
      .then((result) => {
        this.props.dispatch(removeMedicine(medicine));
      })
      .catch((err) => {
        console.log({err});
      })
  }

  renderMedicine = (medicine, index) => {
    return (
      <MedicineCard
        medicine={medicine}
        deleteMedicine={() => this.deleteMedicine(medicine, index)}
        key={`${index}_${medicine.name}`} />
    );
  }

  renderLabTest = (ele, idx) => (
    <AddedTestContainer
      key={ele.name}
      onTap={() => this.removeLabTest(ele)}>
      <LabText>{ele.name}</LabText>
      <RemoveIcon />
    </AddedTestContainer>
  );

  previewPrescription = () => {
    previewPrescription(this.props.authToken, this.props.prescriptionId, this.state.probableDiagnosis)
      .then((result) => {
        this.props.dispatch(setTreatmentPlan(result));
        window.open(result.treatmentPlanPdfLink);
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  sendPrescription = () => {
    console.log('send prescription called');
    sendPrescription(this.props.authToken, this.props.prescriptionId, this.state.probableDiagnosis)
      .then((result) => {
        console.log('prescription sent success');

        this.setState({
          showSendPrescriptionSuccess: true,
          probableDiagnosis: '',
          generalHealthAdvice: '',
        });
        this.props.dispatch(resetPrescription());
        // window.open(result.treatmentPlanPdfLink);
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  render() {
    console.log({ props: this.props, state: this.state }, 'log in render [PrescriptionContainer]');
    const probableDiagnosisLink = Link.state(this, 'probableDiagnosis');
    const generalHealthAdviceLink = Link.state(this, 'generalHealthAdvice');
    return (
      <OuterContainer>
        <BodyContainer>
          <SectionContainer>
            <SectionTitle>Probable Diagnosis*</SectionTitle>
            <StyledInputBox
              valueLink={probableDiagnosisLink}
              className="visit-input"
              placeholder="eg. alopecia alcarta"
            />
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>Medicines</SectionTitle>
            {this.props.medicines.map(this.renderMedicine)}
            <StyledTappableCard onTap={() => this.openAddMedicineDialog()}>
              <TappableText>+ Add Medicines</TappableText>
            </StyledTappableCard>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>Lab tests</SectionTitle>
            {this.props.labTests.length > 0 ? (
              <AddedTestsContainer>
                <AddedTestsInnerContainer>
                  {this.props.labTests.map(this.renderLabTest)}
                </AddedTestsInnerContainer>
              </AddedTestsContainer>
            ) : null}
            <StyledTappableCard onTap={() => this.openAddLabTestDialog()}>
              <TappableText>+ Add Lab Tests</TappableText>
            </StyledTappableCard>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>General Health Advice</SectionTitle>
            <StyledInputBox
              valueLink={generalHealthAdviceLink}
              className="visit-input"
              placeholder="eg. alopecia alcarta"
            />
          </SectionContainer>
        </BodyContainer>
        <FooterContainer>
          <StyledButton
            onTap={() => this.previewPrescription()}
            disabled={(this.props.prescriptionId && this.state.probableDiagnosis.length > 0) ? false : true}>
            <ButtonText>Preview</ButtonText>
          </StyledButton>
          <StyledButton
            onTap={() => this.sendPrescription()}
            disabled={(this.props.prescriptionId && this.state.probableDiagnosis.length > 0) ? false : true}>
            <ButtonText>Send</ButtonText>
          </StyledButton>
        </FooterContainer>
        {this.state.medicinesModalVisible && (
          <AddMedicinesModal
            addMedicine={(body, closeModal) => this.addMedicine(body, closeModal)}
            hideModal={() => this.hideModal()} />
        )}
        {this.state.labTestModalVisible && (
          <AddLabTestModal
            addLabTest={(body) => this.addLabTest(body)}
            removeLabTest={(body) => this.removeLabTest(body)}
            hideModal={() => this.hideModal()} />
        )}
        {this.state.showSendPrescriptionSuccess && (
          <PrescriptionSentSuccessModal
            hideModal={() => this.hidePrescriptionSentSuccessModal()} />
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = state => ({
  authToken: state.user.authToken,
  activeConsult: state.consults.activeConsult,
  prescriptionId: state.prescription.prescriptionId,
  medicines: state.prescription.medicines,
  labTests: state.prescription.labTests,
  probableDiagnosis: state.prescription.probableDiagnosis,
  generalHealthAdvice: state.prescription.generalHealthAdvice,
});

export default connect(mapStateToProps)(PrescriptionContainer);
