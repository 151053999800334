import React, { Component } from 'react';
import { connect } from 'react-redux';


import styled from 'styled-components';

import StyledTappable from '../components/common/StyledTappable';
import { BoldText, Text } from '../components/common/Text';
import {
    fetchFeedbacks,insertFeedbackReplies,getFeedbackRepliesId
} from '../services';

import LoadingComponent from '../components/common/LoadingComponent';
import { PrimaryButton } from '../components/common/Buttons';
import FeedbackBubble from '../components/consultations/chat-bubbles/FeedbackBubble';
import { FaCheck, FaEnvelopeSquare } from 'react-icons/fa';
import { IoMdSend } from 'react-icons/io';
//import { Input } from 'valuelink/tags';
import Link from 'valuelink';
import { Alert, Button, Collapse , Input} from 'antd';

const { TextArea } = Input;


const { Panel } = Collapse;
const divStyle = {
    overflowY: 'scroll',

};

const OuterContainerEle = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;

  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;

`;


const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: white;
  padding: 0.6rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;


const ReplyEnvelopeIcon = styled(FaEnvelopeSquare)`
  opacity: 1;
  font-size: 2rem;
  margin-left:1rem;
  background-color:white;
`;

const CheckReplyIcon = styled(FaCheck)`
opacity:1;
font-size:1.6rem;
margin-left:1rem;
`



const InputContainer = styled.div`
  display: flex;
  align-items: stretch;
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.1);
  background: white;
  padding: 0 1.6rem;
`;

const InputBox = styled(Input)`

  padding: 1.6rem;
  display: flex;
  align-self: stretch;
  flex: 1;
  ::placeholder {
    color: #bababa;
  }
`;

const SendIcon = styled(IoMdSend)`
  opacity: 0.5;
  font-size: 2rem;
`;













class DoctorsFeedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feedbacks: [],
      showInputBox: true,
      chatreply: null,
      replyId: null,
      feedbackselected: false,
      feebdacksentAck: false,
    };
    this.nameInput = React.createRef();
  }

  componentDidMount = () => {
    const { activeConsult, user } = this.props;

    //document.getElementById('chat-input-box').addEventListener('keydown', this.handleKeyPress, false);
    // window.addEventListener('keydown', this.handleKeyPress, false);
    this.getAllfeedbacks();
  };

  scrollToInput = (id) => {
    this.setState({ replyId: id });

    this.setState({ feedbackselected: true });
    this.nameInput.current.focus();
    setTimeout(() => {
      this.setState({ feedbackselected: false });
    }, 10000);

    //uncomment for scrolling
    //this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
  };

  handleReplies = () => {
    let replyId = this.state.replyId;
    let chatReply = this.state.chatreply;
    this.setState({ feedbackselected: false });

    insertFeedbackReplies(replyId, chatReply)
      .then((res) => {
        if (res.message === 'success') {
          //Alert("Feedback replied succesfully!");

          this.setState({ feebdacksentAck: true });
          this.setState({ chatreply: null, replyId: null });

          setTimeout(() => {
            this.setState({ feebdacksentAck: false });
          }, 3000);

          //alert("Feedback updated succesfully!");
        } else {
          Alert('Something went Wrong!');
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleKeyPress = (event) => {
    // console.log({ event, focus: document.activeElement.id });
    if (event.code === 'Enter') {
      this.handleReplies();
    }
  };

  hideSuccessModal = () => {
    this.setState({ modalCheck: false });
  };

  onInputChange = (e) => {
    this.setState({ chatreply: e.target.value });
  };

  async getAllfeedbacks() {
    try {
      let data = await fetchFeedbacks();
      let newval = [...this.state.feedbacks, data];

      this.setState({ feedbacks: newval[0] });
    } catch (error) {
      this.setState({
        errorMessage:
          (error.data || {}).errorMessage ||
          'Something went wrong. Please try again later',
      });
    }
  }

  render() {
    const {
      feedbacks,
      chatreply,
      showInputBox,
      replyId,
      feedbackselected,
      feebdacksentAck,
    } = this.state;

    // return feedbacks.length > 0 ? (feedbacks.map((a) => <li>{a}</li>)) : (<p>hi</p>);
    return feedbacks.length > 0 ? (
      <OuterContainer>
        {feedbackselected ? (
          <Alert
            message="Feedback Selected"
            description="Proceed to input box bewlo to send feedback!"
            type="info"
            showIcon
            closable
          />
        ) : (
          ' '
        )}

        {feebdacksentAck ? (
          <Alert message="Feedback Sent" type="success" showIcon closable />
        ) : (
          ' '
        )}

        <BodyContainer>
          {feedbacks.map((ele) => (
            <>
              <hr
                style={{
                  border: 'solid small grey',
                  borderWidth: 'small',

                  width: '100%',
                }}
              />

              <OuterContainerEle>
                <Button
                  style={{
                    background: 'white',
                    borderColor: 'purple',
                    textDecorationColor: 'purple',
                  }}
                  type="primary"
                  shape="round"
                  icon={<ReplyEnvelopeIcon fill="purple" />}
                  ghost
                  onClick={() => this.scrollToInput(ele.id)}
                >
                  <span style={{ color: 'purple' }}>Reply Feedback</span>
                </Button>
              </OuterContainerEle>

              <FeedbackBubble
                key={ele.id}
                id={ele.id}
                message={ele.feedback}
                timestamp={ele.date}
                doctorName={ele.feedbackBy}
                ack={ele.ack}
                given={ele.givenTo}
                givenToDoctorId={ele.givenToDoctorId}
                doctorId={this.props.user.doctorId}
                insurer={ele.insurer}
                appId={ele.appId}
              />

              {/*
               Uncomment for scrolling
               <div
                style={{ float: 'left', clear: 'both' }}
                ref={(el) => {
                  this.messagesEnd = el;
                }}
              ></div> */}
            </>
          ))}
        </BodyContainer>
        <>
          <InputContainer style={!replyId ? { display: 'none' } : {}}>
            <input
              style={{ width: '100%', padding: '6px' }}
              placeholder="Enter Reply Here"
              rows={3}
              value={chatreply}
              onChange={this.onInputChange}
              onPressEnter={this.handleReplies}
              ref={this.nameInput}
            />
            <StyledTappable onTap={this.handleReplies}>
              <SendIcon />
            </StyledTappable>
          </InputContainer>
        </>
      </OuterContainer>
    ) : (
      <p> Feedbacks not Available</p>
    );
  }
}

const mapStateToProps = (state) => ({
    chatInfo: state.chat,
    authToken: state.user.authToken,
    activeConsult: state.consults.activeConsult,
    activeFilterName: state.info.activeFilterName,
    user: state.user,
    ipruAddCase: state.consults.ipruAddCase,
    insurer: state.user.insurer,
});

export default connect(mapStateToProps)(DoctorsFeedback);

/*<QuestionContainer key={ele.id}>
                        <SubQuestionContainer>
                            <Text fontSize="18px" color="#242327">
                                {ele.feedback} by {ele.feedbackBy}
                            </Text>
                        </SubQuestionContainer>
                    </QuestionContainer> */